import { isArray, flattenDeep, groupBy as lodashGroupBy } from 'lodash';
import moment from 'moment';
import { Doc } from '../schema/models/schema';

export type Accessor = <S extends Doc, T>(value: S) => T;
export type Selector = (slice: any) => any;
export type Grouper = {
  accessor: Accessor | Accessor[];
  groupBy: Selector | Selector[];
};
export type Transform = {
  accessor: Accessor | Accessor[];
  transformer: Selector;
};

const accessorObserver = Proxy.revocable(
  {},
  {
    get: (target, name): string => {
      return name.toString();
    }
  }
);

export const removeTimeFromMoment = (dateTime: moment.Moment) => {
  return moment(dateTime.format(moment.HTML5_FMT.DATE)).toDate();
};

export function toArray<T>(value: T | T[]): T[] {
  if (isArray(value)) {
    return value;
  }
  return [value];
}

export function toValue<T>(array: T[]): T | T[] {
  if (array.length === 0) {
    return [];
  }
  if (array.length === 1) {
    return array[0];
  }
  return array;
}

export async function reduceArrayAsyncParallel<S, T>(
  array: S[],
  asyncFunc: (arrayVal: S) => Promise<T>,
  indexer: (arrayVal: S) => string
) {
  const map = await Promise.all(
    array.map(async arrayVal => ({
      [indexer(arrayVal)]: await asyncFunc(arrayVal)
    }))
  );
  return map.reduce(
    (flattenedObj, kvPair) => ({ ...flattenedObj, ...kvPair }),
    {}
  );
}

export async function reduceAsyncParallel<S, T>(
  object: { [x: string]: S },
  asyncFunc: (x: S, key: string) => Promise<T>
) {
  const map = await Promise.all(
    Object.keys(object).map(async key => ({
      [key]: await asyncFunc(object[key], key)
    }))
  );
  return map.reduce(
    (flattenedObj, kvPair) => ({ ...flattenedObj, ...kvPair }),
    {}
  );
}

export async function reduceAsyncSequential<S, T>(
  array: S[],
  baseValue: T,
  asyncFunc: (updatedBaseVal: T, arrayVal: S) => Promise<T>
) {
  return await array.reduce<Promise<T>>(async (baseValPromise, arrayVal) => {
    const baseVal = await baseValPromise;
    return await asyncFunc(baseVal, arrayVal);
  }, Promise.resolve(baseValue));
}

export function transformSequential<T>(
  source: T,
  transforms?: Transform | Transform[]
) {
  if (!transforms) {
    return source;
  }
  toArray(transforms).forEach(transform => {
    const { accessor, transformer } = transform;
    const accessibles: string[] = applyMapToObjectOrArray(
      accessor,
      getAccessible
    );
    accessibles.reduce<Doc | Doc[]>((slice, accessible, index) => {
      if (index === accessibles.length - 1) {
        return applyMapToObjectOrArray(slice, sl => {
          sl[accessible] = transformer(sl[accessible]);
          return sl;
        });
      }
      return applyMapToObjectOrArray(slice, sl => sl[accessible]);
    }, source as Doc | Doc[]);
  });
  return source;
}

export function groupBySequential<T>(source: T | T[], grouper?: Grouper) {
  if (!grouper) {
    return source;
  }
  const { accessor, groupBy } = grouper;
  const accessors: string[] = applyMapToObjectOrArray(accessor, getAccessible);
  const deflattenedSource = deflattenDeep(source as Doc | Doc[], accessors);
  return toArray(groupBy).reduce((groupedSource, grouping, index) => {
    return applyDeepGroupby(groupedSource, index, innerSource =>
      lodashGroupBy(innerSource, grouping)
    );
  }, deflattenedSource);
}

const getAccessible = (accessor: <S extends Doc, T>(value: S) => T): string =>
  accessor(accessorObserver.proxy);

const applyMapToObjectOrArray = <S, T>(
  source: S | S[],
  mapFn: (value: S) => S | T
): any => {
  const mappedArray = flattenDeep(toArray(source)).map(mapFn);
  return isArray(source) ? mappedArray : toValue(mappedArray);
};

const deflattenDeep = (source: Doc | Doc[], accessors: string[]) => {
  const reducedArray = accessors.reduce(
    (newSource, accessor) =>
      applyMapToObjectOrArray(newSource, s => {
        const { [accessor]: slice, ...parentData } = s;
        return applyMapToObjectOrArray(slice, val => ({
          ...val,
          ...parentData
        }));
      }),
    source
  );
  const isSourceArray = isArray(reducedArray);
  const deflattenedArray = flattenDeep(toArray(reducedArray));
  return isSourceArray ? deflattenedArray : toValue(deflattenedArray);
};

const applyDeepGroupby = (
  docs: any,
  level: number,
  groupbyFn: (value: any) => any
): any => {
  if (level === 0) {
    return groupbyFn(docs);
  }
  return Object.keys(docs).reduce((groupedDocs, key) => {
    return {
      ...groupedDocs,
      [key]: applyDeepGroupby(docs[key], level - 1, groupbyFn)
    };
  }, docs);
};
