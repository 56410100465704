import { toArray } from 'firestore/db/helpers';
import { CollectionDef } from '../../schema/models/schema';
import { firestore } from 'firebase';
import {
  FilterQuery,
  OrderQuery,
  PagingQuery,
  SubCollectionQueryOption
} from '../queryOptions';
import { getQuerySnapshot } from '../queryExecutor';
import { intersection } from 'lodash';
export default abstract class CollectionReaderBase {
  protected _collectionDef: CollectionDef;
  protected _collectionRef: firestore.Query<firestore.DocumentData>;
  private _subCollectionIds: string[];

  constructor(
    collectionDef: CollectionDef,
    collectionRef: firestore.Query<firestore.DocumentData>,
    subCollectionIds?: string[]
  ) {
    this._collectionDef = collectionDef;
    this._collectionRef = collectionRef;
    this._subCollectionIds = subCollectionIds || [];
  }

  public abstract getDocument(
    documentId: string,
    subCollectionQueryOption?: SubCollectionQueryOption
  ): Promise<firestore.DocumentData | undefined>;

  public async getDocuments(
    filterQueries?: FilterQuery[],
    subCollectionQueryOption: SubCollectionQueryOption = SubCollectionQueryOption.withoutSubCollection,
    orderBy?: OrderQuery,
    paging?: PagingQuery
  ) {
    const querySnapshot = await getQuerySnapshot(
      this._collectionRef,
      filterQueries
      // orderBy,
      // paging
    );
    if (querySnapshot) {
      return await Promise.all(
        querySnapshot.docs.map(
          async doc =>
            await this.getDocumentData(
              doc.ref,
              this._collectionDef,
              subCollectionQueryOption
            )
        )
      );
    }
  }

  protected async getDocumentData(
    documentRef: firestore.DocumentReference<firestore.DocumentData>,
    collectionDef: CollectionDef,
    subCollectionQueryOption: SubCollectionQueryOption
  ) {
    switch (subCollectionQueryOption) {
      case SubCollectionQueryOption.withSubCollection:
        return await this.getDocumentDataWithSubCollections(
          documentRef,
          collectionDef,
          this._subCollectionIds
        );
      case SubCollectionQueryOption.onlySubCollection:
        return await this.getDocumentDataWithSubCollections(
          documentRef,
          collectionDef,
          this._subCollectionIds,
          true
        );
      default:
      case SubCollectionQueryOption.withoutSubCollection:
        return (await documentRef.get()).data();
    }
  }

  private async getDocumentDataWithSubCollections(
    documentRef: firestore.DocumentReference<firestore.DocumentData>,
    collectionDef: CollectionDef,
    subCollectionIds: string[],
    onlySubCollections = false
  ) {
    let documentData = onlySubCollections
      ? {}
      : (await documentRef.get()).data();
    const subCollectionObjects = await this.getSubCollectionsData(
      documentRef,
      collectionDef,
      subCollectionIds
    );
    if (subCollectionObjects) {
      return subCollectionObjects.reduce((docData, subCollObjectMap) => {
        if (subCollObjectMap) {
          return { ...docData, ...subCollObjectMap };
        }
        return docData;
      }, documentData);
    }
    return documentData;
  }

  private async getSubCollectionsData(
    documentRef: firestore.DocumentReference<firestore.DocumentData>,
    collectionDef: CollectionDef,
    subCollectionIds: string[]
  ): Promise<
    | ({
      [subcollectionName: string]: firestore.DocumentData[];
    } | null)[]
    | null
  > {
    const existingSubCollections = toArray(collectionDef.subCollection || []);
    const filteredSubCollection = intersection(
      existingSubCollections.map(subColl => subColl.id),
      subCollectionIds
    );
    // if filteredsubcollection is empty, return all subcollections else filter out
    const subCollections =
      filteredSubCollection.length === 0
        ? existingSubCollections
        : existingSubCollections.filter(subColl =>
          filteredSubCollection.includes(subColl.id)
        );
    if (subCollections) {
      return await Promise.all(
        subCollections.map(async subCollection => {
          if (subCollection) {
            const subCollectionRef = await documentRef
              .collection(subCollection.id)
              .get();
            if (subCollectionRef) {
              return {
                [subCollection.name]: subCollectionRef.docs.map(document =>
                  document.data()
                )
              };
            }
          }
          return null;
        })
      );
    }
    return null;
  }
}
