import { firestore } from 'firebase';
import Db from '../../init/db';
import { SubCollectionQueryOption } from '../queryOptions';
import { CollectionDef } from '../../schema/models/schema';
import CollectionReaderBase from './collectionReaderBase';

export default class DocCollectionReader extends CollectionReaderBase {
  constructor(collectionDef: CollectionDef, subCollectionIds?: string[]) {
    super(
      collectionDef,
      Db.Instance.collection(collectionDef.id),
      subCollectionIds
    );
  }

  public async getDocument(
    documentId: string,
    subCollectionQueryOption = SubCollectionQueryOption.withoutSubCollection
  ) {
    const collectionRef = this._collectionRef as firestore.CollectionReference<
      firestore.DocumentData
    >;
    const documentRef = collectionRef.doc(documentId);
    return this.getDocumentData(
      documentRef,
      this._collectionDef,
      subCollectionQueryOption
    );
  }
}
