export const font = {
  fontWeight: 600,
  fontSize: '14px',
  color: '#525f7f'
};
export const styles = {
  container: styles => ({
    ...styles,
    outline: 'unset !important'
  }),
  indicatorSeparator: styles => ({ ...styles, width: 0 }),
  input: styles => ({ ...styles, opacity: '1 !important' }),
  singleValue: styles => ({
    ...styles,
    ...font
  }),
  control: styles => ({
    ...styles,
    paddingLeft: 8,
    border: 0,
    boxShadow: 'none'
  }),
  dropdownIndicator: styles => ({
    ...styles,
    ...font
  }),
  clearIndicator: styles => ({
    ...styles,
    ...font
  })
};
