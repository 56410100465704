import React from 'react';
import './Step.scss';
import { motion } from 'framer-motion';

function Step({ text, number, activeStepNumber, width, lastStep }) {
  const firstStep = number === 1;
  const completed = number < activeStepNumber;
  const isActive = number === activeStepNumber;
  const isInActive = number > activeStepNumber;

  return (
    <motion.li
      style={{ width: width }}
      variants={listItemvariants}
      initial="hidden"
      animate="visible"
      className="d-flex flex-column step"
    >
      <div className="step-bullet">
        <div
          className={`divider-container ${
            firstStep
              ? 'justify-content-end'
              : lastStep
              ? 'justify-content-start'
              : 'justify-content-between'
          } `}
        >
          {!firstStep && (
            <motion.div
              variants={dividerVariants}
              initial="initial"
              animate={
                number === activeStepNumber || completed
                  ? 'complete'
                  : 'initial'
              }
              exit="initial"
              className={`step-divider`}
            />
          )}
          {!lastStep && (
            <motion.div
              variants={dividerVariants}
              initial="initial"
              animate={
                !(number >= activeStepNumber) && completed
                  ? 'complete'
                  : 'initial'
              }
              exit="initial"
              className={`step-divider`}
            />
          )}
        </div>
        <div className="step-number-container">
          <motion.div
            variants={stepNumberVariants}
            initial="initial"
            animate={
              completed
                ? 'complete'
                : isActive
                ? 'active'
                : isInActive
                ? 'initial'
                : ''
            }
            exit="initial"
            className={'step-number'}
          >
            {number}
          </motion.div>
        </div>
      </div>
      <motion.p
        variants={stepTextVariants}
        initial="initial"
        animate={
          completed
            ? 'complete'
            : isActive
            ? 'active'
            : isInActive
            ? 'initial'
            : ''
        }
        exit="initial"
        className="d-none d-sm-block step-text text-center mt-2 mb-0"
      >
        <small>
          <strong>{text}</strong>
        </small>
      </motion.p>
    </motion.li>
  );
}

export default Step;

const listItemvariants = {
  hidden: {
    opactiy: 0
  },
  visibile: {
    opactiy: 1,
    transition: {
      duration: 0.5
    }
  }
};

const stepNumberVariants = {
  initial: {
    background: '#dadad9',
    fontSize: '1rem',
    height: '3rem',
    width: '3rem',
    transition: {
      duration: 0.3,
      ease: 'easeInOut'
    }
  },
  active: {
    background: '#06648F',
    fontSize: '1.25rem',
    height: '3rem',
    width: '3rem',
    transition: {
      duration: 0.3,
      ease: 'easeInOut'
    }
  },
  complete: {
    background: '#6F98A3',
    fontSize: '1.25rem',
    height: '3rem',
    width: '3rem',
    transition: {
      duration: 0.2,
      ease: 'easeInOut'
    }
  }
};

const dividerVariants = {
  initial: {
    background: '#dadad9',
    transition: {
      duration: 0.2
    }
  },
  active: {
    background: '#06648F',
    transition: {
      duration: 2
    }
  },
  complete: {
    background: '#6F98A3',
    transition: {
      duration: 2
    }
  }
};
const stepTextVariants = {
  initial: {
    color: '#dadad9',
    transition: {
      duration: 0.5
    }
  },
  active: {
    color: '#06648F',
    transition: {
      duration: 0.5
    }
  },
  complete: {
    color: '#6F98A3',
    transition: {
      duration: 0.5
    }
  }
};
