import React from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { FormGroup, Label } from 'reactstrap';
import { startsWith } from 'lodash';
// import './PhoneInput.scss';

function PhoneNumber({
  label,
  icon = null,
  addonType = null,
  rules,
  name,
  control,
  error,
  formGroupClassName = '',
  setError = null,
  inputProps = {},
  ...props
}) {
  return (
    <FormGroup>
      <Label className="form-control-label" for={name}>
        {label}
      </Label>
      <Controller
        render={props => {
          return (
            <PhoneInput
              isValid={(inputNumber, country, countries) => {
                const isValid = countries.some(country => {
                  return (
                    startsWith(inputNumber, country.dialCode) ||
                    startsWith(country.dialCode, inputNumber)
                  );
                });
                return isValid;
              }}
              enableSearch={true}
              inputProps={{
                required: true,
                id: inputProps.id,
                ...inputProps
              }}
              inputClass="form-control bg-transparent"
              placeholder="Enter phone number"
              country={'in'}
              {...props}
            />
          );
        }}
        control={control}
        name={name}
        rules={rules}
      />
      {error && (
        <p className="text-danger font-weight-500">
          <small>{error.message}</small>
        </p>
      )}
    </FormGroup>
  );
}

export default PhoneNumber;
