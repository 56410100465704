import React from 'react';
import { Container } from 'reactstrap';
import { announcements } from '../Homepage/data';
import Announcement from './Announcement';

function Announcements() {
  return (
    <Container>
      <h3 className="text-center my-3 bg-gradient-gray-dark py-2 bg-dark text-white">
        Announcements
      </h3>
      {announcements.map((announcement, index) => {
        return <Announcement key={announcement?.title} item={announcement} />;
      })}
    </Container>
  );
}

export default Announcements;
