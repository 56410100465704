import React, { useEffect, useState } from 'react';
import DonationCard from './DonationCard';
import { CardList } from 'components/Cards';
import Query from 'firestore/db/read/queryBase';
import { FilterOperator } from 'firestore/db/read/queryOptions';
import useAuth from 'hooks/useAuth';
import { CardBody, CardText, Spinner } from 'reactstrap';

function DonationHistory() {
  const [currentDonations, setDonations] = useState(null);
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  useEffect(() => {
    const getDonations = async () => {
      setLoading(true);
      const result = await Query.from('donations').Execute({
        filter: [
          {
            field: 'createdBy',
            operator: FilterOperator.equalTo,
            value: auth?.uid
          }
        ]
      });
      setLoading(false);
      setDonations(result);
    };
    getDonations();
  }, [auth]);
  return loading ? (
    <div
      style={{ minHeight: '80vh' }}
      className="h-100 w-100 d-flex flex-column bg-lighter opacity-8 justify-content-center align-items-center"
    >
      <Spinner />
      <p>Loading...</p>
    </div>
  ) : (
    <CardList>
      {currentDonations?.length > 0 ? (
        currentDonations?.map((donation, index) => (
          <DonationCard item={donation} key={index} />
        ))
      ) : (
        <div
          style={{ minHeight: '50vh' }}
          className="d-flex bg-transparent border-0 justify-content-center align-items-center text-center card"
        >
          <CardBody className="d-flex justify-content-center align-items-center">
            <CardText className="h3">
              No donations yet. <br />
              Please do a donation to see it in the donation history
            </CardText>
          </CardBody>
        </div>
      )}
    </CardList>
  );
}

export default DonationHistory;
