import SchemaDef, { CollectionsDef } from '../../models/schema';
import {
  BaseCollectionSchemaDef,
  CollectionSchemaDefs
} from '../../models/collectionsSchema';
import SchemaValidatorBase from './schemaValidatorBase';

export default class CollectionSchemaValidator extends SchemaValidatorBase {
  private _schema: SchemaDef;
  private _collectionSchemaDefs: CollectionSchemaDefs;

  constructor(schema: SchemaDef, collectionSchemaDefs: CollectionSchemaDefs) {
    super();
    this._schema = schema;
    this._collectionSchemaDefs = collectionSchemaDefs;
  }

  public validateCollections(): CollectionSchemaDefs {
    // Get all ids of collections
    const collectionIds = this.getCollectionIds(this._schema.collections);
    // The collection id should have valid scohema defined
    this.validateCollectionIds(collectionIds, this._collectionSchemaDefs);
    return collectionIds.reduce((collectionSchemas, collectionId) => {
      // Flattening collection schema based on "extends"
      const collectionSchemaDef = this.getFlattenCollectionSchema(
        collectionId,
        this._collectionSchemaDefs
      );
      // Validating flattened collection schema
      // this.validateCollectionSchema(collectionSchemaDef);
      return {
        ...collectionSchemas,
        [collectionId]: collectionSchemaDef
      };
    }, {});
  }

  private getCollectionIds(collections: CollectionsDef) {
    return Object.keys(collections).reduce((colIds: string[], collection) => {
      const colId = collections[collection].id;
      return [...colIds, colId];
    }, []);
  }

  private getFlattenCollectionSchema(
    collectionId: string,
    collectionSchemaDefs: CollectionSchemaDefs
  ): BaseCollectionSchemaDef {
    const collectionSchemaDef = collectionSchemaDefs[collectionId];
    return this.flattenCollectionSchema(
      collectionId,
      collectionSchemaDef,
      collectionSchemaDefs
    );
  }
}
