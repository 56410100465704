import { ParsedFilterQueries } from './../queryParser';
import { intersection, flattenDeep, chunk } from 'lodash';
import Db from '../../init/db';
import { RELATION_ID_FIELD } from '../../schema/constants';
import { QueryCompounder } from '../queryOptions';
import {
  FilterOperator,
  FilterQuery,
  MAX_FILTER_OPERATOR_IN_COUNT,
  OrderQuery,
  PagingQuery,
  SubCollectionQueryOption
} from '../queryOptions';
import {
  applySubCollectionQueries,
  getDocumentsFromSnapshot,
  getQuerySnapshot
} from '../queryExecutor';
import {
  reduceArrayAsyncParallel,
  reduceAsyncParallel
} from 'firestore/db/helpers';

export default class SubCollectionReader {
  private _subCollectionIds: string[];
  private _parentCollectionId: string;

  constructor(parentCollectionId: string, subCollectionIds: string[]) {
    this._parentCollectionId = parentCollectionId;
    this._subCollectionIds = subCollectionIds;
  }

  public async getDocuments(
    filterQueries: ParsedFilterQueries,
    subCollectionQueryOption: SubCollectionQueryOption = SubCollectionQueryOption.withoutSubCollection,
    orderBy?: OrderQuery,
    paging?: PagingQuery
  ) {
    const {
      collectionFilterQueries,
      subCollectionFilterQueries
    } = filterQueries;

    //  Apply subcollection queries to generate subcollection
    //  snapshot map
    const subCollectionQueriesMap = await applySubCollectionQueries(
      Db.Instance,
      subCollectionFilterQueries
    );

    // Get documents from subcollection snapshot map to
    // generate subcollection data map
    const subCollectionDataMap = await reduceAsyncParallel(
      subCollectionQueriesMap,
      async collectionRef => {
        return await getDocumentsFromSnapshot(collectionRef);
      }
    );

    // Get the common subcollections (which belong to the same parent)
    //  based on relation id
    let relationIds = intersection(
      ...Object.values(subCollectionDataMap).map(subCollectionData =>
        subCollectionData.map(data => data[RELATION_ID_FIELD])
      )
    );

    // If no match exists, return empty
    if (relationIds.length === 0) {
      return [];
    }

    // Filter the parent subcollection based on parent filter queries
    // and relation ids
    const parentDocsSnapshot = await this.getParentCollectionSnapshot(
      this._parentCollectionId,
      relationIds,
      collectionFilterQueries
    );

    // Return only parent docs if no subcollection data is needed
    if (
      subCollectionQueryOption === SubCollectionQueryOption.withoutSubCollection
    ) {
      return parentDocsSnapshot?.docs.map(doc => doc.data());
    }

    // Filter relation ids based on filtered parent collection
    // relation ids
    relationIds = parentDocsSnapshot?.docs.map(doc => {
      return doc.data()[RELATION_ID_FIELD] as string;
    });

    //  Generate subcollection map that were not fetched before,
    // and the ones specified in subCollectionIds in the ctor
    const newSubCollDataMap = await reduceArrayAsyncParallel(
      this._subCollectionIds.filter(
        subCollId => !(subCollId in subCollectionDataMap)
      ),
      async subCollId => {
        if (subCollId in subCollectionDataMap) {
          return subCollectionDataMap[subCollId];
        }
        return flattenDeep(
          (
            await Promise.all(
              parentDocsSnapshot.docs.map(doc =>
                doc.ref.collection(subCollId).get()
              )
            )
          ).map(collSnapshot => collSnapshot.docs.map(doc => doc.data()))
        );
      },
      subCollId => subCollId
    );

    //  Combine subcollection maps
    const allSubCollectionsDataMap = {
      ...subCollectionDataMap,
      ...newSubCollDataMap
    };

    // Return the combined subcollection map if
    //  no parent data is needed
    if (
      subCollectionQueryOption === SubCollectionQueryOption.onlySubCollection
    ) {
      return allSubCollectionsDataMap;
    }

    // Group the subcollection data based on relation id
    const groupedSubCollectionData = await reduceArrayAsyncParallel(
      relationIds,
      async relationId => {
        return await reduceArrayAsyncParallel(
          this._subCollectionIds,
          async subCollId => {
            return allSubCollectionsDataMap[subCollId].filter(
              data => data[RELATION_ID_FIELD] === relationId
            );
          },
          subCollId => subCollId
        );
      },
      relationId => relationId
    );

    //  Combine the subcollection data with the parent data
    //  based on relation ids
    return parentDocsSnapshot?.docs.map(doc => {
      const { [RELATION_ID_FIELD]: relationId, ...rest } = doc.data();
      return { ...rest, ...groupedSubCollectionData[relationId] };
    });
  }

  private async getParentCollectionSnapshot(
    parentCollectionId: string,
    relationIds: string[],
    filterQueries: FilterQuery[]
  ) {
    const slicedRelationIds = chunk(relationIds, MAX_FILTER_OPERATOR_IN_COUNT);
    return await getQuerySnapshot(Db.Instance.collection(parentCollectionId), [
      ...filterQueries,
      {
        field: RELATION_ID_FIELD,
        compoundQuery: {
          compounder: QueryCompounder.or,
          conditions: slicedRelationIds.map(relIds => doc =>
            doc.where(RELATION_ID_FIELD, FilterOperator.in, relIds)
          )
        }
      }
    ]);
  }

  // private async getParentCollectionSnapshot(
  //   parentCollectionId: string,
  //   relationIds: string[],
  //   filterQueries: FilterQuery[]
  // ) {
  //   const slicedRelationIds = chunk(relationIds, MAX_FILTER_OPERATOR_IN_COUNT);
  //   return await getQuerySnapshot(Db.Instance.collection(parentCollectionId), [
  //     ...filterQueries,
  //     ...slicedRelationIds.map(relIds => ({
  //       field: RELATION_ID_FIELD,
  //       operator: FilterOperator.in,
  //       value: relIds
  //     }))
  //   ]);
  // }
}
