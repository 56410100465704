import React from 'react';
// import { transactions } from './mockdata';
import { CardList } from 'components/Cards';

import { CardBody, CardText, Spinner } from 'reactstrap';
import useTransactions from 'hooks/useTransactions';
import VirtualizedRows from './VirtualizedRows';
function SevaHistory() {
  const { data: currentTransactions, isLoading: loading } = useTransactions();

  return loading ? (
    <div
      style={{ minHeight: '80vh' }}
      className="h-100 w-100 d-flex flex-column opacity-8 justify-content-center align-items-center"
    >
      <Spinner />
      <p>Loading...</p>
    </div>
  ) : (
    <CardList>
      {currentTransactions?.length > 0 ? (
        <VirtualizedRows rows={currentTransactions} />
      ) : (
        <div
          style={{ minHeight: '50vh' }}
          className="d-flex bg-transparent border-0 justify-content-center align-items-center text-center card"
        >
          <CardBody className="d-flex justify-content-center align-items-center">
            <CardText className="h3">
              No transactions yet. <br />
              Please book a transaction to see it in the transaction history
            </CardText>
          </CardBody>
        </div>
      )}
    </CardList>
  );
}

export default SevaHistory;
