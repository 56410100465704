/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import SevaBooking from 'views/examples/SevaBooking/SevaBooking';
import Login from 'views/examples/Auth/Auth';
import Logout from 'views/examples/Auth/Logout';
import Verify from 'views/examples/Verify/Verify';
import Checkout from 'views/examples/CheckoutTest.js';
import Homepage from 'views/examples/Homepage';
import About from 'views/examples/About';
import Contact from 'views/examples/ContactUs';
import Announcements from 'views/examples/Announcements';
import Reports from 'views/examples/Reports';
import SevaInformation from 'views/examples/SevaInformation';
import Privacy from 'views/examples/PrivacyPolicy';
import Terms from 'views/examples/TermsAndConditions';
import Cancellation from 'views/examples/CancellationOrRefund';
import CheckoutVerify from 'views/examples/CheckoutVerify';
var routes = [
  {
    path: '/checkout-verify',
    name: 'Verify Order',
    icon: 'ni ni-calendar-grid-58 text-success',
    component: CheckoutVerify,
    layout: '/user'
  },
  {
    path: '/reports',
    name: 'Reports',
    icon: 'ni ni-calendar-grid-58 text-success',
    component: Reports,
    layout: '/agent'
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-calendar-grid-58 text-success',
    component: Login,
    layout: '/user'
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    icon: 'ni ni-calendar-grid-58 text-success',
    component: Privacy,
    layout: '/user'
  },
  {
    path: '/cancellation-policy',
    name: 'Cancellation/Refund Policy',
    icon: 'ni ni-calendar-grid-58 text-success',
    component: Cancellation,
    layout: '/user'
  },
  {
    path: '/terms-conditions',
    name: 'Terms and Conditions',
    icon: 'ni ni-calendar-grid-58 text-success',
    component: Terms,
    layout: '/user'
  },
  {
    path: '/seva-information',
    name: 'Login',
    icon: 'ni ni-calendar-grid-58 text-success',
    component: SevaInformation,
    layout: '/user'
  },
  {
    path: '/about',
    name: 'About',
    icon: 'ni ni-calendar-grid-58 text-success',
    component: About,
    layout: '/user'
  },
  {
    path: '/announcements',
    name: 'Announcements',
    icon: 'ni ni-calendar-grid-58 text-success',
    component: Announcements,
    layout: '/user'
  },
  {
    path: '/contact',
    name: 'Contact Us',
    icon: 'ni ni-calendar-grid-58 text-success',
    component: Contact,
    layout: '/user'
  },
  {
    path: '/verify',
    name: 'Verify',
    icon: 'ni ni-calendar-grid-58 text-success',
    component: Verify,
    layout: '/user'
  },

  {
    path: '/calendar',
    name: 'Seva Booking',
    icon: '  ni ni-single-copy-04 text-primary',
    component: Homepage,
    layout: '/user'
  },
  {
    path: '/seva',
    name: 'Seva Booking',
    icon: '  ni ni-single-copy-04 text-primary',
    component: SevaBooking,
    layout: '/user'
  },
  {
    path: '/home',
    name: 'Home Page',
    icon: '  ni ni-single-copy-04 text-primary',
    component: Homepage,
    layout: '/user'
  },
  {
    path: '/test',
    name: 'Test',
    icon: '  ni ni-single-copy-04 text-primary',
    component: Checkout,
    layout: '/user'
  },
  {
    path: '/logout',
    name: 'Logut',
    icon: '  ni ni-single-copy-04 text-primary',
    component: Logout,
    layout: '/user'
  }
];
export default routes;
