import { useEffect } from 'react';
import { useAppState } from './useAppState';
import { addOnAuthStateChanged } from 'firestore/auth/utils/helpers';
import collectionSchema from 'firestore/db/schemaFiles/collection-schemas.json';
import schema from 'firestore/db/schemaFiles/schema.json';
import subCollectionSchema from 'firestore/db/schemaFiles/sub-collection-schemas.json';
import Schema from 'firestore/db/schema';
// import AuthLayout from "layouts/Auth.js";
export default function useAuth() {
  const [{ authAttempted, auth }, dispatch] = useAppState();
  useEffect(() => {
    return addOnAuthStateChanged(
      auth => {
        if (auth) {
          Schema.Initialize(schema, collectionSchema, subCollectionSchema);
          const { email, phoneNumber, uid, displayName } = auth;
          dispatch({
            type: 'AUTH_CHANGE',
            payload: {
              auth: {
                email,
                phoneNumber,
                uid,
                displayName
              },
              authAttempted: true
            }
          });
        } else {
          dispatch({
            type: 'AUTH_CHANGE',
            payload: { auth: null, authAttempted: true }
          });
        }
      },
      err => {
        console.error('Authentication Error', err);
      }
    );
  }, [dispatch]);

  return { auth, authAttempted };
}
