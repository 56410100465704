export const DATEPICKER_MODES = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  RANGE: 'range'
};

export const TRANSACTION_STATUS = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  FLAGGED: 'FLAGGED'
};
