import { Payments } from 'utils/payments';
import Log from 'utils/log';

export const checkoutTransaction = async (formObject, callback) => {
    try {
        const order = await Payments.generateOrder(formObject);
        Payments.makePayment(order, callback);
    } catch (err) {
        Log.error(err);
        throw new Error('Payment has failed. Please try again');
    }
}