import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

function Item({ children }) {
  const listRef = useRef();
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }, []);
  return (
    <motion.li
      ref={listRef}
    // layout
    // positionTransition
    // initial={{ opacity: 0, y: 50 }}
    // animate={{ opacity: 1, y: 0, transition: { duration: 0.3 } }}
    // exit={{
    //   opacity: 0,
    //   y: 50,
    //   transition: { duration: 0.3 }
    // }}
    >
      {children}
    </motion.li>
  );
}

export default Item;
