import React from 'react';
import { Col, Row } from 'reactstrap';
import LeftPanel from './LeftPanel';
import Information from './Information';
import Auth from './Auth';

function Homepage() {
  return (
    <div className='mx-xl-7 mx-lg-4'>
      <Row noGutters className="p-sm-3">
        <Col sm={12} md={12} lg={9} xl={9}>
          <LeftPanel />
          <Information />
        </Col>  
        <Col sm={12} md={12} lg={3} xl={3}>
          <Auth />
        </Col>
      </Row>
    </div>
  );
}

export default Homepage;
