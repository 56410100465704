import React from 'react';
import { Card, CardTitle } from 'reactstrap';
import './LeftPanel.scss';

function LeftPanel() {
  return (
    <>
      <Card className="bg-transparent m-0 border-0">
        <CardTitle className="mb-0 mx-1">
          <div className="d-flex justify-content-center border shadow mb-2 p-xl-2">
            <img
              style={{ maxHeight: '55vh', maxWidth: '100%', width: '100%' }}
              className="d-none d-md-flex img-fluid"
              src={require('assets/img/brand/Banner3.jpg')}
              alt="Banner"
            />
            <img
              style={{ maxWidth: '100%', height: '20vh' }}
              className="d-block d-md-none"
              src={require('assets/img/brand/Banner3.jpg')}
              alt="Banner"
            />
          </div>
        </CardTitle>
      </Card>
    </>
  );
}

export default LeftPanel;
