/* eslint-disable jsx-a11y/anchor-is-valid */
import { StepperContext } from 'components/Stepper/context';
import { StepperFooter } from 'components/Stepper/Stepper';
import { removeTimeFromMoment } from 'firestore/db/helpers';
import { useBookSevaState } from 'hooks/useBookSevaState';
import React, { useContext, useState } from 'react';
import { Button, Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import moment from 'moment';
import useAuth from 'hooks/useAuth';
import Swal from 'sweetalert2';
import { DATEPICKER_MODES, TRANSACTION_STATUS } from './constants';
import Create from 'firestore/db/create/base';
import axios from 'axios';
import shortUUID from 'short-uuid';
import firebase from 'firebase';

const apiBaseURL =
  process.env.VERCEL_ENV !== 'development' ? '/api' : 'http://localhost:3000/api';
const notifyBaseURL =
  process.env.VERCEL_ENV !== 'development'
    ? `${window.location.origin}/api`
    : 'http://localhost:3000/api';

const PoojaSummary = ({ pooja }) => {
  return (
    <div style={{ minWidth: '60%' }} className="border-bottom border-top py-3">
      <div className="p-0">
        <Row className="mx-0 text-center">
          <Col className="col col-md-8">
            <Row className="d-flex flex-md-row flex-column mx-0">
              <h3 className="col-md-8 text-center">{pooja.seva}</h3>
              <small className="col-md-4 text-center mt-sm-2">
                {pooja.numberOfDays} x {pooja?.amountPerDay}/day
              </small>
            </Row>
          </Col>
          <Col className="col-4 col-md-4">
            <h3 className="text-center">
              <span>&#x20B9;</span> {pooja.amount}
            </h3>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const Total = ({ amount }) => {
  return (
    <div className="col-md-4 offset-md-8 my-5">
      <h2 className="display-5 text-center">
        Total : <span>&#x20B9;</span> {amount}
      </h2>
    </div>
  );
};
const getSelectedDates = item => {
  let selectedDates = [];
  if (item.mode === DATEPICKER_MODES.SINGLE) {
    const selectedDate = removeTimeFromMoment(
      moment(
        `${item.selectedDates.year}/${item.selectedDates.month}/${item.selectedDates.day}`
      )
    );
    selectedDates.push(selectedDate);
    return selectedDates;
  }
  if (item.mode === DATEPICKER_MODES.MULTIPLE) {
    selectedDates = item.selectedDates.map(date => {
      const selectedDate = removeTimeFromMoment(
        moment(`${date.year}/${date.month}/${date.day}`)
      );
      return selectedDate;
    });
    return selectedDates;
  }
  if (item.mode === DATEPICKER_MODES.RANGE) {
    const fromDate = removeTimeFromMoment(
      moment(`${item.from.year}/${item.from.month}/${item.from.day}`)
    );
    const toDate = removeTimeFromMoment(
      moment(`${item.to.year}/${item.to.month}/${item.to.day}`)
    );
    selectedDates = {
      startDate: fromDate,
      endDate: toDate
    };
    return selectedDates;
  }
};
const getFormattedPoojas = poojas => {
  const newPoojas = poojas?.map(pooja => {
    const poojaItem = {
      selectedPooja: pooja.seva,
      selectedDates: getSelectedDates(pooja),
      isRange: pooja?.mode === DATEPICKER_MODES.RANGE
    };
    return poojaItem;
  });
  return newPoojas;
};
function CheckoutSummary() {
  const { steps, page, paginate } = useContext(StepperContext);
  const [{ people, poojas }] = useBookSevaState();
  const { auth } = useAuth();
  const total = poojas?.reduce((acc, item) => {
    acc += Number.parseInt(item.amount);
    return acc;
  }, 0);
  const [loading, setLoading] = useState(false);
  const submit = async data => {
    try {
      setLoading(true);
      const peopleWithSequence = people.map((person, index) => ({ ...person, seq: index }))
      const order = {
        order_id: `ord_${shortUUID.generate()}`,
        order_amount: total?.toFixed(2),
        order_currency: 'INR',
        customer_details: {
          customer_id: auth?.uid,
          customer_name: auth?.displayName,
          customer_email: auth?.email || 'Shankarmuthyd@gmail.com',
          customer_phone: auth?.phoneNumber
        },
        order_meta: {
          return_url: `${window.location.origin}/user/checkout-verify?order_id={order_id}&order_token={order_token}`,
          notify_url: `${notifyBaseURL}/orders/notify`
        }
      };

      const authToken = await firebase.auth().currentUser.getIdToken();
      const result = await axios.post(
        `${apiBaseURL}/orders/create`,
        { ...order },
        {
          headers: {
            Authorization: authToken
          }
        }
      );
      const url = result?.data?.url;
      const order_token = result?.data?.order_token;
      const order_id = result?.data?.order_id;
      if (!order_id || !order_token) {
        throw new Error(
          'Failed in creating the order from server. Missing orderId or orderToken'
        );
      }
      const formattedPoojas = getFormattedPoojas(poojas);
      const transactionData = {
        transactionId: order_id,
        orderId: order_id,
        personDetails: peopleWithSequence,
        poojaDetails: formattedPoojas,
        createdBy: auth?.uid,
        status: TRANSACTION_STATUS.PENDING,
        amount: total,
        createdOn: removeTimeFromMoment(moment())
      };
      await new Create('transactions').Set(transactionData);
      window.location.replace(url);
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Server Error',
        text: `Failed due to: ${e?.message}. <br /> Please try again`
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column w-100">
      <Row className="mx-0 py-3 bg-default d-flex justify-content-between flex-grow-1 flex-wrap align-items-center">
        <div className="my-2 my-md-0 mb-3 col-md-5 px-5 d-flex justify-content-center justify-content-md-between ">
          <h3 className="m-0 font-weight-200 text-white">
            {'Checkout Summary'}
          </h3>
        </div>
        <div className="d-flex flex-grow-1 text-white justify-content-center justify-content-md-end mr-md-8">
          No. of People: {people?.length}
        </div>
      </Row>
      <Card>
        <CardBody className="p-0">
          <div className="d-flex flex-column justify-content-center align-items-lg-center pt-sm-5">
            {poojas.map(poojaItem => (
              <PoojaSummary key={poojaItem.seva} pooja={poojaItem} />
            ))}
          </div>
          <Total amount={total} />
        </CardBody>
      </Card>
      {loading && (
        <Row className="py-3 d-flex flex-column justify-content-center align-items-center">
          <p className="text-dark font-weight-600">Checking out...</p>
          <Spinner color="primary" />
        </Row>
      )}
      <StepperFooter>
        <Col className="my-2 d-flex justify-content-end">
          <Button
            disabled={page === 0}
            onClick={() => paginate(-1)}
            className="btn-warning"
          >
            Back
          </Button>
          <Button
            onClick={submit}
            disabled={page === steps?.length}
            color="default"
          >
            Pay Now
          </Button>
        </Col>
      </StepperFooter>
    </div>
  );
}

export default CheckoutSummary;
