/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Redirect, Switch, Route, withRouter } from 'react-router-dom';
// reactstrap components
// import { Container } from "reactstrap";
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
// import AdminFooter from "components/Footers/AdminFooter.js";
// import Sidebar from "components/Sidebar/Sidebar.js";

import routes from 'routes.js';
import { AnimatePresence, motion } from 'framer-motion';
import './Admin.scss';
import Footer from 'components/Footers/AdminFooter';
import BottomNavbar from 'components/Navbars/BottomNavbar';

class Admin extends React.Component {
  state = {
    key: false
  };
  componentDidMount() {
    this.setState({ key: Math.random() });
  }
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === '/user') {
        const Component = prop.component;
        return (
          <Route path={prop.layout + prop.path} key={key}>
            <motion.div
              style={{ flexGrow: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <Component />
            </motion.div>
          </Route>
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };
  render() {
    return (
      <>
        <div
          style={{
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}
          ref="mainContent"
        >
          <AdminNavbar
            onLinkClicked={() => this.setState({ key: Math.random() })}
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <div
            style={{ overflow: 'auto', minHeight: 0 }}
            className="content d-flex flex-column flex-grow-1"
          >
            <div className="body-container">
              <AnimatePresence exitBeforeEnter={true}>
                <Switch key={'123'}>
                  {this.getRoutes(routes)}
                  <Redirect from="*" to="/user/home" />
                </Switch>
              </AnimatePresence>
            </div>
            <Footer />
          </div>
          <BottomNavbar />
        </div>
      </>
    );
  }
}

export default withRouter(Admin);
