import React, { memo, useState } from 'react';
import { Button, Card, Container } from 'reactstrap';
import { FormInput } from 'components/FormControls';
import Report from './Report';

const masterPassword = 'SriShankarmath2020';

function Reports() {
  const [password, setpassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isError, setError] = useState(false);
  const submitHandler = () => {
    if (password === masterPassword) {
      setIsPasswordValid(true);
    } else {
      setError(true);
    }
  };

  return (
    <Container>
      <Card className="bg-transparent border-0 m-3 m-sm-5 p-md-4 p-2">
        {isPasswordValid ? (
          <Report></Report>
        ) : (
          <>
            <FormInput
              className={'bg-transparent'}
              label="Enter the password"
              value={password}
              type="password"
              onChange={e => setpassword(e.target.value)}
            />
            {isError ? (
              <p className="text-danger">
                {' '}
                Invalid password entered. Try again
              </p>
            ) : null}
            <Button onClick={submitHandler} className="bg-default text-white">
              SUBMIT
            </Button>
          </>
        )}
      </Card>
    </Container>
  );
}

export default memo(Reports);
