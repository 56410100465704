import { mergeWith, cloneDeep, isArray, merge } from 'lodash';

const defaultMerger = (
  srcValue: any,
  destValue: any
  // key,
  // src,
  // dest
) => {
  if (isArray(srcValue)) {
    return srcValue.concat(destValue);
  }
  return undefined;
};

export function mergeDeep<T>(
  srcObject: T,
  destObject: T,
  replace = false,
  merger = defaultMerger
) {
  const clonedSrcObject = cloneDeep(srcObject);
  if (replace) {
    merge(clonedSrcObject, destObject);
  } else {
    mergeWith(clonedSrcObject, destObject, merger);
  }
  return clonedSrcObject;
}
