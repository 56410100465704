import React from 'react';
import Stepper from 'components/Stepper';
import PersonDetails from './PersonDetails';
import PoojaDetails from './PoojaDetails';
import CheckoutSummary from './CheckoutSummary';
import Disclaimer from './Disclaimer';
import usePersonDetails from 'hooks/usePersonDetails';
const steps = [
  { title: 'Disclaimer' },
  { title: 'Pick Seva' },
  { title: 'Fill Details' },
  { title: 'Payment' }
];

function BookSeva() {
  usePersonDetails()
  return (
    <Stepper steps={steps}>
      <Disclaimer />
      <PoojaDetails />
      <PersonDetails />
      <CheckoutSummary />
    </Stepper>
  );
}

export default BookSeva;
