import React from 'react';
import { Card, CardBody, CardTitle, Container } from 'reactstrap';
import { fullHistory } from '../Homepage/data';

function About() {
  return (
    <Container>
      <Card className="bg-transparent m-0 border-0 mt-2">
        <CardTitle className="mb-0">
          <div className="container d-flex justify-content-center bg-darker">
            <img
              style={{ maxHeight: '45vh', maxWidth: '100%' }}
              className="d-none d-md-flex img-fluid"
              src={require('assets/img/brand/Banner3.jpg')}
              alt="Banner"
            />
            <img
              style={{ maxWidth: '100%', height: '20vh' }}
              className="d-block d-md-none"
              src={require('assets/img/brand/Banner3.jpg')}
              alt="Banner"
            />
          </div>
        </CardTitle>
        <CardBody className="d-flex flex-column align-items-center p-1">
          <section className="border mb-2" id="temple-history">
            <div
              style={{ background: '#A5281F' }}
              className="card p-2 mt-0 rounded-0"
            >
              <div className="d-flex justify-content-start display-5 text-white font-weight-500">
                Temple History
              </div>
            </div>
            <p style={{ color: '#A5281F' }} className="font-weight-500 p-2">
              {fullHistory}
            </p>
          </section>
          {/* <div
            style={{ color: '#A5281F' }}
            className="display-5 font-weight-bold "
          >
            {templeInfo.name}
          </div>
          <small
            style={{ color: '#A5281F', maxWidth: 185, lineHeight: 2 }}
            className="text-center font-weight-600"
          >
            {templeInfo.address} <br />
            {templeInfo.contact}
          </small> */}
        </CardBody>
      </Card>
      <div
        style={{ background: '#A5281F' }}
        className="card p-2 mt-0 rounded-0"
      >
        <div className="d-flex justify-content-start display-5 text-white font-weight-500">
          Location
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.229114598248!2d78.5057773148765!3d17.400788988070563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99bca339b83d%3A0x3a22b15664ed9c3e!2sShankar%20Mutt!5e0!3m2!1sen!2sin!4v1601475709213!5m2!1sen!2sin"
        // width="100%"
        // height="600"
        className="w-100 img-fluid"
        frameborder="0"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
        title="shankermutt-location"
      ></iframe>
    </Container>
  );
}

export default About;
