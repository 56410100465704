import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

function CardList({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(true);
  }, []);
  return (
      <motion.ul
        className="p-1"
        variants={variants}
        initial={false}
        animate={isOpen ? "open" : "closed"}
      >
        {children}
      </motion.ul>
  );
}

export default CardList;
