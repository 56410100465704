export default (state, action) => {
  switch (action.type) {
    case 'AUTH_CHANGE':
      return {
        ...state,
        auth: action.payload.auth,
        authAttempted: action.payload.authAttempted
      };
    case 'UPDATE_USER_PROFILE':
      return {
        ...state,
        auth: {
          ...state.auth,
          displayName: action.payload.displayName,
          email: action.payload.email
        }
      };
    default:
      return state;
  }
};
