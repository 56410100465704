import { isTomorrow, startOfTomorrow } from 'date-fns';
import { isAfter } from 'date-fns/esm';
import { DATEPICKER_MODES } from './constants';
import moment from 'moment';
import { groupBy, isEmpty } from 'lodash';

export const getDefaultDate = (availableDates, tomorrow) => {
  if (availableDates && availableDates.length === 0) {
    return {
      day: tomorrow.getDate(),
      month: tomorrow.getMonth() + 1,
      year: tomorrow.getFullYear()
    };
  }
  const defaultDate = availableDates
    ?.sort((a, b) => a - b)
    .reduce((acc, date, index) => {
      if (isEmpty(acc) && (isAfter(date, tomorrow) || isTomorrow(date))) {
        acc = {
          day: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear()
        };
      }
      return acc;
    }, {});
  return defaultDate;
};
export const momentTomorrow = startOfTomorrow();
export const maxDate = {
  day: 31,
  month: 12,
  year: 2025
};
export const tomorrow = {
  day: momentTomorrow.getDate(),
  month: momentTomorrow.getMonth() + 1,
  year: momentTomorrow.getFullYear()
};

export const getOptions = (field, availableDates) => {
  let options = field?.options?.reduce((acc, option) => {
    if (field.id === 'mode' && availableDates.length > 0) {
      if (option.value !== 'range') {
        acc = acc.concat(option);
        return acc;
      }
    } else {
      acc = acc.concat(option);
      return acc;
    }
    return acc;
  }, []);
  if (field.id === 'seva') {
    options = groupBy(options, 'category');
  }
  return options;
};
export const getDateDifference = (from, to) => {
  const fromDate = moment(
    `${from?.year}-${from?.month}-${from?.day}`,
    'YYYY-MM-DD'
  );
  const toDate = moment(`${to?.year}-${to?.month}-${to?.day}`, 'YYYY-MM-DD');
  moment.duration(fromDate.diff(toDate)).asDays();
  return toDate.diff(fromDate, 'days') + 1;
};
export const getNumberOfDays = (
  mode = DATEPICKER_MODES.SINGLE,
  selectedDates = [],
  from,
  to
) => {
  if (mode === DATEPICKER_MODES.SINGLE) {
    return 1;
  }
  if (mode === DATEPICKER_MODES.MULTIPLE) {
    return selectedDates.length;
  }
  if (mode === DATEPICKER_MODES.RANGE) {
    return getDateDifference(from, to);
  }
};
