import React from 'react';
import { Card, CardHeader, Table } from 'reactstrap';

function DataTable({ rows, columns, title, withIndex = true }) {
  return rows && columns ? (
    <Card className="bg-transaprent my-3">
      <CardHeader className="border-0 bg-transaprent">
        <h3 className="mb-0">{title}</h3>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            {withIndex && (
              <th className="font-weight-bold text-black" scope="col">
                No.
              </th>
            )}
            {columns?.map(column => (
              <th
                className="font-weight-bold text-black"
                key={column.label}
                scope="col"
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => (
            <tr key={index}>
              {withIndex && (
                <td key={'index' + index} className="font-weight-bold">
                  {index + 1}
                </td>
              )}
              {columns?.map(column => (
                <td
                  className="font-weight-bold text-capitalize"
                  key={column.key}
                >
                  {' '}
                  {row[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  ) : null;
}

export default DataTable;
