import { auth, User } from "firebase";
import BaseAuth from "./baseAuth";
import { AuthProviders } from "../utils/authProviders";
import { getThirdPartyAuthProvider } from "../utils/helpers";
import Log from "../../../utils/log";

export default class ThirdPartyAuth extends BaseAuth {
  private _provider: auth.AuthProvider;
  protected _userCredential?: auth.UserCredential;
  constructor(provider: AuthProviders) {
    super();
    this._provider = getThirdPartyAuthProvider(provider);
  }
  public get UserCredential(): auth.UserCredential | undefined {
    return this._userCredential;
  }
  protected async onUserChanged(user: User | null) {
    this._userCredential = await auth().getRedirectResult();
  }

  public async getUserCredential(): Promise<void> {
    if (!this._userCredential?.user) {
      try {
        this._userCredential =
          this._userCredential || (await auth().getRedirectResult());
      } catch (error) {
        Log.error(error as string);
        // const authError=error as auth.AuthError;
        // if(authError.credential){
        //   authError.credential
        // }
      }
    }
  }
  public async signInWithProvider(): Promise<void> {
    if (this._provider) {
      await auth().signInWithRedirect(this._provider);
    }
  }
}
