/**
 * Enumeration for Auth providers supported currently
 *
 * @export
 * @enum {number}
 */
export enum AuthProviders {
  Google = "google.com",
  Facebook = "facebook.com",
  Credential = "password",
  Phone = "phone",
}
