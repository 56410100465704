import React from "react";
import moment from "moment";
import { HistoryCard, Body, Field } from "components/Cards";

const STATUS = {
  FAILED: "Failed",
  PENDING: "Pending",
  SUCCESS: "Success",
};

function DonationCard({ item, index }) {
  return (
    item && (
      <HistoryCard>
        <Body className="p-3">
          <Field
            title={"Donated On"}
            value={moment().format("ddd, MMM Do YYYY")}
          />
          <Field
            title={"Payment Status"}
            className={
              (STATUS.FAILED === item.status && "text-danger") ||
              (STATUS.PENDING === item.status && "text-warning") ||
              (STATUS.SUCCESS === item.status && "text-success") ||
              "text-dark"
            }
            value={item.status}
          />
          <Field title={"Amount"} value={item.amount} />
          <Field title={"Name"} value={item.name} />
          <Field title={"Purpose"} value={item.purpose} />
        </Body>
      </HistoryCard>
    )
  );
}

export default DonationCard;
