export const ADD_PERSON = 'ADD_PERSON';
export const DELETE_PERSON = 'DELETE_PERSON';
export const INPUT_CHANGED = 'INPUT_CHANGED';
export const ADD_PEOPLE = 'ADD_PEOPLE';
export const ADD_POOJA = 'ADD_POOJA';

export const addPerson = () => ({
  type: ADD_PERSON
});
export const deletePerson = payload => ({
  type: DELETE_PERSON,
  payload
});
export const inputChanged = payload => ({
  type: INPUT_CHANGED,
  payload
});

export const addPeople = payload => ({
  type: ADD_PEOPLE,
  payload
});
export const addPooja = payload => ({
  type: ADD_POOJA,
  payload
});
