import React from 'react';
import './Maintain.scss';

function Maintain() {
  return (
    <div class="full-size layout-column layout-align-center-vertical bootstrap snippets bootdeys">
      <div class="size-7 horizontal-align">
        <div class="panel panel-default">
          <div class="panel-body text-center">
            <div class="logo-box logo-box-primary padding-top-4">
              <b>Sri Shankarmath Nallakunta Hyderabad,</b>
            </div>
            <h2>Under Maintenance</h2>
            <p>
              We will be down for a short time.
              <br />
              Please check back later
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Maintain;
