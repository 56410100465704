import { Grouper, Transform } from './../helpers/index';
import { firestore } from 'firebase';

export enum SortOptions {
  Ascending = 'asc',
  Descending = 'desc'
}

/**
 * Filter interpreted by QuerySpec's `filter`
 *
 *  *Please refer to firestore query limitations*
 *  [`here`](https://firebase.google.com/docs/firestore/query-data/queries#limitations)
 *  and [`here`](https://firebase.google.com/docs/firestore/query-data/queries#query_limitations)
 *
 * __Any or All such limitations are NOT validated and handled beforehand *(as of now)*__
 * @enum {number}
 */
export enum FilterOperator {
  lessThan = '<',
  lessThanOrEqualTo = '<=',
  equalTo = '==',
  greaterThanOrEqualTo = '>=',
  greaterThan = '>',
  arrayContains = 'array-contains',
  in = 'in',
  arrayContainsAny = 'array-contains-any'
}

export const MAX_FILTER_OPERATOR_IN_COUNT = 10;

export enum QueryCompounder {
  and,
  or
}

export type FireStoreField = string | firestore.FieldPath;

export type FilterQueryDef = {
  field: FireStoreField;
  operator: FilterOperator;
  value: any;
};

type CompoundQueryDefBase = {
  compounder: QueryCompounder;
};

export type CompoundQueryDef = CompoundQueryDefBase & {
  conditions: FireStoreDocQueryChainer[];
};

export type NestedCompoundQueryDef = CompoundQueryDefBase & {
  conditions: (FireStoreDocQueryChainer | CompoundQueryDef)[];
};

export type FireStoreDocQueryChainer = (
  docQuery: firestore.Query<firestore.DocumentData>
) => firestore.Query<firestore.DocumentData>;

export type CustomFilterQuery = {
  field: FireStoreField;
  query?: FireStoreDocQueryChainer;
  compoundQuery?: NestedCompoundQueryDef;
};

export type FilterQuery = FilterQueryDef | CustomFilterQuery;

/**
 *  Order query interpreted by *QuerySpec's* `orderBy`
 *
 *  *Please refer to the limitations imposed by firestore's orderBy*
 *  [`here`](https://firebase.google.com/docs/firestore/query-data/order-limit-data#limitations)
 *
 * __Any or All such limitations are NOT validated and handled beforehand *(as of now)*__
 */
export type OrderQuery = {
  field: FireStoreField;
  sortBy: SortOptions;
};

export type PagingQuery = {
  skip: number;
  take: number;
};

export enum SubCollectionQueryOption {
  withoutSubCollection,
  withSubCollection,
  onlySubCollection
}

export type QueryOptions = {
  orderBy?: OrderQuery;
  paging?: PagingQuery;
  groupBy?: Grouper;
  transformer?: Transform | Transform[];
  subCollectionQueryOption?: SubCollectionQueryOption;
};

export type QuerySpec = {
  filter?: FilterQuery[];
  documentId?: string;
} & QueryOptions;

export type CustomFirestoreDocDataQuery = {
  get: () => Promise<{
    docs: firestore.QueryDocumentSnapshot<firestore.DocumentData>[];
  }>;
};
