import { firestore } from 'firebase';
import Project from '../../init/project';
if (window.location.hostname === 'localhost') {
  Project.Instance.firestore().settings({
    host: 'localhost:8080', ssl: false
  })
}
export default class Db {
  static Instance = Project.Instance.firestore();
  static Initialize(firestoreInstance: firestore.Firestore) {
    Db.Instance = firestoreInstance;
  }
}
