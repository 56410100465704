import { Providers, AuthProviders } from 'firestore/auth';
import { resetPassword, sendPasswordResetEmail, handleVerifyEmail, checkIfUserExists } from 'firestore/auth/utils/helpers';
import { startsWith } from 'lodash';

export class OAuthLogin {
    static login = (provider = AuthProviders.Facebook) => {
        const Provider = new Providers.ThirdPartyAuth(provider);
        Provider.signInWithProvider();
        return Provider.getUserCredential();
    }
    static providers = AuthProviders;
}

type Country = {
    dialCode: string
}
export class LoginUtils {
    static validateEmail = (value: string) => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
    };
    static validatePhoneNumber = (inputNumber: string, country: Country, countries: Array<Country>) => {
        return countries.some((country: Country) => {
            return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
        });
    }
    static sendPasswordResetEmail = sendPasswordResetEmail;
    static resetPassword = resetPassword;
    static verifyEmail = handleVerifyEmail;
    static checkIfUserExits = checkIfUserExists;
}
export class PhoneAuth {
    phoneAuth: Providers.PhoneAuth;
    constructor(recaptchaID: string, success: () => void, fail: () => void) {
        this.phoneAuth = new Providers.PhoneAuth(recaptchaID, success, fail);
    }
    sendOTP = (phone: string) => {
        this.phoneAuth.initiatePhoneLogin(phone);
    }
    verifyCode = (code: string) => {
        this.phoneAuth.verifyPhoneNumber(code);
    }
}
export class EmailAuth {
    emailAuth: Providers.CredentialAuth;
    constructor(email: string, password: string, redirectURI?: string) {
        this.emailAuth = new Providers.CredentialAuth(email, password, redirectURI || '');
    }
    signIn = async () => {
        await this.emailAuth.authenticateUserWithEmail();
    }
}

export default OAuthLogin;