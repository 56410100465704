import { firestore } from 'firebase';
import { throwErrorAndLog } from '../../../../utils/helpers';
import Db from '../../init/db';
import { CollectionDef } from '../../schema/models/schema';
import { SubCollectionQueryOption } from '../queryOptions';
import CollectionReaderBase from './collectionReaderBase';

export default class DocCollectionGroupReader extends CollectionReaderBase {
  constructor(collectionDef: CollectionDef) {
    super(collectionDef, Db.Instance.collectionGroup(collectionDef.id));
  }
  public async getDocument(
    documentId: string,
    subCollectionQueryOption?: SubCollectionQueryOption
  ): Promise<firestore.DocumentData | undefined> {
    throwErrorAndLog(
      `Get document by id is not currently supported for subcollections in firebase`
    );
    return;
  }
}
