/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Lottie from 'react-lottie';
import successAnimationData from './success.json';
import loading from './loading.json';
import error from './error.json';
import { Card, CardBody, Container } from 'reactstrap';
import useVerifyOrder from 'hooks/useVerifyOrder';
import { useHistory } from 'react-router-dom';

function CheckoutVerify() {
  const orderOptions = useVerifyOrder();
  const history = useHistory();
  const { isLoading, isPaid, isError, isExpired, isActive } = orderOptions;
  const defaultOptions = {
    loop: isLoading,
    autoplay: true,
    animationData: isLoading
      ? loading
      : isPaid
      ? successAnimationData
      : isError || isExpired || isActive
      ? error
      : null
  };

  return (
    <Container className="pt-3 position-relative h-100 w-100">
      <div className="d-flex justify-content-center h-100 w-100">
        <Card className="bg-transparent border-0 p-2 p-sm-5">
          <CardBody className="d-flex flex-column justify-content-center align-items-center">
            <Lottie
              options={defaultOptions}
              height={isLoading ? '20px' : '200px'}
              width={'200px'}
            />
            {isLoading && (
              <div className="text-center">
                {' '}
                <small>Validating payment information...</small>{' '}
              </div>
            )}
            {isPaid && (
              <>
                <div className="text-center display-4">
                  {' '}
                  <small>Transaction successfull!</small>{' '}
                </div>
                <small className="d-block text-center">
                  {' '}
                  You can find the details of the transaction in Transaction
                  History
                </small>
              </>
            )}
            {(isError || isActive) && (
              <>
                <div className="text-center display-4">
                  {' '}
                  <small>Transaction failed!</small>{' '}
                </div>
                <small className="d-block text-center">
                  {' '}
                  Something went wrong. Please try booking again
                </small>
              </>
            )}
            {isExpired && (
              <>
                <div className="text-center display-4">
                  {' '}
                  <small>Transaction expired</small>{' '}
                </div>
                <small className="d-block text-center">
                  {' '}
                  Your transaction has expired. Please try booking it again
                </small>
              </>
            )}
            {!isLoading && (
              <div className="d-flex my-5 p-4 flex-column justify-content-center align-items-center">
                <buttonon
                  onClick={() => history.replace('/user/seva/history')}
                  className="btn btn-default btn-block m-0 mb-3"
                >
                  {' '}
                  Go to Transaction History
                </buttonon>
                <button
                  onClick={() => history.replace('/user/seva/book')}
                  className="btn btn-outline-default btn-block m-0"
                >
                  {' '}
                  Book Another Seva
                </button>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </Container>
  );
}

export default CheckoutVerify;
