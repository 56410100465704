import Query from 'firestore/db/read/queryBase';
import {
  FilterOperator,
  SubCollectionQueryOption
} from 'firestore/db/read/queryOptions';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import useAuth from 'hooks/useAuth';
import { orderBy } from 'lodash';

function usePersonDetails() {
  const { auth } = useAuth();
  const getPeopleDetails = useCallback(async () => {
    const result = await Query.from('transactions').Execute({
      filter: [
        {
          field: 'createdBy',
          operator: FilterOperator.equalTo,
          value: auth?.uid
        }
      ],
      transformer: {
        accessor: [transaction => transaction.personDetails],
        transformer: data => data
      },
      subCollectionQueryOption: SubCollectionQueryOption.withSubCollection
    });
    const personDetails = result?.reduce(
      (prev, transaction) => prev.concat(transaction?.personDetails),
      []
    );
    const uniquePersonDetails = personDetails?.reduce(
      (prev, person, index) => {
        if (index === personDetails?.length - 1) {
          return prev?.result;
        }
        if (!(person?.name in prev?.cache)) {
          prev.cache[`${person.name}`] = person?.name?.replaceAll(' ', '');
          const { name, gothram, nakshatram } = person;
          prev.result.push({ name, gothram, nakshatram });
        }
        return prev;
      },
      { cache: {}, result: [] }
    );
    const sortedTransactions = orderBy(uniquePersonDetails, 'name');
    return Promise.resolve(sortedTransactions);
  }, [auth.uid]);
  const query = useQuery('personDetails', getPeopleDetails, {
    enabled: auth?.uid !== null,
    staleTime: Infinity
  });
  return query;
}

export default usePersonDetails;
