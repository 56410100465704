import React, { useState, useEffect, useRef } from 'react';
import PhoneInput from 'react-phone-input-2';
// import './PhoneInput.scss';
import { LoginUtils } from 'utils/login';

function PhoneNumber({ emailOrPhone, setError, onChange, error }) {
  const [phoneNumber, setPhoneNumber] = useState(emailOrPhone || '');
  const [isTouched, setIsTouched] = useState(false);
  const inputRef = useRef({ valid: true });
  useEffect(() => {
    if (!inputRef.current.valid) {
      setError(true);
    } else {
      // onChange(phoneNumber)
      setError(false);
    }
    onChange(phoneNumber);
  }, [phoneNumber, onChange, inputRef, setError]);
  return (
    <PhoneInput
      isValid={
        isTouched
          ? (inputNumber, country, countries) => {
              const valid = LoginUtils.validatePhoneNumber(
                inputNumber,
                country,
                countries
              );
              if (isTouched) {
                inputRef.current.valid = valid;
              }
              return valid;
            }
          : undefined
      }
      enableSearch={true}
      inputProps={{
        name: 'phone',
        required: true,
        autoFocus: true
      }}
      placeholder="Enter phone number"
      country={'in'}
      value={phoneNumber}
      onChange={number => {
        if (!isTouched) {
          setIsTouched(true);
        }
        setPhoneNumber(number);
      }}
    />
  );
}

export default PhoneNumber;
