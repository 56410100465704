import React from 'react';
import { Container } from 'reactstrap';

function Cancellation() {
  return (
    <Container className="p-5">
      <h1>Refund policy</h1>
      <p>
        Since the Website offers non-tangible, irrevocable goods, we do not
        provide refunds after the ticket is purchased, which you acknowledge
        prior to purchasing any ticket on the Website. Please make sure that
        you've carefully read ticket disclaimer before making a purchase.
      </p>
      <h2>Contacting us</h2>
      <p>
        If you would like to contact us concerning any matter relating to this
        Refund Policy, you may send an email to
        s&#104;&#97;&#110;k&#97;r&#109;u&#116;h&#121;d&#64;&#103;&#109;a&#105;l&#46;&#99;o&#109;
      </p>
      <p>This document was last updated on November 1, 2020</p>
    </Container>
  );
}

export default Cancellation;
