import { auth } from "firebase";
import Project from "../../init/project";
import AuthService from "../utils/authService";
import BaseAuth from "./baseAuth";
/**
 * Phone number based authentication
 *
 * @export
 * @class PhoneAuth
 * @extends {BaseAuth}
 */
export default class PhoneAuth extends BaseAuth {
  protected _userCredential?: auth.UserCredential | undefined;
  private _recaptchaVerifier: auth.RecaptchaVerifier;
  private _confirmationResult?: auth.ConfirmationResult;

  /**
   * Gets the Recaptcha verifier object required for phone authentication
   *
   * @static
   * @param {string} elementId
   * @param {() => void} captchaSuccessCallback
   * @param {() => void} [captchaExpiredCallback]
   * @returns {auth.RecaptchaVerifier}
   * @memberof PhoneAuth
   */
  public static getRecaptcha(
    elementId: string,
    captchaSuccessCallback: () => void,
    captchaExpiredCallback?: () => void
  ): auth.RecaptchaVerifier {
    return new auth.RecaptchaVerifier(
      elementId,
      {
        size: "invisible",
        callback: captchaSuccessCallback,
        "expired-callback": captchaExpiredCallback || (() => ({})),
      },
      Project.Instance
    );
  }
  constructor(
    elementId: string,
    captchaSuccessCallback: () => void,
    captchaExpiredCallback: () => void
  ) {
    super();
    this._recaptchaVerifier = PhoneAuth.getRecaptcha(
      elementId,
      captchaSuccessCallback,
      captchaExpiredCallback
    );
  }

  public get UserCredential(): auth.UserCredential | undefined {
    return this._userCredential;
  }
  /**
   *Initiates login with phoneNumber
   *
   * @param {string} phoneNumber
   * @memberof Auth
   */
  public async initiatePhoneLogin(phoneNumber: string) {
    this._confirmationResult = await AuthService.Instance.signInWithPhoneNumber(
      phoneNumber,
      this._recaptchaVerifier
    );
  }

  /**
   *Verifies the phone number against verification code
   *
   * @param {string} verificationCode
   * @returns {Promise<void>}
   * @memberof PhoneAuth
   */
  public async verifyPhoneNumber(verificationCode: string): Promise<void> {
    // this.UserCredential = auth.PhoneAuthProvider.credential(
    //   this._confirmationResult?.verificationId || "",
    //   verificationCode
    // );
    this._userCredential = await this._confirmationResult?.confirm(
      verificationCode
    );
  }
}
