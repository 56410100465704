/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import {
  Container,
  Card,
  CardBody,
  Spinner,
  CardHeader,
  CardTitle
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import './Login.scss';
import Auth from 'views/examples/Homepage/Login';

import useAuth from 'hooks/useAuth';

function Login() {
  const { auth, authAttempted } = useAuth();

  if (authAttempted && auth) {
    const path = localStorage.getItem('redirect-url');
    if (path) {
      localStorage.removeItem('redirect-url');
    }
    const redirectpath = path || '/user/seva';
    return <Redirect to={redirectpath} />;
  }
  return !authAttempted ? (
    <Container>
      <Card className="d-flex flex-column justify-content-center align-items-center bg-transparent my-5 my-lg-8 p-5 p-lg-9 opacity-8 authenticating">
        <h4 className="text-dark"> Authenticating.....</h4>
        <Spinner color="default" />
      </Card>
    </Container>
  ) : (
    <Container className="d-flex justify-content-center align-items-center">
      <Card
        style={{ maxWidth: '600px' }}
        className="bg-transparent shadow m-lg-5 m-md-3 m-2"
      >
        <CardHeader className="p-2" style={{ background: '#a5281f' }}>
          <CardTitle className="text-white h3 mb-0 pl-3 ">Sign In</CardTitle>
          <small className="text-white pl-3">
            Welcome to Shankar Math Seva Ticket Booking
          </small>
        </CardHeader>
        <CardBody>
          <Auth />
        </CardBody>
      </Card>
    </Container>
    // <>
    //   <Container className="d-flex my-3 flex-column justify-content-center align-items-center ">
    //     <Col className="border rounded p-0" lg="5" md="7">
    //       <LoginContext.Provider
    //         value={{
    //           isPhoneNo,
    //           setIsSubmitted,
    //           isLogin: activeTab === '1',
    //           setIsPhoneNo,
    //           emailOrPhone,
    //           setEmailOrPhone
    //         }}
    //       >
    //         {isSubmitted ? (
    //           <PasswordCard />
    //         ) : (
    //           <Card className="bg-transparent shadow">
    //             {/* <CardHeader> */}
    //             <Nav className="tabs p-2" tabs>
    //               <NavItem className="flex-grow-1">
    //                 <NavLink
    //                   className={`p-3 text-center my-tab-nav ${
    //                     activeTab === '1' ? ' active-tab' : ''
    //                   }`}
    //                   onClick={() => setActiveTab('1')}
    //                 >
    //                   Sign In
    //                 </NavLink>
    //               </NavItem>
    //               <NavItem className="flex-grow-1">
    //                 <NavLink
    //                   className={`p-3 text-center my-tab-nav ${
    //                     activeTab === '2' ? 'active-tab' : ''
    //                   }`}
    //                   onClick={() => setActiveTab('2')}
    //                 >
    //                   Register
    //                 </NavLink>
    //               </NavItem>
    //             </Nav>
    //             {/* </CardHeader> */}
    //             <CardBody className="p-0">
    //               <TabContent activeTab={activeTab}>
    //                 <TabPane tabId="1">
    //                   <LoginCard />
    //                 </TabPane>
    //                 <TabPane tabId="2">
    //                   <RegisterCard />
    //                 </TabPane>
    //               </TabContent>
    //             </CardBody>
    //           </Card>
    //         )}
    //       </LoginContext.Provider>
    //     </Col>
    //   </Container>
    // </>
  );
}

export default Login;
