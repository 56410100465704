import React, { useEffect } from 'react';
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { INPUT_TYPES } from 'views/examples/SevaBooking/Booking/schema';
import { sortBy } from 'lodash';

function Text(
  {
    label,
    addonType,
    error,
    icon,
    placeholder,
    validateHelperText,
    className = '',
    id,
    type,
    options,
    required = false,
    initialValue = '',
    ...props
  },
  innerRef = React.createRef()
) {
  useEffect(() => {
    if (props.autoFocus) {
      // eslint-disable-next-line no-unused-expressions
      innerRef?.current?.scrollIntoView?.({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }, [innerRef, props.autoFocus]);
  return (
    <FormGroup key={id}>
      <Label className="form-control-label" for={id}>
        {label}
        {required ? '*' : ''}
      </Label>
      <InputGroup
        className={`input-group-alternative border ${error ? 'border-danger' : ''
          }`}
      >
        {icon && (
          <InputGroupAddon addonType={addonType}>
            <InputGroupText>
              <FontAwesomeIcon icon={icon} />
            </InputGroupText>
          </InputGroupAddon>
        )}
        <Input
          innerRef={innerRef}
          autoComplete="true"
          id={id}
          placeholder={placeholder}
          {...props}
          type={type}
          className={'form-control-label ' + className}
          children={
            type === INPUT_TYPES.SELECT ? (
              <>
                {initialValue === '' && (
                  <option value={''} hidden>
                    Select....
                  </option>
                )}
                {Array.isArray(options)
                  ? options?.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))
                  : Object.keys(options).map(category => {
                    return (
                      <optgroup key={category} label={category}>
                        {sortBy(options[category], 'label').map(
                          ({ value, label, disabled }) => (
                            <option
                              disabled={disabled}
                              key={value}
                              value={value}
                            >
                              {label}
                            </option>
                          )
                        )}
                      </optgroup>
                    );
                  })}
              </>
            ) : null
          }
        />
      </InputGroup>
      {error && (
        <p className="text-danger font-weight-500">
          <small>
            {error?.type === 'validate' ? validateHelperText : error.message}
          </small>
        </p>
      )}
    </FormGroup>
  );
}

export default React.forwardRef((props, ref) => (
  <Text {...props} innerRef={ref} />
));
