import Schema from '../schema';
import {
  checkIsSubCollection,
  getCollectionDef,
  getSubCollectionDef,
  getSubCollectionIds
} from '../schema/helpers';
import SubCollectionReader from './readers/subCollectionReader';
import DocCollectionReader from './readers/docCollectionReader';
import DocCollectionGroupReader from './readers/docCollectionGroupReader';

export default class Read {
  public static from(collectionId: string) {
    const isSubCollection = checkIsSubCollection(
      collectionId,
      Schema.SchemaDef
    );
    if (isSubCollection) {
      // Prefer this if subcollection is provided
      return new DocCollectionGroupReader(
        getSubCollectionDef(collectionId, Schema.SchemaDef)
      );
    }
    // Prefer this if collection is provided, but no subcollection
    // queries exist
    return new DocCollectionReader(
      getCollectionDef(collectionId, Schema.SchemaDef)
    );
  }

  // Prefer this if subcollection queries exist
  public static fromSubCollection(
    parentCollectionId: string,
    subCollectionIds: string[]
  ) {
    const queriedSubCollIds =
      subCollectionIds.length > 0
        ? subCollectionIds
        : getSubCollectionIds(parentCollectionId, Schema.SchemaDef) || [];
    return new SubCollectionReader(parentCollectionId, queriedSubCollIds);
  }
}
