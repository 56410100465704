import React, { useState } from 'react';
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Form,
  Spinner,
  Button,
  CardHeader,
  CardTitle
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { FormInput } from 'components/FormControls';
import AuthService from 'firestore/auth/utils/authService';
import { useAppState } from 'hooks/useAppState';

function UpdateProfile() {
  const [, dispatch] = useAppState();
  const { register, handleSubmit, errors, setError } = useForm({
    mode: 'onTouched'
  });
  const [loading, setLoading] = useState(false);
  const submit = async data => {
    const auth = AuthService.Instance.currentUser;
    try {
      setLoading(true);
      await auth.updateProfile({ displayName: data.name });
      if (data?.email !== '') {
        await auth.updateEmail(data.email);
      }
      dispatch({
        type: 'UPDATE_USER_PROFILE',
        payload: { displayName: data.name, email: data.email }
      });
    } catch (e) {
      setError('email', { type: 'manual', message: e?.message });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Container className="d-flex justify-content-center align-items-center">
      <Card className="bg-transparent shadow mt-5">
        <CardHeader className="p-2" style={{ background: '#a5281f' }}>
          <CardTitle className="text-white h4">Update Profile</CardTitle>
          <small className="text-white">
            Welcome to Shankar Math Seva Ticket Booking. Please complete your
            profile to start booking{' '}
          </small>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit(submit)}>
            <FormInput
              label="Name*"
              ref={register({
                required: 'Name cannot be empty. Please enter your name'
              })}
              name="name"
              type="text"
              className="bg-transparent shadow form-control-label"
              error={errors.name}
            />
            <FormInput
              label="Email"
              ref={register()}
              name="email"
              type="email"
              className="bg-transparent shadow form-control-label"
              error={errors.email}
            />
            {loading && (
              <div className="py-2 my-2 d-flex flex-column justify-content-center align-items-center">
                <Spinner />
                <small>{'Updating the profile....'}</small>
              </div>
            )}
            <Button type="submit" className="btn-block btn-default">
              Update Profile
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
}

export default UpdateProfile;
