import React, { useContext } from 'react';
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap';
import { StepperContext } from 'components/Stepper/context';
import { StepperFooter } from 'components/Stepper/Stepper';
import { guidelines } from './data';
const BulletPoint = ({ text, index }) => {
  return (
    <p className='display-6 mb-1 font-weight-400'>
        {index}. {text}
    </p>
  );
};

function Disclaimer() {
  const { steps, page, paginate } = useContext(StepperContext);
  return (
    <div className="d-flex flex-column">
      <Card>
        <CardHeader className="bg-default py-2 py-sm-3">
          <p className="mb-0 text-white display-5 font-weight-500">Ticket Booking Guidelines</p>
        </CardHeader>
        <CardBody style={{ overflow: 'auto' }}>
          {guidelines.map((text, index) => (
            <BulletPoint key={index} text={text} index={index + 1} />
          ))}
        </CardBody>
      </Card>
      <StepperFooter>
        <Col className="my-2 d-flex justify-content-end">
          <Button
            disabled={page === 0}
            onClick={() => paginate(-1)}
            className="btn-warning"
          >
            Back
          </Button>
          <Button
            onClick={() => paginate(1)}
            disabled={page === steps?.length}
            color="default"
          >
            Agree
          </Button>
        </Col>
      </StepperFooter>
    </div>
  );
}

export default Disclaimer;
