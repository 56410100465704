import { auth } from 'firebase';
import AuthService from '../utils/authService';
import { getSignInMethodsForEmail } from '../utils/helpers';
import BaseAuth from './baseAuth';
import { AuthProviders } from '../utils/authProviders';

/**
 * Credential based authentication (email and password)
 *
 * @export
 * @class CredentialAuth
 * @extends {BaseAuth}
 */
export default class CredentialAuth extends BaseAuth {
  protected _userCredential?: auth.UserCredential;
  _email: string;
  _password: string;
  _redirectUri?: string;

  constructor(email: string, password: string, redirectUri?: string) {
    super();
    this._email = email;
    this._password = password;
    this._redirectUri = redirectUri;
  }
  /**
   * Signing up using email and password provided in ctor
   *
   * @param {string} redirectUri required for embedding in email template (for verification)
   * @returns
   * @memberof CredentialAuth
   */
  public async signUp(redirectUri: string) {
    const firebaseCredential: auth.UserCredential = await AuthService.Instance.createUserWithEmailAndPassword(
      this._email,
      this._password
    );
    if (firebaseCredential && firebaseCredential.user) {
      firebaseCredential.user.sendEmailVerification({
        url: /* "http://localhost:8080" */ redirectUri,
        handleCodeInApp: false
      });
    }
    return firebaseCredential;
  }
  /**
   * Login using email and password provided in ctor
   * (Checks if email is verified)
   * @returns
   * @memberof CredentialAuth
   */
  public async login() {
    const userCredential = await AuthService.Instance.signInWithEmailAndPassword(
      this._email,
      this._password
    );
    if (!userCredential.user?.emailVerified) {
      throw new Error('Email needs to be verified for completion of signup');
    }
    return userCredential;
  }
  public get UserCredential(): auth.UserCredential | undefined {
    return this._userCredential;
  }
  public async authenticateUserWithEmail() {
    const signInMethods = await getSignInMethodsForEmail(this._email);
    const userExists = signInMethods.includes(AuthProviders.Credential);
    if (!userExists) {
      if (!this._redirectUri) {
        throw new Error('Redirect Uri is needed for a user signup');
      }
      this._userCredential = await this.signUp(this._redirectUri);
    } else {
      this._userCredential = await this.login();
    }
  }

  public async updatePassword(password: string) {
    await this._userCredential?.user?.updatePassword(password);
  }
}
