import {
  BaseCollectionSchemaDef,
  CollectionSchemaDef,
  GenericCollectionSchemaDefs
} from '../../models/collectionsSchema';
import { toArray } from '../../../helpers';
import { throwErrorAndLog } from '../../../../../utils/helpers';
import { flattenDeep } from 'lodash';

export default abstract class SchemaValidatorBase {
  protected validateCollectionIds(
    collectionIds: string[],
    collectionSchemaDef: GenericCollectionSchemaDefs
  ) {
    collectionIds.forEach(collId => {
      if (!(collId in collectionSchemaDef)) {
        throwErrorAndLog(
          `Invalid Schema specified. Collection or Sub-Collection "${collId}" 
                has no schema defined`
        );
      }
    });
  }

  protected validateCollectionSchema(schemaDef: BaseCollectionSchemaDef) {
    const keys = flattenDeep(
      Object.values(schemaDef).filter(fieldDef =>
        toArray(fieldDef).some(field => field.key)
      )
    );
    if (keys.length !== 1) {
      throwErrorAndLog('Atleast and only one field can be a key field');
    }
    if (typeof keys[0].type !== 'string') {
      throwErrorAndLog('Key field can be only of type "string"');
    }
  }

  protected flattenCollectionSchema(
    collectionId: string,
    collectionSchemaDef: CollectionSchemaDef,
    collectionSchemaDefs: GenericCollectionSchemaDefs,
    baseCollection = false
  ): BaseCollectionSchemaDef {
    if (collectionSchemaDef.extends) {
      // Get base collection Id and schema Def
      const { extends: extendsSchemas, ...colSchemaDef } = collectionSchemaDef;
      // Base Collections cannot have more than one base collection
      if (baseCollection && extendsSchemas.length > 1) {
        throwErrorAndLog(`A Base Schema (here, "${collectionId}") cannot 
          have more than one base schemas`);
      }
      return toArray(extendsSchemas)
        .map(baseCollectionId => {
          if (!(baseCollectionId in collectionSchemaDefs)) {
            throwErrorAndLog(`Attempted to extend collection ${baseCollectionId} 
                that either doesn't exist or is invalid`);
          }
          const baseCollectionSchemas = toArray(
            collectionSchemaDefs[baseCollectionId]
          );
          if (baseCollectionSchemas.length > 1) {
            throwErrorAndLog(`A Base Schema (here: "${collectionId}") cannot 
              have more than one schema definition`);
          }
          return this.flattenCollectionSchema(
            baseCollectionId,
            baseCollectionSchemas[0],
            collectionSchemaDefs,
            true
          );
        })
        .reduce<BaseCollectionSchemaDef>(
          (resultantSchemaDef, baseColSchemaDef) => {
            return { ...resultantSchemaDef, ...baseColSchemaDef };
          },
          colSchemaDef
        );
    }
    return collectionSchemaDef;
  }
}
