import SchemaDef from './../schema/models/schema';
import { firestore } from 'firebase';
import { FilterQuery /* , SubCollectionQueryOption  */ } from './queryOptions';
import { mergeDeep } from '../helpers/lodashHelpers';
import { validateSubCollection } from '../schema/helpers';

const alphaNumericUnderScore = '[a-zA-Z0-9_]+';
const pathRegex = RegExp(`${alphaNumericUnderScore}/${alphaNumericUnderScore}`);

export type SubCollectionFilterQueries = {
  [subCollection: string]: FilterQuery[];
};

export type ParsedFilterQueries = {
  collectionFilterQueries: FilterQuery[];
  subCollectionFilterQueries: SubCollectionFilterQueries;
};

export default class QueryParser {
  public static parseFilterQueries(
    collectionId: string,
    filterQueries: FilterQuery[] = [],
    schema: SchemaDef
  ) {
    return filterQueries.reduce<ParsedFilterQueries>(
      (queries, query) => {
        if (QueryParser.matchNestedQuery(query.field)) {
          const { field, ...rest } = query;
          const [subCollectionId, subCollectionField] = field
            .toString()
            .split('/');
          validateSubCollection(subCollectionId, schema, collectionId);
          return {
            ...queries,
            subCollectionFilterQueries: mergeDeep(
              queries.subCollectionFilterQueries,
              { [subCollectionId]: [{ field: subCollectionField, ...rest }] }
            )
          };
        }
        return {
          ...queries,
          collectionFilterQueries: [...queries.collectionFilterQueries, query]
        };
      },
      { collectionFilterQueries: [], subCollectionFilterQueries: {} }
    );
  }

  public static checkIfSubCollectionQueryExists(
    filterQueries: FilterQuery[] = []
  ) {
    return filterQueries.some(query => this.matchNestedQuery(query.field));
  }

  // public static getSubCollectionQueryOption(
  //   isCollectionSubCollection: boolean,
  //   subCollectionQueryOption: SubCollectionQueryOption
  // ) {
  //   return isCollectionSubCollection
  //     ? SubCollectionQueryOption.onlySubCollection
  //     : subCollectionQueryOption;
  // }

  //#region path matchers

  private static matchNestedQuery(
    collectionPath: firestore.FieldPath | string
  ) {
    return pathRegex.test(collectionPath.toString());
  }

  // private static matchSubCollectionQueryRegex(
  //   subCollectionId: string,
  //   field: firestore.FieldPath | string
  // ) {
  //   return RegExp(`${subCollectionId}/${alphaNumericUnderScore}`).test(
  //     field.toString()
  //   );
  // }

  //#endregion
}
