import React, { useState, useEffect } from 'react'
import { Card, CardHeader, CardBody, Spinner, Container } from 'reactstrap';
import { LoginUtils } from 'utils/login';

const Success = () => (
    <Card>
        <CardHeader className='bg-success text-white border-0'>
            Verification Success
                </CardHeader>
        <CardBody className='d-flex justify-content-center align-items-center p-5'>
            Your email address has been verified successfully. Please login to continue to book tickets
                </CardBody>
    </Card>
)
const Failed = () => (
    <Card>
        <CardHeader className='bg-orange text-white border-0'>
            Verification Failed
        </CardHeader>
        <CardBody className='d-flex justify-content-center align-items-center p-5'>
            Verification code expired. Please try to register again
        </CardBody>
    </Card>
)
const Verifying = () => (
    <Card>
        <CardHeader className='bg-default text-white border-0'>
            Verifying Email
                </CardHeader>
        <CardBody className='d-flex justify-content-center align-items-center p-5'>
            <Spinner color='primary' type='grow' />
        </CardBody>
    </Card>
)
function EmailVerify({ actionCode }) {
    const [isVerifying, setIsVerifying] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    useEffect(() => {
        setIsVerifying(true);
        const verifyEmail = async () => {
            try {
                await LoginUtils.verifyEmail(actionCode);
                setSuccess(true);
            }
            catch (error) {
                setError(error);
            }
            finally {
                setIsVerifying(false);
            }
        }
        verifyEmail();
    }, [actionCode])
    return (
        <Container className='d-flex bg-transparent border-0 justify-content-center align-items-center p-4'>
            {isVerifying && <Verifying />}
            {success && <Success />}
            {error && <Failed />}
        </Container>
    )
}

export default EmailVerify;
