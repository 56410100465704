/**
 * Wrapper over console logger
 *
 * @export
 * @class Log
 */
export default class Log {
  /**
   *  Logs info on console
   *
   * @static
   * @memberof Log
   */
  static info = (message: string, object?: object | null) =>
    console.info(message, object);
  /**
   *
   * Logs error on console with red font
   * @static
   * @memberof Log
   */
  static error = (message: string, object?: object | null) =>
    console.error(message, object);
  /**
   *
   * Logs warning on console with yellow font
   * @static
   * @memberof Log
   */
  static warn = (message: string, object?: object | null) =>
    console.warn(message, object);
  /**
   *  Logs the object as info
   *
   * @static
   * @memberof Log
   */
  static object = (object?: Object | null) => console.info(object);
}
