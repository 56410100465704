import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthService from 'firestore/auth/utils/authService';
import Alert from 'components/Alert';

function Logout() {
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const history = useHistory();
  useEffect(() => {
    AuthService.Instance.signOut().then(() => {
      setIsLoggedOut(true);
      history.replace('/user/home');
    });
  }, [history]);
  return isLoggedOut ? (
    <>
      <Alert color="default"> Logout successful</Alert>
    </>
  ) : null;
}

export default Logout;
