import React, { useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import Step from './Step';
import './Stepper.scss';
import { motion, AnimatePresence } from 'framer-motion';
import { StepperContext } from './context';
function MyStepper({ children = [], steps = [] }) {
  const [[page, direction], setPage] = useState([0, 0]);

  const paginate = newDirection => {
    if (page === 0 && newDirection === -1) {
      return;
    }
    if (page + 1 === steps.length && newDirection === 1) {
      return;
    }
    setPage([page + newDirection, newDirection]);
  };
  return (
    <Card className="container">
      <CardHeader className="p-0 p-sm-2 py-sm-3 py-sm-0">
        <AnimatePresence initial={false} exitBeforeEnter>
          <motion.ul
            variants={stepVariants}
            initial={false}
            animate={steps.lenght > 0 ? 'visible' : 'hidden'}
            className="d-flex ml-0 mb-3 p-0 mt-3 p-lg-2 stepper"
          >
            {steps.map((step, index) => (
              <Step
                width={`${100 / steps.length}%`}
                key={index}
                text={step.title}
                activeStepNumber={page + 1}
                number={index + 1}
                lastStep={index + 1 === steps.length}
              />
            ))}
          </motion.ul>
        </AnimatePresence>
      </CardHeader>
      <StepperContext.Provider value={{ steps, page, paginate }}>
        <CardBody className="content-body p-0">
          <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
            <motion.div
              key={page}
              variants={contentVariants}
              custom={direction}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                opacity: { duration: 0.2 }
              }}
              className="col d-flex justify-content-center align-items-center p-0 p-sm-2"
            >
              {children ? children[page] : null}
            </motion.div>
          </AnimatePresence>
        </CardBody>
        {/* <StepperFooter /> */}
      </StepperContext.Provider>
    </Card>
  );
}

export const StepperFooter = ({ children }) => {
  return <CardFooter className="justify-content-end">{children}</CardFooter>;
};
const contentVariants = {
  enter: direction => {
    return {
      x: direction > 0 ? 50 : -50,
      opacity: 0,
      transition: {
        when: 'beforeChildren',
        staggeredChildren: 0.3
      }
    };
  },
  center: {
    x: 0,
    opacity: 1
  },
  exit: direction => {
    return {
      x: direction < 0 ? 50 : -50,
      opacity: 0,
      transition: {
        when: 'beforeChildren',
        staggeredChildren: 0.3
      }
    };
  }
};

const stepVariants = {
  hidden: {
    opactiy: 0
  },
  visibile: {
    opactiy: 1,
    transition: {
      duration: 0.5
    }
  }
};
export default MyStepper;
