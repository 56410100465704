import React from 'react';
import { Card, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import { poojaInfo } from '../SevaBooking/Booking/poojaData';

function SevaInformation() {
  return (
    <Container>
      <Card className="bg-transparent border shadow mt-3">
        <CardTitle className="bg-default text-white p-3">
          Seva Information
        </CardTitle>
        <p className="px-2">
          Tickets for the sevas that could be booked through this portal are
          listed below
        </p>
        <CardBody className="p-4">
          {poojaInfo.map((item, index) => {
            return (
              <Row className="p-3 border" key={item.key}>
                <Col className="text-center pr-2">{index + 1}</Col>
                <Col>{item.label || item.name}</Col>
                <Col>
                  <span>&#x20B9;</span> {item.amount}
                </Col>
              </Row>
            );
          })}
        </CardBody>
      </Card>
    </Container>
  );
}

export default SevaInformation;
