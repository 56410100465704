// import CustomDatePicker from 'views/examples/DatePicker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import CustomDatePicker from 'react-modern-calendar-datepicker';
import React from 'react';
import { Controller } from 'react-hook-form';
import './Datepicker.scss';
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { eachDayOfInterval, startOfDay } from 'date-fns';
import { isEmpty } from 'lodash';
const getDisabledDays = (maxDate, minDate, availableDates) => {
  if (!availableDates || availableDates.length === 0) {
    return [];
  }
  const currentAvailableDates = availableDates.map(date => startOfDay(date));
  const currentMinDate = startOfDay(
    new Date(`${minDate.month}-${minDate.day}-${minDate.year}`)
  );
  const currentMaxDate = startOfDay(
    new Date(`${maxDate.month}-${maxDate.day}-${maxDate.year}`)
  );
  const arrayOfDays = eachDayOfInterval({
    start: currentMinDate,
    end: currentMaxDate
  });
  const disabledDays = arrayOfDays
    .filter(
      date =>
        !currentAvailableDates.some(
          availableDate => availableDate.getTime() === date.getTime()
        )
    )
    .map(date => ({
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    }));

  return disabledDays;
};
function DatePicker({
  label,
  error,
  className = '',
  addonType = 'prepend',
  icon,
  validateHelperText = 'Minimum of 1 day and maximum of 10 days can be selected ',
  control,
  name,
  defaultValue,
  minimumDate,
  id,
  calendarPopperPosition = 'top',
  availableDates,
  maximumDate,
  getValues = () => {}
}) {
  return (
    <FormGroup>
      <Label className="form-control-label" for={id}>
        {label}
      </Label>
      <InputGroup
        className={`input-group flex-nowrap mb-3 border ${
          error ? 'border-danger' : ''
        }`}
      >
        <div style={{ position: 'relative', zIndex: '100', width: '100%' }}>
          <Controller
            render={props => {
              return (
                <CustomDatePicker
                  formatInputText={() => {
                    if (!props.value || props.value.length === 0) return '';
                    if (props.value && !Array.isArray(props.value)) {
                      if (isEmpty(props.value)) {
                        return `No avaialble dates to select`
                      }
                      const selectedDay = props.value;
                      return `${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`;
                    }
                    if (Array.isArray(props.value)) {
                      return props.value
                        ?.map(
                          selectedDay => !isEmpty(selectedDay) ?
                            ` ${selectedDay?.day}/${selectedDay?.month}/${selectedDay?.year}` : `No avaialble dates to select`
                        )
                        .join(',');
                    }
                  }}
                  value={isEmpty(props.value) ? null : props.value}
                  calendarPopperPosition={calendarPopperPosition}
                  onChange={e => props.onChange(e)}
                  inputPlaceholder="Select a day"
                  inputClassName={
                    'form-control-label bg-white border-0 w-100 ' + className
                  }
                  wrapperClassName="flex-grow-1 w-100"
                  minimumDate={minimumDate}
                  maximumDate={maximumDate}
                  disabledDays={getDisabledDays(
                    maximumDate,
                    minimumDate,
                    availableDates
                  )}
                />
              );
            }}
            defaultValue={defaultValue}
            control={control}
            name={name}
            id={id}
            rules={{
              validate: () => {
                const selectedDates = getValues(name);
                if (Array.isArray(selectedDates)) {
                  return selectedDates.length > 0 && selectedDates.length <= 10;
                }
                return true;
              }
            }}
          />
        </div>
        {icon && (
          <InputGroupAddon className="ml-0" addonType={addonType}>
            <InputGroupText className="border-0">
              <FontAwesomeIcon icon={icon} />
            </InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>
      {error && (
        <p className="text-danger font-weight-500">
          {error?.type === 'validate' ? validateHelperText : error.message}
        </p>
      )}
    </FormGroup>
  );
}

export default React.forwardRef((props, ref) => (
  <DatePicker {...props} innerRef={ref} />
));
