import axios from 'axios';
import { auth } from 'firebase';
import { useQuery } from 'react-query';
import { useLocation, useHistory } from 'react-router-dom';

function useGetQueryParams() {
  return new URLSearchParams(useLocation().search);
}
const apiBaseURL =
  process.env.VERCEL_ENV === 'development' ? 'http://localhost:3000/api' : '/api';

function useVerifyOrder() {
  const query = useGetQueryParams();
  const order_id = query.get('order_id');
  const order_token = query.get('order_token');
  const history = useHistory();
  if (order_id && order_token) {
    history.replace(history.location.pathname, {
      order_id,
      order_token
    });
  }
  const currentOrderId = history.location?.state?.order_id;
  const returnedTokenFromGateway = history.location?.state?.order_token;
  let orderOptions = useQuery(
    'payment',
    async () => {
      const authToken = await auth().currentUser.getIdToken();
      const result = await axios.post(
        `${apiBaseURL}/orders/fetch`,
        {
          order_id: currentOrderId,
          order_token: returnedTokenFromGateway
        },
        {
          headers: {
            Authorization: authToken
          }
        }
      );
      return result?.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: currentOrderId !== '' && returnedTokenFromGateway !== ''
    }
  );
  const currentOrderToken = orderOptions?.data?.data?.order_token;
  const isPaid =
    orderOptions.isSuccess &&
    currentOrderToken === returnedTokenFromGateway &&
    orderOptions.data.data?.order_status === 'PAID';
  const isActive =
    orderOptions.isSuccess &&
    currentOrderToken === returnedTokenFromGateway &&
    orderOptions.data.data?.order_status === 'ACTIVE';
  const isExpired =
    orderOptions.isSuccess &&
    currentOrderToken === returnedTokenFromGateway &&
    orderOptions.data.data?.order_status === 'EXPIRED';

  orderOptions = {
    ...orderOptions,
    isPaid,
    isActive,
    isExpired
  };
  return orderOptions;
}

export default useVerifyOrder;
