import React, { useContext } from 'react';
import { Accordion } from 'components/Accordion';
import { FormInput } from 'components/FormControls';
import { Col, Row, Button } from 'reactstrap';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { personSchema } from './schema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Person.scss';
import { BookSevaContext } from './context';
import { isEmpty } from 'lodash';
import CreatableSingle from 'components/FormControls/CreatableSelect';
export const validateText = value => {
  const english = /^[A-Za-z\s]*$/;
  const result = english.test(value);
  return result;
};
const Person = ({ item, append, remove, index, isLast }) => {
  const { register, errors, watch, control, setValue } =
    useContext(BookSevaContext);
  const personName = watch(`people[${index}].name`);
  const title = personName || `Person ${index + 1}`;
  const deleteDisabled = index === 0;
  const currentErrors =
    !isEmpty(errors) && errors.people && errors.people[index];

  return (
    <Accordion title={title} expandOnMount={true}>
      <Row className="px-4 mt--4 d-flex justify-content-between flex-grow-1 no-gutters">
        {personSchema.map((person, itemIndex) => {
          return (
            <Col
              className="px-sm-2"
              key={person.id + itemIndex}
              size={12}
              sm={person?.id === 'name' ? 4 : 3}
            >
              {person?.id === 'name' ? (
                <CreatableSingle
                  autoFocus
                  control={control}
                  name={`people[${index}].${person.id}`}
                  label={person.label}
                  id={person.id}
                  index={index}
                  defaultValue={item[person.id]}
                  error={currentErrors ? currentErrors[person.id] : ''}
                  setValue={setValue}
                  ref={register}
                  validateHelperText={'Entered text is not English'}
                />
              ) : (
                <FormInput
                  ref={register({
                    required: person?.required,
                    validate: validateText
                  })}
                  name={`people[${index}].${person.id}`}
                  label={person.label}
                  id={person.id}
                  defaultValue={item[person.id]}
                  error={currentErrors ? currentErrors[person.id] : ''}
                  type={person?.type}
                  options={person?.options}
                  required={person?.required}
                  validateHelperText={'Entered text is not English'}
                />
              )}
            </Col>
          );
        })}
        <Col
          className="d-flex justify-content-between align-items-center nav-pills-circle mx-1 my-2"
          lg={1}
        >
          <Button
            disabled={deleteDisabled}
            onClick={!deleteDisabled ? () => remove(index) : undefined}
            style={{ lineHeight: 2 }}
            id="delete-button"
            className="d-none d-md-block btn-outline-danger rounded-circle mb-3 mb-sm-0"
          >
            <FontAwesomeIcon className="mr-2 mr-sm-0" icon={faTrashAlt} />
          </Button>
          <Button
            disabled={deleteDisabled}
            onClick={!deleteDisabled ? () => remove(index) : undefined}
            style={{ lineHeight: 2 }}
            id="delete-button"
            className="d-block d-md-none btn-outline-danger mb-3 mb-sm-0 btn-sm"
          >
            <FontAwesomeIcon className="mr-2" icon={faTrashAlt} />
            <span>Delete</span>
          </Button>
          <small>
            <Button
              onClick={() => append()}
              id="delete-button"
              className="d-block d-lg-none btn-default btn-sm mb-3 mb-sm-0"
            >
              <FontAwesomeIcon className="mr-2 mr-sm-0" icon={faPlus} /> Add
              Person
            </Button>
          </small>
        </Col>
      </Row>
    </Accordion>
  );
};

export default Person;
