/* eslint-disable no-use-before-define */
import React, { useContext, useEffect } from 'react';
import { Accordion } from 'components/Accordion';
import { FormInput, DatePicker } from 'components/FormControls';
import { Col, Row, Button } from 'reactstrap';
import {
  faTrashAlt,
  faCalendarAlt,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { poojaDetails, INPUT_TYPES } from './schema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Person.scss';
import { BookSevaContext } from './context';
import { poojaInfo } from './poojaData';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { DATEPICKER_MODES } from './constants';
import {
  getDefaultDate,
  getOptions,
  tomorrow,
  getNumberOfDays,
  maxDate
} from './helpers';
import { startOfTomorrow } from 'date-fns';

const Pooja = ({ item, remove, append, index, keyId, isLast }) => {
  const { register, control, setValue, watch, errors, getValues } =
    useContext(BookSevaContext);
  const fieldKeys = {
    selectedPooja: `${keyId}[${index}].seva`,
    mode: `${keyId}[${index}].mode`,
    selectedDates: `${keyId}[${index}].selectedDates`,
    from: `${keyId}[${index}].from`,
    to: `${keyId}[${index}].to`,
    availableDates: `${keyId}[${index}].availableDates`,
    defaultDate: `${keyId}[${index}].defaultDate`,
    amountPerDay: `${keyId}[${index}].amountPerDay`,
    amount: `${keyId}[${index}].amount`,
    numberOfDays: `${keyId}[${index}].numberOfDays`
  };
  const selectedPooja = watch(fieldKeys.selectedPooja);
  const mode = watch(fieldKeys.mode);
  const selectedDates = watch(fieldKeys.selectedDates);
  const from = watch(fieldKeys.from);
  const to = watch(fieldKeys.to);
  const availableDates = watch(fieldKeys.availableDates) || [];
  const defaultDate = watch(fieldKeys.defaultDate);

  useEffect(() => {
    register({ name: fieldKeys.amountPerDay });
    register({ name: fieldKeys.availableDates });
    register({ name: fieldKeys.defaultDate });
  }, [register, fieldKeys]);

  useEffect(() => {
    setValue(fieldKeys.amountPerDay, item['amountPerDay']);
    setValue(
      fieldKeys.amount,
      !isNaN(Number(item['amountPerDay']) * item['numberOfDays'])
        ? Number(item['amountPerDay']) * item['numberOfDays']
        : 0
    );
    setValue(fieldKeys.numberOfDays, item['numberOfDays']);
    setValue(fieldKeys.availableDates, item['availableDates']);
    setValue(fieldKeys.selectedDates, item['selectedDates']);
    setValue(fieldKeys.from, item['from']);
    setValue(fieldKeys.to, item['to']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);
  const setDefaultValuesForFields = () => {
    const selectedPooja = getValues(fieldKeys.selectedPooja);
    const selectedDates = getValues(fieldKeys.selectedDates);
    const amountPerDay =
      poojaInfo.find(pooja => pooja.name === selectedPooja)?.amount || 0;
    const availableDates =
      poojaInfo.find(pooja => pooja.name === selectedPooja)?.availableDates ||
      [];
    const numberOfDays = getNumberOfDays(mode, selectedDates, from, to) || 1;
    setValue(fieldKeys.amountPerDay, amountPerDay);
    setValue(fieldKeys.amount, amountPerDay * numberOfDays);
    setValue(fieldKeys.numberOfDays, numberOfDays);
    setValue(fieldKeys.availableDates, availableDates);
  };
  useEffect(() => {
    setDefaultValuesForFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates, mode]);
  useEffect(() => {
    const fromDate = moment(
      `${from?.year}-${from?.month}-${from?.day}`,
      'YYYY-MM-DD'
    );
    const toDate = moment(`${to?.year}-${to?.month}-${to?.day}`, 'YYYY-MM-DD');
    if (fromDate.isSameOrAfter(toDate)) {
      setValue(fieldKeys.to, from);
    }
    setDefaultValuesForFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates, from, to]);

  const title = selectedPooja || `Seva ${index + 1}`;
  const deleteDisabled = index === 0;
  const currentErrors = !isEmpty(errors) && errors.poojas[index];
  const changeHandler = e => {
    if (e.target.name.includes('mode') && e.target.value !== undefined) {
      const amountPerDay =
        poojaInfo.find(pooja => pooja.name === selectedPooja)?.amount || 0;
      setValue(
        fieldKeys.selectedDates,
        e.target.value === 'single' ? defaultDate : [defaultDate]
      );
      setValue(fieldKeys.numberOfDays, 1);
      setValue(fieldKeys.amount, amountPerDay);
    }
    if (e.target.name === fieldKeys.selectedPooja) {
      const selectedPooja = e.target.value;
      const amountPerDay =
        poojaInfo.find(pooja => pooja.name === selectedPooja)?.amount || 0;
      const availableDates =
        poojaInfo.find(pooja => pooja.name === selectedPooja)?.availableDates ||
        [];
      const defaultDate = getDefaultDate(availableDates, startOfTomorrow());

      setValue(fieldKeys.amountPerDay, 0);
      setValue(fieldKeys.amount, amountPerDay);
      setValue(fieldKeys.numberOfDays, 1);
      setValue(fieldKeys.defaultDate, defaultDate);
      setValue(fieldKeys.mode, 'undefined');
    }
  };
  return (
    <Accordion title={title} expandOnMount={true}>
      <Row className="px-4 mt--4 d-flex justify-content-between flex-grow-1 no-gutters">
        <Col>
          <Row className="flex-grow-1">
            {poojaDetails.map((field, itemIndex) => {
              return (
                <Col key={field.id + itemIndex} sm={4} md={4}>
                  {[
                    INPUT_TYPES.TEXT,
                    INPUT_TYPES.SELECT,
                    INPUT_TYPES.TEXTAREA
                  ].includes(field.type) && (
                      <FormInput
                        key={field.id}
                        autoFocus={field.id === 'seva' && isLast}
                        ref={register({
                          required: field?.required
                        })}
                        name={`${keyId}[${index}].${field.id}`}
                        defaultValue={item[field.id]}
                        type={field.type}
                        options={getOptions(field, availableDates)}
                        id={field.id + index}
                        label={field.label}
                        className="form-control-sm"
                        required={field?.required}
                        onChange={changeHandler}
                        readOnly={
                          field?.readOnly ||
                          (field.id === 'mode' && !selectedPooja)
                        }
                        error={currentErrors ? currentErrors[field.id] : ''}
                      />
                    )}
                </Col>
              );
            })}
            {mode === DATEPICKER_MODES.SINGLE && (
              <Col>
                <DatePicker
                  key={`${keyId}[${index}]`}
                  className="form-control-sm"
                  name={`${keyId}[${index}].selectedDates`}
                  id={DATEPICKER_MODES.SINGLE + index}
                  label={'Choose Dates'}
                  control={control}
                  icon={faCalendarAlt}
                  addonType={'append'}
                  minimumDate={tomorrow}
                  maximumDate={maxDate}
                  availableDates={availableDates}
                  defaultValue={defaultDate}
                  error={currentErrors?.selectedDates}
                />
              </Col>
            )}
            {mode === DATEPICKER_MODES.MULTIPLE && (
              <Col>
                <DatePicker
                  key={`${keyId}[${index}]`}
                  className="form-control-sm"
                  name={`${keyId}[${index}].selectedDates`}
                  id={DATEPICKER_MODES.MULTIPLE + index}
                  label={'Choose Dates'}
                  control={control}
                  icon={faCalendarAlt}
                  addonType={'append'}
                  minimumDate={tomorrow}
                  maximumDate={maxDate}
                  availableDates={availableDates}
                  getValues={getValues}
                  defaultValue={[defaultDate]}
                  error={currentErrors?.selectedDates}
                />
              </Col>
            )}
            {mode === DATEPICKER_MODES.RANGE && (
              <Col className="d-flex flex-wrap">
                <Col sm={6}>
                  <DatePicker
                    key={`${keyId}[${index}].from`}
                    className="form-control-sm"
                    name={`${keyId}[${index}].from`}
                    id={'from' + index}
                    label={'From:'}
                    control={control}
                    icon={faCalendarAlt}
                    addonType={'append'}
                    minimumDate={tomorrow}
                    maximumDate={maxDate}
                    availableDates={availableDates}
                    defaultValue={item['from'] || tomorrow}
                    error={currentErrors?.from}
                  />
                </Col>
                <Col sm={6}>
                  <DatePicker
                    key={`${keyId}[${index}].to`}
                    name={`${keyId}[${index}].to`}
                    id={'to' + index}
                    label={'To:'}
                    control={control}
                    icon={faCalendarAlt}
                    addonType={'append'}
                    availableDates={availableDates}
                    minimumDate={from || tomorrow}
                    maximumDate={maxDate}
                    defaultValue={item['to'] || tomorrow}
                    error={currentErrors?.to}
                  />
                </Col>
              </Col>
            )}
            {mode && (
              <Col sm={4}>
                <FormInput
                  className="form-control-sm"
                  ref={register()}
                  name={`${keyId}[${index}].numberOfDays`}
                  defaultValue={'1'}
                  type={INPUT_TYPES.TEXT}
                  id={'numberOfDays' + index}
                  label={'Number Of Days'}
                  readOnly={true}
                  error={currentErrors?.numberOfDays}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col
          className="d-flex justify-content-between align-items-center nav-pills-circle mx-1 my-2"
          lg={1}
        >
          <Button
            disabled={deleteDisabled}
            onClick={!deleteDisabled ? () => remove(index) : undefined}
            style={{ lineHeight: 2 }}
            id="delete-button"
            className="d-none d-md-block btn-outline-danger rounded-circle mb-3 mb-sm-0"
          >
            <FontAwesomeIcon className="mr-2 mr-sm-0" icon={faTrashAlt} />
          </Button>
          <Button
            disabled={deleteDisabled}
            onClick={!deleteDisabled ? () => remove(index) : undefined}
            style={{ lineHeight: 2 }}
            id="delete-button"
            className="d-block d-md-none btn-outline-danger mb-3 mb-sm-0 btn-sm"
          >
            <FontAwesomeIcon className="mr-2" icon={faTrashAlt} />
            <span>Delete</span>
          </Button>
          <small>
            <Button
              onClick={() => append()}
              id="delete-button"
              className="d-block d-lg-none btn-default btn-sm mb-3 mb-sm-0"
            >
              <FontAwesomeIcon className="mr-2 mr-sm-0" icon={faPlus} /> Add
              Seva
            </Button>
          </small>
        </Col>
      </Row>
    </Accordion>
  );
};

export default Pooja;
