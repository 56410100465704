import React from 'react';
// import { Row } from 'reactstrap';
// import moment from 'moment';
// import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
  HistoryCard,
  Body
  // Field
  // FooterButton,
  // Footer
} from 'components/Cards';
// import { TRANSACTION_STATUS } from '../Booking/constants';
// import { Row } from 'reactstrap';
import { format } from 'date-fns';
import DataTable from './Table';
import { sortBy } from 'lodash';
import { Button } from 'reactstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import logo from '../../../../assets/img/brand/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileInvoice
} from '@fortawesome/free-solid-svg-icons';
// const SectionTitle = ({ children }) => {
//   return (
//     <Row className="flex-grow-1 m-2">
//       <h2 class="display-5 text-center text-orange">{children}</h2>
//     </Row>
//   );
// };
const getFormattedDate = date => date && format(date, 'do MMM yyyy');
function TransactionCard({ transaction }) {
  const peopleRows = sortBy(transaction.personDetails, 'seq').map(person => ({
    name: person.name,
    gothram: person.gothram,
    nakshatram: person.nakshatram
  }));
  const peopleColumns = [
    { label: 'Name', key: 'name' },
    { label: 'Gohtram', key: 'gothram' },
    { label: 'Nakshatram', key: 'nakshatram' }
  ];
  const poojaDetails = transaction.poojaDetails.map((pooja, index) => ({
    selectedPooja: pooja.selectedPooja,
    selectedDates: pooja.isRange && !Array.isArray(pooja?.selectedDates)
      ? `From ${getFormattedDate(
        pooja.selectedDates.startDate
      )} - To ${getFormattedDate(pooja.selectedDates.endDate)}`
      : `${pooja?.selectedDates.map(date => getFormattedDate(date)).join(',')}`
  }));
  const orderColumns = [
    { label: 'Order ID', key: 'transactionId' },
    { label: 'Booked Date on', key: 'createdOn' },
    { label: 'Booked Status', key: 'status' },
    { label: 'Amount', key: 'amount' }
  ];
  const orderRows = [
    {
      ...transaction,
      createdOn: getFormattedDate(transaction?.createdOn.toDate())
    }
  ];
  const downloadReceipt = () => {
    const doc = new jsPDF();
    doc.setTextColor(40);
    doc.setFontSize(12);
    doc.text("Order Information", 14, 35);
    doc.autoTable({
      didDrawPage: function (data) {

        // Header
        doc.setFontSize(16)
        doc.setTextColor(40)
        if (logo) {
          doc.addImage(logo, 'PNG', data.settings.margin.left, 10, 15, 15)
        }
        doc.setFontSize(14)
        doc.text('Sri Sringeri Jagadguru Mahamsamsthanam', data.settings.margin.left + 20, 15)
        doc.setFontSize(10)
        doc.setTextColor(60)
        doc.text('Sri Sringeri Shankarmath, New Nallakunta, Hyderabad - 44 (T.S)', data.settings.margin.left + 20, 20)
        doc.text('Tel: 040-27667783', data.settings.margin.left + 20, 25)

        // Footer
        var str = `Sri Sringeri Shankarmath,New Nallakunta, Hyderabad - 500044. © Copyrights reserved`;
        doc.setFontSize(10)
        doc.setTextColor(150)

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        doc.text(str, data.settings.margin.left, pageHeight - 10)
      },
      columns: orderColumns.map(order => ({ header: order.label, dataKey: order.key })),
      body: orderRows,
      startY: 37,
      theme: 'grid'
    });
    doc.setFontSize(12)
    doc.text("People Information", 14, doc.lastAutoTable.finalY + 10);

    doc.autoTable({
      columns: peopleColumns.map(people => ({ header: people.label, dataKey: people.key })),
      body: peopleRows,
      startY: doc.lastAutoTable.finalY + 12,
      theme: 'grid',
    });

    doc.text("Pooja Information", 14, doc.lastAutoTable.finalY + 10);
    doc.autoTable({
      columns: [
        {
          header: 'Selected Pooja',
          dataKey: 'selectedPooja'
        },
        {
          header: 'Booking Dates',
          dataKey: 'selectedDates'
        }
      ],
      body: poojaDetails,
      startY: doc.lastAutoTable.finalY + 12,
      theme: 'grid'
    });

    doc.save(`Seva Receipt ${moment().format('DD-MM-YYYY')}`);
    return false;
  }
  return (
    <HistoryCard className="my-2 border" showHoverEffect={true}>
      <Body>
        <Button
          size='md'
          style={{ background: '#a5281f' }}
          onClick={downloadReceipt}
          className="my-3 my-md-2 px-3 text-white" >
          <FontAwesomeIcon className='fa-light color-white mr-2' icon={faFileInvoice} />
          Download Receipt
        </Button>
        <DataTable
          title={'Order Information'}
          rows={orderRows}
          columns={orderColumns}
        />
        <DataTable
          title={'People Information'}
          rows={peopleRows}
          columns={peopleColumns}
        />
        <DataTable
          title={'Pooja Information'}
          columns={[
            {
              label: 'Selected Pooja',
              key: 'selectedPooja'
            },
            {
              label: 'Booking Dates',
              key: 'selectedDates'
            }
          ]}
          rows={poojaDetails}
        />

        {/* <Row className="m-lg-2">
          <Field
            class
            title={'Ticket was Booked On'}
            value={getFormattedDate(transaction?.createdOn.toDate())}
          />
          <Field
            title={'Booked Status'}
            className={
              transaction &&
              ((TRANSACTION_STATUS.FAILED === transaction.status &&
                'text-danger') ||
                (TRANSACTION_STATUS.PENDING === transaction.status &&
                  'text-warning') ||
                (TRANSACTION_STATUS.SUCCESS === transaction.status &&
                  'text-success') ||
                'text-dark')
            }
            value={transaction && transaction.status}
          />
          <Field
            title={'Total Amount'}
            value={transaction && transaction.amount}
          /> */}
        {/* <Field
            title={'People'}
            value={
              transaction &&
              transaction.personDetails.map(person => person.name).join(', ')
            }
          />
          <Field
            title={'Number of People'}
            value={transaction && transaction.personDetails.length}
          />

          <Field
            title={'Sevas'}
            value={
              transaction &&
              transaction.poojaDetails
                .map(pooja => pooja.selectedPooja)
                .join(',')
            }
          />
          <Field
            title={'Number of Sevas'}
            value={transaction && transaction.poojaDetails.length}
          /> */}
        {/* </Row> */}
      </Body>
    </HistoryCard>
  );
}

export default TransactionCard;
