import React, { useState } from 'react';
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
  faLockOpen
} from '@fortawesome/free-solid-svg-icons';
function PasswordInput({
  name = '',
  id,
  value,
  onChange,
  error,
  placeholder,
  label = '',
  className = '',
  inputGroupTextClassName = '',
  inputClassName = '',
  show = false,
  innerRef = null
}) {
  const [showPassword, setShowPassword] = useState(show);
  return (
    <FormGroup>
      <Label className="form-control-label" for={name}>
        {label}
      </Label>
      <InputGroup className={'input-group-alternative'}>
        <InputGroupAddon addonType="prepend">
          <InputGroupText className={inputGroupTextClassName}>
            <FontAwesomeIcon icon={faLockOpen} />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          innerRef={innerRef}
          id={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={inputClassName}
          type={showPassword ? 'text' : 'password'}
          autoComplete="new-password"
          name={name}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText className={inputGroupTextClassName}>
            <FontAwesomeIcon
              onClick={() => setShowPassword(state => !state)}
              icon={!showPassword ? faEye : faEyeSlash}
            />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </FormGroup>
  );
}
export default React.forwardRef((props, ref) => (
  <PasswordInput {...props} innerRef={ref} />
));
