import { auth, User } from "firebase";
import { AuthProviders } from "../utils/authProviders";
import { getThirdPartyAuthProvider } from "../utils/helpers";
import AuthService from "../utils/authService";
import Log from "../../../utils/log";
export default abstract class BaseAuth {
  constructor() {
    this.addAuthObserver(AuthService.Instance);
  }

  protected abstract _userCredential?: auth.UserCredential;
  protected async onUserChanged(user: User | null) {
    Log.object(user);
  }

  private addAuthObserver(authService: auth.Auth) {
    authService.onAuthStateChanged(async (user: User | null) => {
      await this.onUserChanged(user);
    });
  }

  /**
   * Gets the user credentials after successful authentication
   *
   * @readonly
   * @abstract
   * @type {(auth.UserCredential | undefined)}
   * @memberof BaseAuth
   */
  public abstract get UserCredential(): auth.UserCredential | undefined;
  public async updateUserInfo(profile: {
    displayName?: string | null;
    photoURL?: string | null;
  }) {
    await this._userCredential?.user?.updateProfile(profile);
  }

  /**
   * Links with email-password credential
   *
   * @param {string} email
   * @param {string} password
   * @returns {(Promise<auth.UserCredential | undefined>)}
   * @memberof BaseAuth
   */
  public async linkWithEmail(
    email: string,
    password: string
  ): Promise<auth.UserCredential | undefined> {
    // No provision of mutliple emails to a single account at this time
    if (this._userCredential?.user?.providerId !== AuthProviders.Credential) {
      if (email && password) {
        const emailCredential = auth.EmailAuthProvider.credential(
          email,
          password
        );
        if (this._userCredential?.user && emailCredential) {
          const linkedCredential = await this.linkWithCredential(
            emailCredential
          );
          return linkedCredential;
        }
      }
    }
    Log.warn("User credentials are empty");
  }
  /**
   * Links with any authenticated credential
   *
   * @param {auth.AuthCredential} credential
   * @returns {(Promise<auth.UserCredential | undefined>)}
   * @memberof BaseAuth
   */
  public async linkWithCredential(
    credential: auth.AuthCredential
  ): Promise<auth.UserCredential | undefined> {
    return await this._userCredential?.user?.linkWithCredential(credential);
  }
  /**
   * Links with third party provider
   *
   * @param {AuthProviders} provider
   * @returns {Promise<void>}
   * @memberof BaseAuth
   */
  public async linkWithProvider(provider: AuthProviders): Promise<void> {
    if (
      this._userCredential?.user
      // Multiple emails with same provider has to be supported
      /* &&
      this.UserCredential?.user?.providerId !== provider */
    ) {
      const authProvider = getThirdPartyAuthProvider(provider);
      if (authProvider) {
        await this._userCredential.user.linkWithRedirect(authProvider);
      }
    }
  }
}
