import { ADD_PEOPLE, ADD_POOJA } from 'actions/seva';

export default (state, action) => {
  switch (action.type) {
    case ADD_PEOPLE:
      return {
        ...state,
        people: action.payload ? [...action.payload] : state.people
      };
    case ADD_POOJA:
      return { ...state, poojas: [...action.payload] };
    default:
      return state;
  }
};
