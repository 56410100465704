import React from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import './Select.scss';

function Text(
  {
    label,
    error,
    validateHelperText,
    className = '',
    control,
    options,
    id,
    name,
    ...props
  },
  innerRef
) {
  return (
    <div className="px-md-7 d-flex flex-column">
      <label className="form-control-label" htmlFor={id}>
        {label}
      </label>
      <Controller
        as={Select}
        options={options}
        isClearable
        control={control}
        name={name}
        className={`flex-grow-1 ${className}`}
        // id={id}
        // {...props}
      />
      {error && (
        <p className="text-danger font-weight-500">
          {error?.type === 'validate' ? validateHelperText : error.message}
        </p>
      )}
    </div>
  );
}

export default React.forwardRef((props, ref) => (
  <Text {...props} innerRef={ref} />
));
