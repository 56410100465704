import React from 'react';
import { Link } from 'react-router-dom';
import Announcement from 'views/examples/Announcements/Announcement';
import { announcements, history } from './data';
import './Information.scss';
function Information() {
  return (
    <div className="px-2 px-md-0 mt-2">
      <section className="border-background mb-2" id="temple-history">
        <div
          style={{ background: '#A5281F' }}
          className="card p-2 mt-0 rounded-0 pl-4"
        >
          <div className="d-flex justify-content-start display-5 text-white font-weight-500">
            Temple History
          </div>
        </div>
        <p
          style={{ color: '#A5281F' }}
          className="font-weight-500 p-2 mb-0 pl-4"
        >
          {history}
          <Link
            style={{ color: '#A5281F' }}
            className="d-flex justify-content-end font-weight-bold mx-4 display-5"
            to="/user/about"
          >
            More...
          </Link>
        </p>
      </section>
      <section className="mb-2 border-background" id="announcements-section">
        <div
          style={{ background: '#A5281F' }}
          className="card p-2 mb-2 rounded-0"
        >
          <div className="d-flex justify-content-start display-5 text-white font-weight-500 shadow pl-4">
            Latest Event Announcements
          </div>
        </div>
        <div className="px-4">
          {announcements.map((announcement, index) => {
            if (index < 3) {
              return (
                <Announcement key={announcement?.title} item={announcement} />
              );
            }
            return null;
          })}
          <Link
            style={{ color: '#A5281F' }}
            className="d-flex justify-content-end font-weight-bold my-3 mx-4 display-5"
            to="/user/announcements"
          >
            More...
          </Link>
        </div>
      </section>
    </div>
  );
}

export default Information;
