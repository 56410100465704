import Query from 'firestore/db/read/queryBase';
import {
  FilterOperator,
  SubCollectionQueryOption
} from 'firestore/db/read/queryOptions';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import useAuth from 'hooks/useAuth';
import { isArray, orderBy } from 'lodash';

function useTransactions() {
  const { auth } = useAuth();
  const getDonations = useCallback(async () => {
    const result = await Query.from('transactions').Execute({
      filter: [
        {
          field: 'createdBy',
          operator: FilterOperator.equalTo,
          value: auth?.uid
        }
      ],
      transformer: {
        accessor: [
          transaction => transaction.poojaDetails,
          poojaDetail => poojaDetail.selectedDates
        ],
        transformer: selectedDates => {
          if (isArray(selectedDates)) {
            return selectedDates.map(date => date.toDate());
          }
          return {
            startDate: selectedDates.startDate.toDate(),
            endDate: selectedDates.endDate.toDate()
          };
        }
      },
      subCollectionQueryOption: SubCollectionQueryOption.withSubCollection
    });
    const sortedTransactions = orderBy(result, ['createdOn'], ['desc']);
    return Promise.resolve(sortedTransactions);
  }, [auth.uid]);
  const query = useQuery('transactions', getDonations, {
    enabled: auth?.uid !== null,
    staleTime: Infinity
  });

  return query;
}

export default useTransactions;
