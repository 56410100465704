import { firebaseFunctions } from 'firestore';
import Log from './log';

export type CustomerDetails = {
  customerEmail?: string,
  customerName: string,
  customerPhone?: number,
  orderAmount: number,
}

export type OrderDetails = CustomerDetails & {
  orderId: string,
  appId: string,
  notifyUrl: string,
  returnUrl: string,
  [key: string]: any,
}

type data = {
  data: object
}

export class Payments {
  private static cashFree = (window as any).CashFree.init({ mode: 'PROD' });
  private static createOrder = firebaseFunctions.httpsCallable('createOrder');
  public static generateOrder = async (formObject: CustomerDetails) => {
    if (!Payments.cashFree) {
      Log.error("cashfree sdk not initialised, cannot make payments");
      return;
    }
    try {
      const order: data = await Payments.createOrder(formObject);
      return order.data;
    }
    catch (err) {
      Log.error(err as string);
      throw new Error('Order creation failed. Please try again with valid parameters');
    }
  }
  public static makePayment = (order: OrderDetails, callback: Function) => {
    (window as any).CashFree.makePayment(order, callback);
  }
}