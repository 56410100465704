import React, { useContext } from 'react';
import Person from './Person';
import StepDetails from './StepDetails';
import { useForm } from 'react-hook-form';
import { BookSevaContext } from './context';
import { useBookSevaState } from 'hooks/useBookSevaState';
import { addPeople } from 'actions/seva';
import { Button, Col, Form } from 'reactstrap';
import { StepperFooter } from 'components/Stepper/Stepper';
import { StepperContext } from 'components/Stepper/context';

function PersonDetails() {
  const [{ people }, dispatch] = useBookSevaState();
  const { steps, page, paginate } = useContext(StepperContext);
  const methods = useForm({
    defaultValues: {
      people: people ? [...people] : []
    },
    mode: 'onTouched'
  });
  const submit = () => {
    const { people: currentPeople } = methods.getValues();
    if (!methods.formState.errors.poojas) {
      dispatch(addPeople(currentPeople));
      paginate(1);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => () => submit(), []);
  return (
    <BookSevaContext.Provider value={methods}>
      <Form className="w-100" onSubmit={methods.handleSubmit(submit)}>
        <StepDetails
          defaultItems={people}
          ItemComponent={Person}
          keyId="people"
          title="Details Of People"
          titleButtonText="Add Person"
        />
        <StepperFooter>
          <Col className="my-2 d-flex justify-content-end">
            <Button
              disabled={page === 0}
              onClick={() => paginate(-1)}
              className="btn-warning"
            >
              Back
            </Button>
            <Button
              type="submit"
              disabled={page === steps?.length}
              color="default"
            >
              Next
            </Button>
          </Col>
        </StepperFooter>
      </Form>
    </BookSevaContext.Provider>
  );
}

export default PersonDetails;
