/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Container, Row, Col, Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAsterisk,
  faBook,
  faInfo,
  faMapPin,
  faUserSecret
} from '@fortawesome/free-solid-svg-icons';

class Footer extends React.Component {
  render() {
    return (
      <footer
        style={{ backgroundColor: '#A5281F' }}
        className="footer p-2 mb-sm-0"
      >
        <Row className="align-items-center justify-content-xl-between no-gutters text-white flex-sm-row">
          <Col className="mb-2 mb-sm-0 d-flex" sm="6">
            <div className="copyright">
              <small className="">
                <NavLink
                  className="font-weight-500 ml-1 text-white"
                  to="/user/home"
                >
                  Shankar Math, Nallakunta, Hyderabad. <br />© 2021 Copyrights
                  Reserved
                </NavLink>
              </small>
            </div>
          </Col>

          <Col
            className="mb-2 mb-sm-0 d-flex flex-column flex-md-row justify-content-end"
            sm="6"
          >
            <Nav className="nav-footer justify-content-xl-end text-white mr-2">
              <NavItem>
                <NavLink className="text-white" to="/user/about">
                  <small>
                    {/* <FontAwesomeIcon className="mr-1" icon={faInfo} /> */}
                    About Us
                  </small>
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="nav-footer justify-content-xl-end text-white mr-2">
              <NavItem>
                <NavLink className="text-white" to="/user/terms-conditions">
                  <small>
                    {/* <FontAwesomeIcon className="mr-1" icon={faAsterisk} />  */}
                    Terms of Use
                  </small>
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="nav-footer justify-content-xl-end text-white mr-2">
              <NavItem>
                <NavLink className="text-white" to="/user/cancellation-policy">
                  <small>
                    {/* <FontAwesomeIcon className="mr-1" icon={faBook} />{' '} */}
                    Cancellation/Refund Policy
                  </small>
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="nav-footer justify-content-xl-end text-white mr-2">
              <NavItem>
                <NavLink className="text-white" to="/user/privacy-policy">
                  <small>
                    {/* <FontAwesomeIcon className="mr-1" icon={faUserSecret} />{' '} */}
                    Privacy Policy
                  </small>
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="nav-footer justify-content-xl-end text-white mr-2">
              <NavItem>
                <small>
                  <a
                    className="text-white"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://goo.gl/maps/xZmYSTMbDhwyy5fk6"
                  >
                    {/* <FontAwesomeIcon className="mr-1" icon={faMapPin} />{' '} */}
                    Location
                  </a>
                </small>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
