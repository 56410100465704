import { auth, User } from 'firebase';
import AuthService from './authService';
import { AuthProviders } from './authProviders';
// import AdminAuthService from "./adminService";
import Log from '../../../utils/log';

/**
 * Gets the firebase provider object for specified provider
 * (Currently only Google and Facebook are supported)
 * @param {AuthProviders} provider
 * @returns firebase provider object for specified provider
 */
export const getThirdPartyAuthProvider = (provider: AuthProviders) => {
  if (provider === AuthProviders.Google) {
    return new auth.GoogleAuthProvider();
  } else if (provider === AuthProviders.Facebook) {
    return new auth.FacebookAuthProvider();
  }
  throw new Error("Invalid Third Party AuthProvider");
};
/**
 * Gets the available sign in methods for existing user identified by email
 *
 * @param {string} email
 * @returns
 */
export const getSignInMethodsForEmail = async (email: string) => {
  const signInMethods: string[] = await AuthService.Instance.fetchSignInMethodsForEmail(
    email
  );
  return signInMethods;
};

/**
 * Checks if email is associated to an existing user
 *
 * @param {string} email
 * @returns
 */
export const checkIfUserExists = async (email: string) => {
  const isExistingUser = (await getSignInMethodsForEmail(email)).length > 0;
  return isExistingUser;
};

/**
 *  Sends a password reset mail to the specified email
 *
 * @param {string} email
 */
export const sendPasswordResetEmail = async (email: string) => {
  await AuthService.Instance.sendPasswordResetEmail(email);
};
/**
 * Resets the password for the email with new one
 *
 * @param {string} actionCode
 * @param {string} newPassword
 * @param {string} continueUrl
 * @returns { object of kind { email: string, password: string, continueUrl: string }
 */
export const resetPassword = async (
  actionCode: string,
  newPassword: string,
  continueUrl: string
) => {
  let email: string;
  try {
    // Can use email through some callback
    email = await AuthService.Instance.verifyPasswordResetCode(actionCode);
  } catch (error) {
    Log.error(error as string);
    throw new Error(
      "Invalid or expired action code. Try to reset the password again."
    );
  }
  try {
    await AuthService.Instance.confirmPasswordReset(actionCode, newPassword);
  } catch (error) {
    Log.error(error as string);
    throw new Error(`Error occurred during confirmation. 
      The code might have expired or the password is too weak.`);
  }
  return { email, password: newPassword, continueUrl };
};

/**
 * Verfies the email against provided action code
 *
 * @param {string} actionCode
 * @param {string} continueUrl
 */
export const handleVerifyEmail = async (
  actionCode: string,
  continueUrl: string
) => {
  try {
    // Try to apply the email verification code.
    await AuthService.Instance.applyActionCode(actionCode);
    return { continueUrl };
  } catch (error) {
    // Email address has been verified.

    // Display a confirmation message to the user.
    // You could also provide the user with a link back to the app.

    // If a continue URL is available, display a button which on
    // click redirects the user back to the app via continueUrl with
    // additional state determined from that URL's parameters.

    throw new Error(`Code is invalid or expired. 
      Please verify email address again`);
  }
};

// /**
//  * Deletes any unverified email
//  *
//  * @param {string} email
//  */
// export const DeleteUnverifiedEmail = async (email: string) => {
//   const user = await AdminAuthService.Instance.getUser(email);
//   if (!user.emailVerified) {
//     await AdminAuthService.Instance.deleteUser(email);
//   }
// };

/**
 *  Logs out the user of the firebase session
 *
 */
export const logout = async () => {
  await AuthService.Instance.signOut();
};

/**
 *  Adds authchanged and error event handlers to *onAuthStateChanged* event of firebase
 *
 * @param {((user: User | null) => void)} userChanged
 * @param {*} [errorCb=() => {}]
 */
export const addOnAuthStateChanged = (
  userChanged: (user: User | null) => void,
  errorCb = () => { }
) => {
  AuthService.Instance.onAuthStateChanged(userChanged, errorCb);
};
