import { toArray } from 'firestore/db/helpers';
import {
  CollectionSchemaDef,
  CollectionSchemaDefs,
  SubCollectionSchemaDefs
} from './models/collectionsSchema';
import { throwErrorAndLog } from '../../../utils/helpers';
import SchemaDef, { SubCollectionDef } from './models/schema';
import { flattenDeep, pick } from 'lodash';

export function getSubCollectionSchemaDefs(
  subCollection: SubCollectionDef | SubCollectionDef[],
  subCollectionSchemaDefs: SubCollectionSchemaDefs
): SubCollectionSchemaDefs {
  return pick(
    subCollectionSchemaDefs,
    toArray(subCollection).map(subCol => subCol.id)
  );
}

export function getCollectionSchemaDef(
  collectionSchemaDefs: CollectionSchemaDefs,
  collectionId: string
) {
  return collectionSchemaDefs[collectionId];
}

export function getKeyProperties(colSchemaDef: CollectionSchemaDef) {
  return Object.keys(colSchemaDef).filter(fieldName =>
    toArray(colSchemaDef[fieldName]).some(schemaDef => schemaDef.key)
  );
}

export function checkIsSubCollection(
  subCollectionId: string,
  schema: SchemaDef,
  collectionId?: string
) {
  return Object.values(schema.collections).some(
    collectionDef =>
      collectionDef.subCollection &&
      toArray(collectionDef.subCollection).some(
        subColl => subColl.id === subCollectionId
      ) &&
      (collectionId ? collectionDef.id === collectionId : true)
  );
}

export function validateCollection(collectionId: string, schema: SchemaDef) {
  if (!(collectionId in schema.collections)) {
    throwErrorAndLog(
      `collection "${collectionId}" doesn't exist in specified schema`
    );
  }
}

export function getSubCollectionDefs(schema: SchemaDef) {
  return flattenDeep(
    Object.values(schema.collections).map(col =>
      toArray(col.subCollection || [])
    )
  );
}

export function validateSubCollection(
  subCollectionId: string,
  schema: SchemaDef,
  collectionId?: string
) {
  if (!checkIsSubCollection(subCollectionId, schema, collectionId)) {
    throwErrorAndLog(
      `Subcollection "${subCollectionId}" doesn't exist in 
        specified ${collectionId ? `collection "${collectionId}"` : 'schema'}`
    );
  }
}

export function getCollectionDef(collectionId: string, schema: SchemaDef) {
  validateCollection(collectionId, schema);
  return schema.collections[collectionId];
}

export function getSubCollectionDef(
  subCollectionId: string,
  schema: SchemaDef
) {
  const filteredSubColls = getSubCollectionDefs(schema).filter(
    subColl => subColl.id === subCollectionId
  );
  if (filteredSubColls.length === 0) {
    throwErrorAndLog(
      `Subcollection "${subCollectionId}" doesn't exist in specified schema`
    );
  }
  return filteredSubColls[0];
}

export function getSubCollectionNames(
  parentCollectionId: string,
  schema: SchemaDef
) {
  const { subCollection } = schema.collections[parentCollectionId];
  if (subCollection) {
    return toArray(subCollection).map(subColDef => subColDef.name);
  }
  return undefined;
}

export function getSubCollectionIds(
  parentCollectionId: string,
  schema: SchemaDef
) {
  const { subCollection } = schema.collections[parentCollectionId];
  if (subCollection) {
    return toArray(subCollection).map(subColDef => subColDef.id);
  }
  return undefined;
}
