import React, { useEffect, useRef, useState } from 'react';
import { Form, Spinner } from 'reactstrap';
import { PhoneNumber } from 'components/FormControls';
import { Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import PasswordInput from '../Auth/PasswordInput';
import { PhoneAuth } from 'firestore/auth/providers';
import { useHistory } from 'react-router-dom';
const RECAPTCHA_CONTAINER_ID = 'recaptcha';

function Login() {
  const { control, register, handleSubmit, errors, setError } = useForm({
    mode: 'onTouched'
  });
  const [loading, setLoading] = useState(false);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const history = useHistory();
  const phoneAuthRef = useRef(null);
  const successHandler = () => {
    console.log('success');
  };
  const failHandler = () => {
    console.log('failed');
  };
  useEffect(() => {
    if (!phoneAuthRef.current) {
      phoneAuthRef.current = new PhoneAuth(
        RECAPTCHA_CONTAINER_ID,
        successHandler,
        failHandler
      );
    }
  }, []);
  const initiateLogin = async data => {
    try {
      setLoading(true);
      console.log(errors);
      await phoneAuthRef.current.initiatePhoneLogin('+' + data.phoneNumber);
      setLoading(false);
      setShowVerifyOTP(true);
    } catch (e) {
      setError('phoneNumber', {
        type: 'manual',
        message: e?.message
      });
      setLoading(false);
    }
  };
  const verifyOTP = async data => {
    try {
      setLoading(true);
      await phoneAuthRef.current.verifyPhoneNumber(data.verifyOTP);
      history.push('/user/seva/book');
    } catch (e) {
      setError('verifyOTP', {
        type: 'manual',
        message: 'Invalid OTP. Please try again'
      });
      setLoading(false);
    }
  };
  return (
    <Form onSubmit={handleSubmit(showVerifyOTP ? verifyOTP : initiateLogin)}>
      <PhoneNumber
        formGroupClassName="my-3"
        label="Phone Number*"
        control={control}
        rules={{
          validPhoneNumber: value => 'Please enter a valid phoneNumber'
        }}
        name="phoneNumber"
        className="bg-transparent shadow form-control-label"
        setError={setError}
        inputProps={{ id: RECAPTCHA_CONTAINER_ID }}
        error={errors.phoneNumber}
      />
      {showVerifyOTP && (
        <PasswordInput
          show={true}
          ref={register({
            required: 'OTP should not be left blank',
            maxLength: 6 || 'OTP should be 6 characters or les'
          })}
          name="verifyOTP"
          label="Verify OTP"
          inputClassName="bg-transparent shadow form-control-label border-0"
          inputGroupTextClassName="bg-transparent shadow form-control-label border-0"
          error={errors.verifyOTP}
        />
      )}
      {loading && (
        <div className="py-2 my-2 d-flex flex-column justify-content-center align-items-center">
          <Spinner />
          <small>{showVerifyOTP ? 'Verifying OTP..' : ' Sending OTP...'}</small>
        </div>
      )}
      <Button type="submit" className="btn-block btn-default">
        {showVerifyOTP ? 'Verify OTP' : 'Send OTP'}
      </Button>
    </Form>
  );
}

export default Login;
