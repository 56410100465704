import React, { useState, useCallback, useRef } from "react";
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Button,
  Form,
  Spinner,
} from "reactstrap";
import { LoginUtils } from "utils/login";
import PasswordInput from "views/examples/Auth/PasswordInput";
import { useEffect } from "react";

function ForgotPassword({ actionCode }) {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const resetHandler = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        if (password.length < 3) {
          return setError({
            message: "Password should be minimum of 3 characters",
          });
        }
        await LoginUtils.resetPassword(
          actionCode,
          password,
          `${window.location.origin}/user/home`
        );
        setLoading(false);
        setSuccess(true);
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    },
    [password, actionCode]
  );
  return (
    <Container style={{ maxWidth: "600px" }} className="my-6">
      <Card>
        <CardHeader className="custom-header-bg p-3 d-flex">
          <h4 className="d-flex mb-0 justify-content-center align-items-center flex-grow-1 mr-2 text-white">
            Reset Password
          </h4>
        </CardHeader>
        <CardBody>
          {success ? (
            <p>
              Your password has been successfully changed. Please use the new
              password and login again
            </p>
          ) : (
            <Form>
              <PasswordInput
                ref={inputRef}
                error={error}
                value={password}
                onChange={(e) => {
                  if (error) {
                    setError(false);
                  }
                  const value = e.target.value;
                  setPassword(value);
                }}
                placeholder={`Enter your new password`}
              />
              {error && <small className="text-danger"> {error.message}</small>}
              {loading && (
                <div className="my-3 d-flex flex-column align-items-center">
                  <small className="mb-2">Changing the password...</small>
                  <Spinner color="default" />
                </div>
              )}
              <div className="d-flex my-4 justify-content-center align-items-center">
                <Button
                  disabled={loading}
                  type="submit"
                  style={{ backgroundColor: "#2C8E8D" }}
                  onClick={resetHandler}
                  className="text-white text-uppercase"
                >
                  SUBMIT
                </Button>
              </div>
            </Form>
          )}
        </CardBody>
      </Card>
    </Container>
  );
}

export default ForgotPassword;
