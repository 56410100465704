import React from 'react';
import { motion } from 'framer-motion';

function List({ children }) {
  return (
    // <AnimateSharedLayout>
    <motion.ul className="pl-0 mb-0 list-unstyled d-flex flex-column">
      {children}
    </motion.ul>
    // </AnimateSharedLayout>
  );
}

export default List;
