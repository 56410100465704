import React from 'react';
import { useVirtual } from 'react-virtual';
import TransactionCard from './TransactionCard';
function RowVirtualizerDynamic({ rows }) {
  const parentRef = React.useRef();

  const rowVirtualizer = useVirtual({
    size: rows.length,
    parentRef,
    estimateSize: React.useCallback(() => 682, []),
    overscan: 2
  });

  return (
    <>
      <div
        ref={parentRef}
        className="List"
        style={{
          height: `calc(100vh - 12rem)`,
          width: `100%`,
          overflow: 'auto'
        }}
      >
        <div
          style={{
            height: `${rowVirtualizer.totalSize}px`,
            width: '100%',
            position: 'relative'
          }}
        >
          {rowVirtualizer.virtualItems.map(virtualRow => (
            <div
              key={virtualRow.index}
              ref={virtualRow.measureRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${rows[virtualRow.index]}px`,
                transform: `translateY(${virtualRow.start}px)`
              }}
            >
              <TransactionCard
                transaction={rows[virtualRow.index]}
                key={virtualRow.index}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default RowVirtualizerDynamic;
