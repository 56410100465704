
// export default process.env.VERCEL_ENV === 'production' ?
//   {
//     apiKey: "AIzaSyA0V4RcmhXt_z1wTA9GWT1oOXJJ7TKY2LA",
//     authDomain: "shankermut.firebaseapp.com",
//     databaseURL: "https://shankermut.firebaseio.com",
//     projectId: "shankermut",
//     storageBucket: "shankermut.appspot.com",
//     messagingSenderId: "613830332787",
//     appId: "1:613830332787:web:0d6cb624746284f399b506",
//   } :
//   {
//     apiKey: "AIzaSyA2UHpdr83tOdXbG6srzVxc82frrz0Xhzk",
//     authDomain: "shankermut-preview-test.firebaseapp.com",
//     projectId: "shankermut-preview-test",
//     storageBucket: "shankermut-preview-test.appspot.com",
//     messagingSenderId: "662328550954",
//     appId: "1:662328550954:web:c22a507c988e2c5bcb4553"
//   };
export default {
  apiKey: "AIzaSyA0V4RcmhXt_z1wTA9GWT1oOXJJ7TKY2LA",
  authDomain: "shankermut.firebaseapp.com",
  databaseURL: "https://shankermut.firebaseio.com",
  projectId: "shankermut",
  storageBucket: "shankermut.appspot.com",
  messagingSenderId: "613830332787",
  appId: "1:613830332787:web:0d6cb624746284f399b506",
};
