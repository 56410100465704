import { auth } from "firebase";
import Project from "../../init/project";

/**
 * Singleton for firebase auth object
 *
 * @export
 * @class AuthService
 */
export default class AuthService {
  private static _authService = auth(Project.Instance);
  public static readonly Instance: auth.Auth = AuthService._authService;
}
