import React from "react";
import { useLocation } from "react-router-dom";
import EmailVerify from "./EmailVerify";
import ResetPasswordCard from "./ResetPassword";
const MODES = {
  VERIFY_EMAIL: "verifyEmail",
  RESET_PASSWORD: "resetPassword",
};
function Verify() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const mode = params.get("mode");
  const actionCode = params.get("oobCode");
  if (mode === MODES.VERIFY_EMAIL) {
    return <EmailVerify actionCode={actionCode} />;
  }
  if (mode === MODES.RESET_PASSWORD) {
    return <ResetPasswordCard actionCode={actionCode} />;
  }
  return null;
}

export default Verify;
