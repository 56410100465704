import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHome,
    faCalendarCheck,
    faChevronDown,
    faKey,
    faRunning,
    faUser,
    faInfo
} from '@fortawesome/free-solid-svg-icons';
// reactstrap components
import Swal from 'sweetalert2';
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Nav,
    NavItem,
    Media,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import './AdminNavbar.scss';
import useAuth from 'hooks/useAuth';
import { faAccessibleIcon } from '@fortawesome/free-brands-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';
import AuthService from 'firestore/auth/utils/authService';

function BottomNavbar({ onLinkClicked }) {
    const { auth } = useAuth();

    return (
        <Nav
            id="my-navbar"
            className="d-flex d-sm-none pr-lg-8 align-items-center flex-row flex-grow-1 justify-content-sm-center justify-content-lg-end my-navbar"
            navbar
        >
            <NavItem className="admin-nav-item">
                <NavLink
                    className="text-white px-4 my-nav-link"
                    activeClassName="selected"
                    onClick={onLinkClicked}
                    to="/user/home/"
                >
                    <FontAwesomeIcon className="mr-2" icon={faHome} />
                    Home
                </NavLink>
            </NavItem>
            <NavItem className="admin-nav-item">
                <NavLink
                    className="text-white px-4 my-nav-link"
                    activeClassName="selected"
                    onClick={onLinkClicked}
                    to="/user/seva/"
                >
                    <FontAwesomeIcon className="mr-2" icon={faCalendarCheck} />
                    Book Seva
                </NavLink>
            </NavItem>
            <UncontrolledDropdown className="border-right-unset" nav>
                <DropdownToggle className="pr-0 my-nav-link" nav>
                    <Media className="ml-2">
                        <span className="mb-0 mr-2 display-5">More</span>
                        <FontAwesomeIcon className="mt-1" icon={faChevronDown} />
                    </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                    <AnimatePresence exitBeforeEnter>
                        <motion.ul
                            className="p-0"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5, when: 'beforeChildren' }}
                        >
                            {auth && (
                                <DropdownItem className="noti-title" header tag="div">
                                    <h6 className="text-overflow m-0">
                                        Welcome!{' '}
                                        <small className="d-block text-dark">
                                            {auth.email || auth.phoneNumber}{' '}
                                        </small>
                                    </h6>
                                </DropdownItem>
                            )}
                            <DropdownItem
                                onClick={onLinkClicked}
                                to="/user/seva-information"
                                tag={Link}
                            >
                                <FontAwesomeIcon className="mr-2" icon={faInfo} />
                                <span>Sevas Info</span>
                            </DropdownItem>
                            <DropdownItem
                                onClick={onLinkClicked}
                                to="/user/about"
                                tag={Link}
                            >
                                <FontAwesomeIcon className="mr-3" icon={faUser} />
                                <span>About</span>
                            </DropdownItem>
                            <DropdownItem
                                onClick={onLinkClicked}
                                to="/user/contact"
                                tag={Link}
                            >
                                <FontAwesomeIcon className="mr-3" icon={faAccessibleIcon} />
                                <span>Contact Us</span>
                            </DropdownItem>
                            <DropdownItem divider />
                            {!auth ? (
                                <DropdownItem
                                    onClick={onLinkClicked}
                                    to="/user/login"
                                    tag={Link}
                                >
                                    <FontAwesomeIcon className="mr-3" icon={faKey} />
                                    <span>Login / Register</span>
                                </DropdownItem>
                            ) : (
                                <>
                                    <DropdownItem
                                        onClick={() => {
                                            AuthService.Instance.signOut()
                                                .then(() => {
                                                    Swal.fire(
                                                        'Logout Successfull!!',
                                                        'You were logged out successfully',
                                                        'success'
                                                    );
                                                })
                                                .catch(() => {
                                                    Swal.fire({
                                                        icon: 'error',
                                                        title: 'Logout Failed',
                                                        text: 'Please try to logout again'
                                                    });
                                                });
                                        }}
                                    >
                                        <FontAwesomeIcon className="mr-3" icon={faRunning} />
                                        <span>Logout</span>
                                    </DropdownItem>
                                </>
                            )}
                        </motion.ul>
                    </AnimatePresence>
                </DropdownMenu>
            </UncontrolledDropdown>
        </Nav>
    );
}

export default BottomNavbar;
