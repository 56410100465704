import { throwErrorAndLog } from '../../../utils/helpers';
import {
  CollectionSchemaDefs,
  SubCollectionSchemaDefs
} from './models/collectionsSchema';
import SchemaDef from './models/schema';
import CollectionSchemaValidator from './validators/schema/collectionSchemaValidator';
import SubCollectionSchemaValidator from './validators/schema/subCollectionSchemaValidator';

export default class Schema {
  private static _dbSchema: SchemaDef;
  private static _collectionSchema: CollectionSchemaDefs;
  private static _subCollectionSchema: SubCollectionSchemaDefs;
  private static _initialized: boolean;

  public static get SchemaDef() {
    if (!Schema._initialized) {
      throwErrorAndLog(
        'Please initialize the schema before accessing schema definition'
      );
    }
    return Schema._dbSchema;
  }

  public static get CollectionSchemaDefs() {
    if (!Schema._initialized) {
      throwErrorAndLog(
        'Please initialize the schema before accessing collection schema definitions'
      );
    }
    return Schema._collectionSchema;
  }

  public static get SubCollectionSchemaDefs() {
    if (!Schema._initialized) {
      throwErrorAndLog(
        `Please initialize the schema before accessing sub-collection 
          schema definitions`
      );
    }
    return Schema._subCollectionSchema;
  }

  public static Initialize(
    schema: Object,
    collectionSchema: Object,
    subCollectionSchema: Object
  ) {
    Schema._dbSchema = (schema as unknown) as SchemaDef;
    Schema._collectionSchema = (collectionSchema as unknown) as CollectionSchemaDefs;
    Schema._subCollectionSchema = (subCollectionSchema as unknown) as SubCollectionSchemaDefs;
    Schema._initialized = false;
    if (!Schema._dbSchema) {
      throwErrorAndLog('Invalid Schema specified');
    }
    if (!Schema._collectionSchema) {
      throwErrorAndLog('Invalid collection schema definitions specified');
    }
    const collectionSchemaValidator = new CollectionSchemaValidator(
      Schema._dbSchema,
      Schema._collectionSchema
    );
    Schema._collectionSchema = collectionSchemaValidator.validateCollections();
    const subCollectionSchemaValidator = new SubCollectionSchemaValidator(
      Schema._dbSchema,
      Schema._subCollectionSchema
    );
    Schema._subCollectionSchema = subCollectionSchemaValidator.validateCollections();
    Schema._initialized = true;
  }
}
