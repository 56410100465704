import { groupBySequential, transformSequential } from 'firestore/db/helpers';
import { firestore } from 'firebase';
import { QuerySpec, SubCollectionQueryOption } from './queryOptions';
import { throwErrorAndLog } from '../../../utils/helpers';
import Read from './base';
import Schema from '../schema';
import { checkIsSubCollection, validateCollection } from '../schema/helpers';
import QueryParser from './queryParser';

const defaultQuerySpec: QuerySpec = {
  paging: { skip: 0, take: 10 },
  subCollectionQueryOption: SubCollectionQueryOption.withoutSubCollection,
  transformer: []
};
export default class Query {
  public static from(collectionPath: string, subCollectionIds?: string[]) {
    return new QueryBase(collectionPath, subCollectionIds);
  }
}
export class QueryBase {
  private _collectionId: string;
  private _subCollectionIds: string[];
  constructor(collectionId: string, subCollectionIds?: string[]) {
    this._collectionId = collectionId;
    this._subCollectionIds = subCollectionIds || [];
  }
  public async Execute(query: QuerySpec = defaultQuerySpec) {
    const {
      collectionId,
      subCollectionQueryOption,
      documentId,
      filter,
      orderBy,
      paging,
      groupBy,
      transformer
    } = {
      ...defaultQuerySpec,
      ...query,
      collectionId: this._collectionId
    };

    let docs:
      | (firestore.DocumentData | undefined)[]
      | { [x: string]: firestore.DocumentData[] }
      | undefined;

    //#region validate collection
    if (!collectionId) {
      throwErrorAndLog('collectionId cannot be empty');
    }
    validateCollection(collectionId, Schema.SchemaDef);
    //#endregion

    //#region Validate subcollectionIds

    this._subCollectionIds.forEach(subCollectionId => {
      if (
        !checkIsSubCollection(subCollectionId, Schema.SchemaDef, collectionId)
      ) {
        throwErrorAndLog(`"${subCollectionId} in the subcollectionIds array is not a
         valid subcollection"`);
      }
    });

    //#endregion

    //#region Only if specific document is needed

    if (documentId) {
      return transformSequential(
        await Read.from(collectionId).getDocument(documentId),
        transformer
      );
    }

    //#endregion

    //#region If subcollection filters exist

    if (QueryParser.checkIfSubCollectionQueryExists(filter)) {
      const parsedFilterQueries = QueryParser.parseFilterQueries(
        collectionId,
        filter,
        Schema.SchemaDef
      );
      docs = await Read.fromSubCollection(
        collectionId,
        this._subCollectionIds
      ).getDocuments(
        parsedFilterQueries,
        subCollectionQueryOption,
        orderBy,
        paging
      );
    }

    //#endregion

    //#region If no subcollection filter exists
    else {
      docs = await Read.from(collectionId).getDocuments(
        filter,
        subCollectionQueryOption,
        orderBy,
        paging
      );
    }

    //#endregion

    //#region Apply transformation

    docs = transformSequential(docs, transformer);

    //#endregion

    //#region Apply grouping (if provided)

    if (groupBy && docs && docs.length > 0) {
      return groupBySequential(docs, groupBy);
    }

    //#endregion

    return docs;
  }
}
