import SchemaDef, { CollectionsDef } from '../../models/schema';
import {
  BaseCollectionSchemaDef,
  SubCollectionSchemaDefs
} from '../../models/collectionsSchema';
import { toArray, toValue } from '../../../helpers';
import SchemaValidatorBase from './schemaValidatorBase';

export default class SubCollectionSchemaValidator extends SchemaValidatorBase {
  private _schema: SchemaDef;
  private _subCollectionSchemaDefs: SubCollectionSchemaDefs;
  constructor(
    schema: SchemaDef,
    subCollectionSchemaDefs: SubCollectionSchemaDefs
  ) {
    super();
    this._schema = schema;
    this._subCollectionSchemaDefs = subCollectionSchemaDefs;
  }

  public validateCollections(): SubCollectionSchemaDefs {
    // Get all ids of sub-collections
    const subCollectionIds = this.getSubCollectionIds(this._schema.collections);
    // The collection id should have valid schema defined
    this.validateCollectionIds(subCollectionIds, this._subCollectionSchemaDefs);
    return subCollectionIds.reduce(
      (subCollectionSchemaDefs, subCollectionId) => {
        // Flattening collection schema based on "extends"
        const subCollectionSchemaDef = this.getFlattenSubCollectionSchema(
          subCollectionId,
          this._subCollectionSchemaDefs
        );
        // Validating flattened collection schema
        // subCollectionSchemaDef.forEach(this.validateCollectionSchema);
        return {
          ...subCollectionSchemaDefs,
          [subCollectionId]: toValue(subCollectionSchemaDef)
        };
      },
      {}
    );
  }

  private getSubCollectionIds(collections: CollectionsDef) {
    return Object.keys(collections).reduce(
      (subColIds: string[], collection) => {
        // iterate through subcollection and get the ids
        const { subCollection } = collections[collection];
        // Check for subcollection existence
        if (subCollection) {
          const subCollIds = toArray(subCollection).map(subColl => subColl.id);
          return [...subColIds, ...subCollIds];
        }
        return subColIds;
      },
      []
    );
  }

  private getFlattenSubCollectionSchema(
    subCollectionId: string,
    subCollectionSchemaDefs: SubCollectionSchemaDefs
  ): BaseCollectionSchemaDef[] {
    const subCollectionSchemaDef = toArray(
      subCollectionSchemaDefs[subCollectionId]
    );
    return subCollectionSchemaDef.map(schemaDef =>
      this.flattenCollectionSchema(
        subCollectionId,
        schemaDef,
        subCollectionSchemaDefs
      )
    );
  }
}
