import React, { useEffect } from 'react';
import { useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './Accordion.scss';
import { Card } from 'reactstrap';

function Accordion({
  showTitleAlways = false,
  children,
  expandOnMount = false,
  title = '',
  headerClassName = ''
}) {
  const [isOpen, setIsOpen] = useState(expandOnMount);
  const titleControls = useAnimation();
  const sectionControls = useAnimation();
  useEffect(() => {
    const animation = async () => {
      if (isOpen) {
        await titleControls.start(
          {
            display: showTitleAlways ? 'flex' : 'none'
          },
          { duration: 0 }
        );
        await sectionControls.start(
          {
            opacity: 1,
            height: 'auto'
          },
          { duration: 0 }
        );
      } else {
        await sectionControls.start(
          {
            opacity: 0,
            height: 0
          },
          { duration: 0 }
        );
        await titleControls.start(
          {
            display: 'flex'
          },
          { duration: 0 }
        );
      }
    };
    animation();
  }, [isOpen, sectionControls, titleControls, showTitleAlways]);
  return (
    <Card style={{ overflow: 'visible' }} className="border mb-3">
      <header className="accordion-header">
        <motion.h4
          initial={false}
          animate={titleControls}
          className={'flex-grow-1 p-2 px-4 ' + headerClassName}
          transition={{ duration: 0, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {title}
        </motion.h4>
        <motion.div
          onClick={() => setIsOpen(state => !state)}
          whileHover={{ cursor: 'pointer' }}
          animate={!isOpen ? { rotate: '180deg' } : { rotate: '360deg' }}
          className={'header-icon ' + headerClassName}
          whileTap={{ scale: 0.9 }}
        >
          <FontAwesomeIcon size={'1x'} icon={faChevronUp} />
        </motion.div>
      </header>
      <motion.section
        key="content"
        initial={false}
        animate={sectionControls}
        transition={{ duration: 0, ease: [0.04, 0.62, 0.23, 0.98] }}
      >
        {children}
      </motion.section>
    </Card>
  );
}

export default Accordion;
