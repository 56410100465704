import usePersonDetails from 'hooks/usePersonDetails';
import React from 'react';
import { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { FormGroup, InputGroup, Label } from 'reactstrap';
import { validateText } from 'views/examples/SevaBooking/Booking/Person';
import { styles } from './constants';
import './CreatableSelect.scss';

export default function CreatableSingle({
  name,
  id,
  defaultValue,
  control,
  error,
  validateHelperText,
  required,
  label,
  setValue,
  index,
  autoFocus = false,
  ref
}) {
  const { data: personDetails } = usePersonDetails();
  const [newDetails, setNewDetails] = useState([]);
  const currentPersonDetails = useMemo(
    () =>
      personDetails?.concat(newDetails)?.reduce((prev, person) => {
        prev[person?.name?.replaceAll(' ', '')] = person;
        return prev;
      }, {}),
    [newDetails, personDetails]
  );
  const options = useMemo(() => {
    return personDetails?.concat(newDetails)?.map(person => ({
      label: person?.name,
      value: person?.name?.replaceAll(' ', '')
    }));
  }, [newDetails, personDetails]);
  const addOptionToOptions = inputValue => {
    const label = inputValue?.trim() || '';
    const optionExists = options.find(opt => opt.label === label);

    if (!label || optionExists) {
      return;
    }

    const option = { label, name: label };

    // Add new option to menu list
    setNewDetails(state => [option, ...state]);
  };
  const handleChange = (e, onChange, action) => {
    addOptionToOptions(e?.label);
    setValue(`people[${index}].name`, e?.value ?? '');
    const person = currentPersonDetails[e?.value];
    if (person) {
      setValue(`people[${index}].gothram`, person.gothram || '');
      setValue(`people[${index}].nakshatram`, person.nakshatram || '');
    }
    if (!e) {
      setValue(`people[${index}].gothram`, '');
      setValue(`people[${index}].nakshatram`, '');
    }
    onChange(e?.label);
  };
  function handleBlur(e, onChange) {
    const value = e.target.value;
    if (value && value.trim() !== '') {
      handleChange({ label: value, value: value }, onChange);
    }
  }
  return (
    <FormGroup key={id}>
      <Label className="form-control-label" for={id}>
        {label}
        {required ? '*' : ''}
      </Label>
      <InputGroup
        className={`input-group-alternative border ${
          error ? 'border-danger' : ''
        }`}
      >
        <Controller
          render={({ onChange, value }) => {
            return (
              <CreatableSelect
                autoFocus
                styles={styles}
                theme={{ spacing: { controlHeight: 46 } }}
                className="w-100"
                value={options?.find(person => person.value === value)}
                defaultInputValue={defaultValue}
                createOptionPosition={'first'}
                defaultValue={{
                  label: defaultValue,
                  value: defaultValue?.replaceAll(' ', '')
                }}
                inputValue={
                  currentPersonDetails && currentPersonDetails[value]?.name
                }
                isClearable
                onChange={(e, { action }) => handleChange(e, onChange, action)}
                onBlur={e => handleBlur(e, onChange)}
                options={options}
              />
            );
          }}
          defaultValue={defaultValue}
          control={control}
          name={name}
          id={id}
          rules={{
            validate: v => {
              return validateText(v);
            },
            required: true
          }}
        />
      </InputGroup>
      {error && (
        <p className="text-danger font-weight-500">
          <small>
            {error?.type === 'validate'
              ? validateHelperText
              : error?.type === 'required'
              ? 'Please enter a name...'
              : error.message}
          </small>
        </p>
      )}
    </FormGroup>
  );
}
