import React, { useContext } from 'react';
import Pooja from './Pooja';
import StepDetails from './StepDetails';
import { StepperFooter } from 'components/Stepper/Stepper';
import { useForm } from 'react-hook-form';
import { BookSevaContext } from './context';
import { useBookSevaState } from 'hooks/useBookSevaState';
import { addPooja } from 'actions/seva';
import { StepperContext } from 'components/Stepper/context';
import { Button, Col, Form } from 'reactstrap';

function PoojaDetails() {
  const [{ poojas }, dispatch] = useBookSevaState();
  const { steps, page, paginate } = useContext(StepperContext);
  const methods = useForm({
    defaultValues: {
      poojas: poojas ? [...poojas] : []
    },
    shouldUnregister: false
  });
  const submit = data => {
    const { poojas: currentPoojas } = methods.getValues();
    if (!methods.formState.errors.poojas) {
      dispatch(addPooja(currentPoojas));
      paginate(1);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => () => submit(), []);
  return (
    <BookSevaContext.Provider value={methods}>
      <Form className="w-100" onSubmit={methods.handleSubmit(submit)}>
        <StepDetails
          defaultItems={poojas}
          ItemComponent={Pooja}
          keyId="poojas"
          title="Details Of Pooja"
          titleButtonText="Add Seva"
        />
        <StepperFooter>
          <Col className="my-2 d-flex justify-content-end">
            <Button
              type="submit"
              disabled={page === 0}
              onClick={() => paginate(-1)}
              className="btn-warning"
            >
              Back
            </Button>
            <Button
              type="submit"
              disabled={page === steps?.length}
              color="default"
            >
              Next
            </Button>
          </Col>
        </StepperFooter>
      </Form>
    </BookSevaContext.Provider>
  );
}

export default PoojaDetails;
