import React, { useContext } from 'react';
import { Button, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { List, ListItem } from 'components/List';
import { useFieldArray } from 'react-hook-form';
import { BookSevaContext } from './context';

function StepDetails({ title, titleButtonText, keyId, ItemComponent }) {
  const { control } = useContext(BookSevaContext);
  const { fields, append, remove } = useFieldArray({ control, name: keyId });

  return (
    <Row className="d-flex flex-column">
      <Row className="mx-0 mb-3 d-flex justify-content-between flex-grow-1 flex-wrap align-items-center">
        <div className="my-2 my-md-0 col-md-5 px-5 d-flex justify-content-center justify-content-sm-start ">
          <h3 className="m-0">{title}</h3>
        </div>
        <div className="d-none d-lg-block col-md-5 col-lg-4 px-5">
          <Button
            color="default"
            onClick={() => append()}
            className="btn-block"
          >
            <FontAwesomeIcon className="mr-2" icon={faPlus} />
            {titleButtonText}
          </Button>
        </div>
      </Row>
      <Row className="mx-0 px-3 d-flex flex-column w-100 justify-content-between flex-grow-1 flex-wrap">
        <List>
          {fields.map((item, index) => {
            return (
              <ListItem id={item.id} key={item.id}>
                <ItemComponent
                  item={item}
                  append={append}
                  remove={remove}
                  index={index}
                  keyId={keyId}
                  isLast={index === fields.length - 1}
                />
              </ListItem>
            );
          })}
        </List>
      </Row>
    </Row>
  );
}

export default StepDetails;
