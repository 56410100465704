import { SubCollectionFilterQueries } from './queryParser';
import { firestore } from 'firebase';
import {
  FilterQuery,
  FilterQueryDef,
  OrderQuery,
  PagingQuery,
  CustomFilterQuery,
  CompoundQueryDef,
  QueryCompounder,
  CustomFirestoreDocDataQuery,
  NestedCompoundQueryDef,
  FireStoreDocQueryChainer
} from './queryOptions';
import { throwErrorAndLog } from '../../../utils/helpers';
import { intersectionBy, unionBy } from 'lodash';
import { reduceAsyncParallel, reduceAsyncSequential } from '../helpers';

type Cache = {
  [key: string]: string;
};
let cache: Cache = {};

async function applyFilterQueries(
  collectionRef: firestore.Query<firestore.DocumentData>,
  filterQueries?: FilterQuery[]
) {
  if (filterQueries && filterQueries.length > 0) {
    // Separate custom compound filter queries
    const nestedCompoundFilterQueries = filterQueries.filter(
      query => (query as CustomFilterQuery).compoundQuery
    );
    const filteredRef = filterQueries
      .filter(query => !(query as CustomFilterQuery).compoundQuery)
      .reduce<firestore.Query<firestore.DocumentData>>((ref, filterQuery) => {
        // For custom simple queries
        const customFilterQuery = filterQuery as CustomFilterQuery;
        if (customFilterQuery.query) {
          return customFilterQuery.query(ref);
        }
        // For simple queries
        const { field, operator, value } = filterQuery as FilterQueryDef;
        return ref.where(field, operator, value);
      }, collectionRef);
    if (nestedCompoundFilterQueries.length === 0) {
      return filteredRef;
    }
    // For custom compound filter queries
    return await reduceAsyncSequential<
      FilterQuery,
      firestore.Query<firestore.DocumentData> | CustomFirestoreDocDataQuery
    >(
      nestedCompoundFilterQueries,
      filteredRef,
      async (updatedRef, filterQuery) => {
        const compoundFilterQuery = (filterQuery as CustomFilterQuery)
          .compoundQuery;
        if (compoundFilterQuery) {
          return await applyNestedCompoundFilterQuery(
            compoundFilterQuery,
            updatedRef as firestore.Query<firestore.DocumentData>
          );
        }
        return updatedRef;
      }
    );
  }
  return collectionRef;
}

export async function getQuerySnapshot(
  collectionRef: firestore.Query<firestore.DocumentData>,
  filterQueries?: FilterQuery[]
  // orderBy?: OrderQuery,
  // paging?: PagingQuery
) {
  // TODO pass collection Id
  // const collectionId = 'abc';
  let docQuery = await applyFilterQueries(collectionRef, filterQueries);
  // if (orderBy) {
  //   docQuery = applyOrderBy(docQuery, orderBy);
  // }
  // if (paging) {
  //   docQuery = applyPagination(collectionId, docQuery, paging);
  // }
  const querySnapshot = await docQuery.get();
  // setSnapShotToCache(
  //   collectionId,
  //   querySnapshot.docs[querySnapshot.docs.length - 1]
  // );
  return querySnapshot;
}

export async function applySubCollectionQueries(
  firestoreDb: firestore.Firestore,
  subCollectionFilterQueries: SubCollectionFilterQueries
) {
  return await reduceAsyncParallel<
    FilterQuery[],
    firestore.Query<firestore.DocumentData> | CustomFirestoreDocDataQuery
  >(
    subCollectionFilterQueries,
    async (subCollectionFilterQuery, subCollectionId) => {
      const subCollectionRef = firestoreDb.collectionGroup(subCollectionId);
      return await applyFilterQueries(
        subCollectionRef,
        subCollectionFilterQuery
      );
    }
  );
}

export async function getDocumentsFromSnapshot(
  collectionRef:
    | firestore.Query<firestore.DocumentData>
    | CustomFirestoreDocDataQuery
  // getParentCollectionId = false
) {
  const { docs } = await collectionRef.get();
  return docs.map(doc => doc.data());
  // return {
  //   data: docs.map(doc => doc.data()),
  //   ...(getParentCollectionId && {
  //     parentCollectionId: docs[0].ref.parent?.parent?.parent?.path
  //   })
  // };
}

async function applyNestedCompoundFilterQuery(
  query: NestedCompoundQueryDef,
  collectionRef: firestore.Query<firestore.DocumentData>
) {
  const { compounder, conditions } = query;
  const docRefs = await Promise.all(
    conditions.map(async condition => {
      if ('compounder' in condition) {
        return await applyCompoundFilterQuery(condition, collectionRef);
      }
      const query = condition as FireStoreDocQueryChainer;
      return await applyFireStoreDocQueryChainer(query, collectionRef);
    })
  );
  const filteredDocRefs = await filterRefs(compounder, docRefs);
  return {
    get: async () => {
      return { docs: filteredDocRefs.map(ref => ref.doc) };
    }
  };
}

async function applyCompoundFilterQuery(
  query: CompoundQueryDef,
  collectionRef: firestore.Query<firestore.DocumentData>
) {
  const { compounder, conditions } = query;
  const docRefs = await Promise.all(
    conditions.map(
      async condition =>
        await applyFireStoreDocQueryChainer(condition, collectionRef)
    )
  );
  return await filterRefs(compounder, docRefs);
}

async function applyFireStoreDocQueryChainer(
  condition: FireStoreDocQueryChainer,
  collectionRef: firestore.Query<firestore.DocumentData>
) {
  return (await condition(collectionRef).get()).docs.map(doc => ({
    ref: doc.ref,
    doc
  }));
}

async function filterRefs(
  compounder: QueryCompounder,
  docRefs: {
    ref: firestore.DocumentReference<firestore.DocumentData>;
    doc: firestore.QueryDocumentSnapshot<firestore.DocumentData>;
  }[][]
) {
  switch (compounder) {
    case QueryCompounder.and:
      return intersectionBy(...docRefs, doc => doc.ref.id);
    case QueryCompounder.or:
      return docRefs
        .slice(1, docRefs.length)
        .reduce(
          (unionedRefs, docRef) =>
            unionBy(unionedRefs, docRef, doc => doc.ref.id),
          docRefs[0]
        );
  }
}

export function applyOrderBy(
  query: firestore.Query<firestore.DocumentData>,
  orderBy: OrderQuery
) {
  return query.orderBy(orderBy.field, orderBy.sortBy);
}

export function applyPagination(
  collectionId: string,
  query: firestore.Query<firestore.DocumentData>,
  paging: PagingQuery
) {
  return applyOffsetCursor(query, getSnapshotFromCache(collectionId)).limit(
    paging.take
  );
}

export function getSnapshotFromCache(
  collectionId: string
): firestore.DocumentSnapshot<any> {
  return JSON.parse(cache[collectionId]) as firestore.DocumentSnapshot<any>;
}

export function setSnapShotToCache(
  collectionId: string,
  snapShot: firestore.DocumentSnapshot<any>
) {
  cache[collectionId] = JSON.stringify(snapShot);
  return;
}

export function clearSnapshotCache() {
  cache = {};
}

// integrate https://github.com/stagas/memoize#readme &
//      https://github.com/sindresorhus/mem
// for better memoization to persist snapshot across subsequent calls
// initial strategy is to memoize on collectionId, or JSON stringifying spec object
export function applyOffsetCursor(
  query: firestore.Query<firestore.DocumentData>,
  startSnapshot: firestore.DocumentSnapshot<any>
) {
  if (!startSnapshot || !startSnapshot.exists) {
    throwErrorAndLog(`cannot set cursor startAfter on a snapshot 
      whose data doesn't exists`);
  }
  return query.startAfter(startSnapshot);
}
