export const guidelines = [
  'Tickets can be booked until one day before the pooja is performed',
  'Tickets can be booked only until March 31, 2023',
  'People details should be filled in the order which it has to be read during the pooja',
  'Certain poojas are disabled as the day of performing is not yet finalized. Once it is finalized it will be enabled',
  'Once booked, the tickets cannot be cancelled under any circumstances',
  'Prasadam will not be provided to any pooja during Covid Times',
  'Tickets can be booked only in English Language',
  'Due to technical limitations, you can select and book only 10 dates in Multiple Dates Mode'
];
