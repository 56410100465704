import React, { useState } from 'react';
import classnames from 'classnames';
// reactstrap components
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Container,
  Col
} from 'reactstrap';

import './Login.scss';
// import Register from './Register';
import Login from './Login';
import useAuth from 'hooks/useAuth';

function Auth() {
  const [tabs, setTabs] = useState(2);
  const { auth, authAttempted } = useAuth();
  const toggleNavs = (e, state, index) => {
    e.preventDefault();
    setTabs(index);
  };

  return (
    authAttempted && (
      <Container>
        {!auth && (
          <Card className="bg-transparent">
            <div className="nav-wrapper px-3">
              <Nav
                className="nav-fill flex-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={tabs === 2}
                    style={{ color: '#a5281f' }}
                    className={classnames('bg-transparent mb-sm-3 mb-md-0', {
                      active: tabs === 2
                    })}
                    onClick={e => toggleNavs(e, 'tabs', 2)}
                    href="#pablo"
                    role="tab"
                  >
                    Sign In
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <CardBody>
              <Login />
            </CardBody>
          </Card>
        )}
        <Card className="bg-transparent py-2">
          <CardBody style={{ lineHeight: 2 }} className="text-left pt-0">
            <Col className="mb-3 border-bottom p-3 text-left mt-4 ">
              <h2 className="text-center mb-4">Temple Timings (IST) </h2>
              <div className="flex-column">
                <div className="text-center mb-3">
                  <h5 className="text-default">Mon-Sun (Normal Days)</h5>
                  <div>
                    Morning: 5:00 AM – 11:30 AM <br />
                    Evening: 5:30 PM - 8:00 PM
                  </div>
                </div>
                <div className="text-center">
                  <h5 className="mt-3 mt-sm-0">Sharada Navaratri</h5>
                  <div>
                    Morning: 5:00 AM – 12:30 PM <br />
                    Evening: 5:00 PM - 10:00 PM
                  </div>
                </div>
              </div>
            </Col>
            <h2 className="mb-1 p-2 text-center"> Instructions for Covid 19</h2>
            <Col className="mb-3 pb-4 font-weight-500">
              <div className="d-block mb-2">
                1. Every devotee should wear a mask other wise devotee would not
                be allowed
              </div>
              <div className="d-block mb-2">
                2. Every devotee should practice social distancing and maintain
                a minimum of 6 feet distance when inside the temple premises
              </div>
              <div className="d-block mb-2">
                3. It may be kindly noted that teertha prasadam shall not
                provided during the sharada sharannavaratri's considering
                Covid-19 Hygiene guidelines and devotee safety
              </div>
              <div className="d-block mb-2">
                4. It is advised that devotees above the age of 65 should be
                very very careful. Also, people who are not in their full health
                are advised to avoid visiting the temple in the interest of the
                devotee's health and also other devotees.
              </div>
              <div className="d-block mb-2">
                5. Every devotee should cooperate with the temple authorities to
                keep the temple clean and sanitized.
              </div>
              <div className="d-block mb-2">
                6. Devotees will not be allowed to sit inside the temple
                premises. Devotees should leave the temple premises immediately
                after the darshan
              </div>
              <div className="d-block mb-2 ">
                7. Devotees will not be allowed to do Parayanas inside the
                temple premises in the interest of devotee's health
              </div>
            </Col>
          </CardBody>
        </Card>
      </Container>
    )
  );
}

export default Auth;
