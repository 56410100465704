import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Col,
  Button,
  Spinner
} from 'reactstrap';
import { FormInput } from 'components/FormControls';
import './Donate.scss';
import {
  faUser,
  faAddressCard,
  faRupeeSign
} from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { Payments } from 'utils/payments';
import useAuth from 'hooks/useAuth';
import Create from 'firestore/db/create/base';
import moment from 'moment';
import { removeTimeFromMoment } from 'firestore/db/helpers';

function Donate() {
  const { auth } = useAuth();
  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onTouched'
  });
  const [loading, setLoading] = useState(false);
  const onSubmit = async data => {
    setLoading(true);
    const order = await Payments.generateOrder({
      customerName: auth?.displayName,
      purpose: data.purpose,
      orderAmount: data.amount,
      customerPhone: auth?.phoneNumber,
      customerEmail: auth?.email || 'shankarmuthyd@gmail.com'
    });
    Payments.makePayment(order, async status => {
      setLoading(false);
      if (status.response && status.response.txStatus === 'SUCCESS') {
        const donationData = {
          ...data,
          amount: Number(data?.amount),
          createdBy: auth?.uid,
          status: 'Success',
          transactionId: '234234',
          createdOn: removeTimeFromMoment(moment())
        };
        await new Create('donations').Set(donationData);
        reset();
        Swal.fire(
          'Congratulations!!',
          'Your transaction was successfull!',
          'success'
        );
      }
      if (status.response && status.response.txStatus === 'FAILED') {
        const donationData = {
          ...data,
          amount: Number(data?.amount),
          createdBy: auth?.uid,
          transactionId: '234234',
          createdOn: removeTimeFromMoment(moment()),
          status: 'Failed'
        };
        await new Create('donations').Set(donationData);
        Swal.fire({
          icon: 'error',
          title: 'Payment Failed...',
          text: 'Something went wrong!',
          footer: '<a href>Please try again</a>'
        });
      }
    });
  };
  return (
    <Card className="donation-card">
      <CardHeader className="bg-default text-center">
        <h3 className="text-white">Donate to Shankar Math Trust</h3>
      </CardHeader>
      <CardBody className="d-flex flex-column justify-content-center">
        <p>
          <small>
            <strong>Note:</strong> 80G certificates will not be provided as of
            now since it is work in progress.
          </small>
        </p>
        <Form className="w-100" onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            icon={faUser}
            addonType="prepend"
            className="form-control-alternative"
            label="Name"
            name="name"
            type="text"
            ref={register({
              required: 'Name should not be empty',
              validate: value => !/\d/.test(value)
            })}
            error={errors.name}
            validateHelperText="Value should contain only alphabets"
          />
          <FormInput
            addonType="prepend"
            icon={faAddressCard}
            className="form-control-alternative"
            label="Purpose"
            name="purpose"
            type="text"
            ref={register({
              required: 'Purpose should not be empty',
              validate: value => !/\d/.test(value)
            })}
            error={errors.purpose}
            validateHelperText="Value should contain only alphabets"
          />
          <FormInput
            addonType="prepend"
            icon={faRupeeSign}
            className="form-control-alternative"
            label="Amount"
            type="number"
            name="amount"
            ref={register({
              required:
                'Amount should be greater than zero and cannot contain decimals',
              validate: value => isFinite(value) && value > 0
            })}
            error={errors.amount}
            validateHelperText={'Amount should be greater than zero'}
          />
          {loading && (
            <Row className="py-3 d-flex flex-column justify-content-center align-items-center">
              <p className="text-dark font-weight-600">Checking out...</p>
              <Spinner color="primary" />
            </Row>
          )}
          <Row className="py-3">
            <Col>
              <Button disabled={loading} className="btn-block" color="default">
                Pay now
              </Button>
            </Col>
            <Col>
              <Button disabled={loading} className="btn-block" color="warning">
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
}

export default Donate;
