import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './HistoryCard.scss';
import { motion } from 'framer-motion';

const listVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 }
    }
  },
  closed: {
    y: 50,
    transition: {
      y: { stiffness: 1000 }
    }
  }
};

function HistoryCard({ showHoverEffect, children }) {
  return (
    <motion.div
      variants={listVariants}
      transformTemplate={({ scale }) => `scale(${scale || 1}) translateY(0)`}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
    >
      <Card className="py-2 mb-3 px-lg-3 border history-card">{children}</Card>
    </motion.div>
  );
}
export const Header = ({ children }) => {
  return (
    <CardHeader className="p-0 px-2 px-md-0 p-md-2">
      <Row className="mx-0">{children}</Row>
    </CardHeader>
  );
};
export const Body = ({ children }) => {
  return (
    <CardBody className="p-3">
      <div className="mx-0 flex-column">{children}</div>
    </CardBody>
  );
};

export const Footer = ({ children }) => {
  return (
    <CardFooter className="py-0">
      <Row className="mx-0 justify-content-end" color="warning">
        {children}
      </Row>
    </CardFooter>
  );
};
export const FooterButton = ({ title, icon }) => {
  return (
    <div className="d-flex justify-content-center align-items-center mx-3 p-2 footer-button">
      <div className="text-warning font-weight-bold text-underline">
        {title}
      </div>
      {icon && (
        <FontAwesomeIcon
          className="ml-2 text-warning footer-icon"
          icon={icon}
        />
      )}
    </div>
  );
};
export const Field = ({ title, value, className }) => {
  return (
    <Col className="col-12 col-md-4 px-4 px-md-0 mb-1 mb-md-0 justify-content-center">
      <p className="mr-2 font-weight-bold text-dark mb-0">{title}: </p>
      <p className={'display-5 font-weight-400 ' + className}>{value}</p>
    </Col>
  );
};

export default HistoryCard;
