import { toArray } from '../helpers';
import { RELATION_ID_FIELD } from '../schema/constants';
import { throwErrorAndLog } from '../../../utils/helpers';
import { firestore } from 'firebase';
import { v4 as uuid } from 'uuid';
import Db from '../init/db';
import { Doc, SubCollectionDef, CollectionDef } from '../schema/models/schema';
import Schema from '../schema';
// import DocumentValidator from '../schema/validators/documentValidator';
import {
  getCollectionSchemaDef,
  getKeyProperties,
  getSubCollectionSchemaDefs
} from '../schema/helpers';
import { flattenDeep, omit, uniq } from 'lodash';
import {
  CollectionSchemaDef,
  SubCollectionSchemaDefs
} from '../schema/models/collectionsSchema';

export default class Create {
  private _collectionRef: firestore.CollectionReference;
  private _collectionSchemaDef: CollectionSchemaDef;
  private _subCollectionSchemaDefs?: SubCollectionSchemaDefs;
  private _collectionDef: CollectionDef;
  private _subCollectionDefs?: SubCollectionDef[];

  constructor(collection: string) {
    if (!(collection in Schema.SchemaDef.collections)) {
      throwErrorAndLog('Invalid collection specified');
    }
    this._collectionDef = Schema.SchemaDef.collections[collection];
    if (this._collectionDef.subCollection) {
      this._subCollectionDefs = toArray(this._collectionDef.subCollection);
      this._subCollectionSchemaDefs = getSubCollectionSchemaDefs(
        this._subCollectionDefs,
        Schema.SubCollectionSchemaDefs
      );
    }
    this._collectionSchemaDef = getCollectionSchemaDef(
      Schema.CollectionSchemaDefs,
      Schema.SchemaDef.collections[collection].id
    );
    this._collectionRef = Db.Instance.collection(this._collectionDef.id);
  }

  private async createDocument(
    colRef: firestore.CollectionReference,
    colSchemaDef: CollectionSchemaDef | CollectionSchemaDef[],
    data: Doc
  ) {
    // Unique key prop validation is already done at schema level
    const keyProps = uniq(
      flattenDeep(toArray(colSchemaDef).map(def => getKeyProperties(def)))
    );
    if (keyProps.length === 1) {
      const keyProp = keyProps[0];
      const isKeySpecified = keyProp in data;
      const firestoreDoc = isKeySpecified
        ? colRef.doc(data[keyProp])
        : colRef.doc();
      await firestoreDoc.set({
        ...data,
        ...(!isKeySpecified && { [keyProp]: firestoreDoc.id })
      });
      return firestoreDoc;
    } else {
      const firestoreDoc = colRef.doc();
      await firestoreDoc.set({ ...data });
      return firestoreDoc;
    }
  }

  private async createSubCollection(
    subCollectionDef: SubCollectionDef,
    subColSchemaDef: CollectionSchemaDef | CollectionSchemaDef[],
    docRef: firestore.DocumentReference<firestore.DocumentData>,
    relationId: string,
    data: Doc
  ) {
    const subCollectionName = subCollectionDef.name;
    const subCollVals = data[subCollectionName] || [];
    const subCollRef = docRef.collection(subCollectionDef.id);
    await Promise.all(
      subCollVals.map(async (subCollVal: any) => {
        await this.createDocument(subCollRef, subColSchemaDef, {
          ...subCollVal,
          [RELATION_ID_FIELD]: relationId
        });
      })
    );
  }

  private async createDocWithSubCollections(
    colSchemaDef: CollectionSchemaDef,
    collectionRef: firestore.CollectionReference,
    subCollectionDefs: SubCollectionDef[],
    subColSchemaDefs: SubCollectionSchemaDefs,
    data: Doc
  ) {
    const relationId = uuid();
    const collectionData = omit(
      data,
      subCollectionDefs.map(def => def.name)
    );
    const docRef = await this.createDocument(collectionRef, colSchemaDef, {
      ...collectionData,
      [RELATION_ID_FIELD]: relationId
    });
    await Promise.all(
      subCollectionDefs.map(def =>
        this.createSubCollection(
          def,
          subColSchemaDefs[def.id],
          docRef,
          relationId,
          data
        )
      )
    );
  }

  public async Set(data: Doc) {
    // Disabling validations temporarily
    // const docValidator = new DocumentValidator(
    //   this._collectionDef.id,
    //   Schema.Instance,
    //   data
    // );
    // docValidator.validateData();
    if (this._subCollectionDefs && this._subCollectionSchemaDefs) {
      return this.createDocWithSubCollections(
        this._collectionSchemaDef,
        this._collectionRef,
        this._subCollectionDefs,
        this._subCollectionSchemaDefs,
        data
      );
    }
    return this.createDocument(
      this._collectionRef,
      this._collectionSchemaDef,
      data
    );
  }
}
