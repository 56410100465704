import React from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import './Announcement.scss'
const Announcement = ({ item }) => {
  return (
    <Card className="bg-transparent border border-red-background left-thick-border shadow my-3 p-1">
      <CardHeader className="bg-transparent p-2">
        <h2 className="mb-0" style={{ color: '#A5281F' }}>
          {item.title}
        </h2>
      </CardHeader>
      <CardBody style={{ color: '#A5281F' }} className="font-weight-500 p-2">
        {item.information?.map(info => (
          <p key={info} className="font-weight-500 mb-2">
            {info}
          </p>
        ))}
        {item.src && <a rel="noopener noreferrer" href={item.src} target='_blank'>
          <img style={{ height: '200px', maxWidth: '100%' }} src={item.src} alt={''} />
          <div>View</div>
        </a>}
      </CardBody>
      <CardFooter
        style={{ color: '#A5281F' }}
        className="bg-transparent d-flex justify-content-end p-2"
      >
        <small> Posted on: {item.createdDate}</small>{' '}
      </CardFooter>
    </Card>
  );
};

export default Announcement;
