import React, { useEffect, useState } from 'react';
import { Alert } from "reactstrap";
import './Alert.scss';

const CustomAlert = (props) => {
    const [visible, setVisible] = useState(true);
    const onDismiss = () => setVisible(false);
    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                onDismiss();
            }, props.timeout || 2000)
        }
    }, [props.timeout, visible])
    return (
        <Alert className='font-weight-bold mr-2 w-25' {...props} isOpen={visible} toggle={onDismiss}>
            {props.children}
        </Alert>
    );
}

export default CustomAlert;