/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';
// import useAuth from 'hooks/useAuth';
import AppReducer from 'reducers/appReducer';

import AdminLayout from 'layouts/Admin.js';
import AgentLayout from 'layouts/Agent.js';
import { AppStateProvider } from 'hooks/useAppState';
import useAuth from 'hooks/useAuth';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();

const initialState = {
  auth: null,
  authAttempted: false
};
const queryClient = new QueryClient();
const App = () => {
  // eslint-disable-next-line no-unused-vars
  const { auth } = useAuth();
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/user"
          render={props => <AdminLayout auth={auth} {...props} />}
        />
        <Route
          path="/agent"
          render={props => <AgentLayout auth={auth} {...props} />}
        />
        <Redirect from="*" to="/user" />
      </Switch>
    </BrowserRouter>
  );
};
ReactDOM.render(
  <AppStateProvider reducer={AppReducer} initialState={initialState}>
    {/* <CustomScroll> */}
    <QueryClientProvider client={queryClient}>
      {process.env.VERCEL_ENV === 'development' && <ReactQueryDevtools />}
      <App />
    </QueryClientProvider>
    {/* </CustomScroll> */}
  </AppStateProvider>,
  document.getElementById('root')
);
