/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Navbar, Nav, NavItem, Container } from 'reactstrap';
import { NavLink, Redirect, Switch, Route } from 'react-router-dom';
import './SevaBooking.scss';
import SevaHistory from './SevaHistory';
import DonationHistory from './DonationHistory';
import Book from './Booking';
import Donate from './Donate';
import { BookSevaStateProvider } from 'hooks/useBookSevaState';
import sevaReducer from 'reducers/sevaReducer';
import useAuth from 'hooks/useAuth';
import { personIds, poojaIds } from './Booking/schema';
import UpdateProfile from './UpdateProfile';
import Maintain from 'layouts/Maintain';

const initialState = {
  people: [{ ...personIds }],
  poojas: [{ ...poojaIds, amount: '', numberOfDays: '', amountPerDay: '' }]
};

const MyNavItem = ({ children, to, onClick }) => {
  return (
    <NavItem>
      <NavLink
        onClick={onClick}
        className="h-100 text-center text-white font-weight-normal my-tab border border-light"
        activeClassName="tab-selected"
        to={to}
      >
        {children}
      </NavLink>
    </NavItem>
  );
};

const CustomRoute = ({ children, ...props }) => (
  <Route {...props}>
    <Container className="p-2 my-2 my-md-3">{children}</Container>
  </Route>
);

function SevaBooking() {
  const { authAttempted, auth } = useAuth();
  const isMaintain = false;
  if (!authAttempted || !auth) {
    localStorage.setItem(
      'redirect-url',
      window.location.pathname + window.location.search
    );
    return <Redirect to="/user/login" />;
  }
  if (isMaintain) {
    return <Maintain />;
  }
  return authAttempted && auth && !auth.displayName ? (
    <UpdateProfile />
  ) : (
    <>
      <Navbar className="navbar-dark my-nav-bar">
        <Nav
          className="flex-nowrap flex-row d-flex flex-grow-1 justify-content-sm-center justify-content-lg-end my-navbar-tabs"
          tabs
        >
          <MyNavItem to={'/user/seva/book'}>Book Seva</MyNavItem>
          <MyNavItem to={'/user/seva/history'}>Booking History</MyNavItem>
        </Nav>
      </Navbar>
      <Switch>
        <CustomRoute path={'/user/seva/book'}>
          <BookSevaStateProvider
            reducer={sevaReducer}
            initialState={initialState}
          >
            <Book />
          </BookSevaStateProvider>
        </CustomRoute>
        <CustomRoute path={'/user/seva/history'}>
          <SevaHistory />
        </CustomRoute>
        <CustomRoute exact path={'/user/seva/donate-history'}>
          <DonationHistory />
        </CustomRoute>
        <CustomRoute path={'/user/seva/donate'}>
          <Donate />
        </CustomRoute>
        <Redirect path="/user/seva" to="/user/seva/book" />
      </Switch>
    </>
  );
}

export default SevaBooking;
