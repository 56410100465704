import { poojaInfo } from './poojaData';
import { sortBy } from 'lodash';

export const INPUT_TYPES = {
  TEXT: 'text',
  TEXTAREA: 'textarea',
  SELECT: 'select',
  DATE: 'date'
};

const nakshatras = [
  'Ashwini',
  'Bharani',
  'Kritika',
  'Rohini',
  'Mrugasira',
  'Aarudra',
  'Punarvasu',
  'Pushyami',
  'Aashlesha',
  'Magha',
  'Poorva Phalguni',
  'Uttara Phalguni',
  'Hasta',
  'Chitta',
  'Swaati',
  'Vishaakha',
  'Anuraadha',
  'Jyeshtha',
  'Moola',
  'Poorvaashaadha',
  'Uttaraashaadha',
  'Shravana',
  'Dhanishtha',
  'Shatabisha',
  'Poorva Bhaadrapada',
  'Uttara Bhaadrapada',
  'Revati'
].sort();

export const personSchema = [
  {
    id: 'name',
    label: 'Name',
    type: INPUT_TYPES.TEXT,
    required: 'Please enter a valid name'
  },
  {
    id: 'gothram',
    label: 'Gothram',
    type: INPUT_TYPES.TEXT
  },
  {
    id: 'nakshatram',
    label: 'Nakshatram',
    type: INPUT_TYPES.SELECT,
    options: nakshatras.map(item => ({
      value: item.toLowerCase(),
      label: item
    }))
  }
];

export const personIds = personSchema.reduce((acc, item) => {
  acc[item.id] = '';
  return acc;
}, {});

export const poojaDetails = [
  {
    id: 'seva',
    label: 'Select Seva',
    type: INPUT_TYPES.SELECT,
    required: 'Please select a Seva',
    options: sortBy(poojaInfo, 'category').map(item => ({
      value: item.name,
      label: item.label || item.name,
      category: item.category,
      disabled: item.disabled || false
    }))
  },

  {
    id: 'mode',
    label: 'Booking for',
    type: INPUT_TYPES.SELECT,
    required: 'Please select the mode of booking dates',
    options: [
      {
        value: 'single',
        label: 'Single Date'
      },
      {
        value: 'multiple',
        label: 'Multiple Dates'
      },
      {
        value: 'range',
        label: 'Range of Dates'
      }
    ]
  },
  {
    id: 'amount',
    label: 'Amount',
    type: INPUT_TYPES.TEXT,
    readOnly: true,
    required: 'The amount should be valid'
  }
];

export const poojaIds = poojaDetails.reduce((acc, item) => {
  acc[item.id] = '';
  return acc;
}, {});
