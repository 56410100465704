import React from 'react';
import { Container, Row, Col, Input, Label, Button } from 'reactstrap';
import { checkoutTransaction } from './helpers';

function CheckoutTest() {
  const checkoutHandler = async () => {
    const formObject = {
      customerEmail: 'a@a.com',
      customerName: 'a',
      customerPhone: '1234512345',
      orderAmount: '2',
      paymentModes: ''
    };
    checkoutTransaction(formObject, res => {
      console.log(formObject);
    });
  };
  return (
    <Container>
      <Row className="justify-content-end pr-4 pt-3">
        <Button color="success">Add Person</Button>
      </Row>
      <Row className="flex-column p-4">
        <Col xl={4}>
          <Label className="form-control-label">Name</Label>
          <Input type="text"></Input>
        </Col>
        <Col xl={4}>
          <Label className="form-control-label">Gothram</Label>
          <Input type="text"></Input>
        </Col>
        <Col xl={4}>
          <Label className="form-control-label">Nakshatram</Label>
          <Input type="text"></Input>
        </Col>
        <Col xl={4}>
          <Label>Pooja</Label>
          <Input type="text"></Input>
        </Col>
        <Col xl={4}>
          <Label>Date</Label>
          <Input type="text"></Input>
        </Col>
        <Col className="mt-3" xl={4}>
          <Button onClick={checkoutHandler} color="primary">
            {' '}
            Checkout{' '}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default CheckoutTest;
