import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { DatePicker } from 'components/FormControls';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, CardTitle, Form } from 'reactstrap';
import moment from 'moment';
import MultiSelect from '@khanacademy/react-multi-select';
import { poojaInfo } from '../SevaBooking/Booking/poojaData';
import { removeTimeFromMoment } from 'firestore/db/helpers';
import Query from 'firestore/db/read/queryBase';
import {
  FilterOperator,
  QueryCompounder,
  SubCollectionQueryOption
} from 'firestore/db/read/queryOptions';
import { flatten, isArray, isEmpty, sortBy } from 'lodash';
import './Report.scss';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { startOfToday } from 'date-fns/esm';
import { TRANSACTION_STATUS } from '../SevaBooking/Booking/constants';

// const poojaDate = '2020-10-10';
const customRangeQuery = poojaDate => ({
  compounder: QueryCompounder.or,
  conditions: [
    {
      compounder: QueryCompounder.and,
      conditions: [
        doc =>
          doc
            .where('isRange', '==', true)
            .where(
              'selectedDates.startDate',
              '<=',
              removeTimeFromMoment(moment(poojaDate))
            ),
        doc =>
          doc
            .where('isRange', '==', true)
            .where(
              'selectedDates.endDate',
              '>=',
              removeTimeFromMoment(moment(poojaDate))
            )
      ]
    },
    doc =>
      doc.where('selectedDates', FilterOperator.arrayContainsAny, [
        removeTimeFromMoment(moment(poojaDate))
      ])
  ]
});
const getPoojaReportDetails = async (poojaDate, selectedPoojas) => {
  const results = await Promise.all(
    selectedPoojas.map(async selectedPooja => {
      return await Query.from('transactions').Execute({
        filter: [
          {
            field: 'status',
            operator: FilterOperator.equalTo,
            value: TRANSACTION_STATUS.SUCCESS
          },
          {
            field: 'poojaDetails/selectedDates',
            compoundQuery: customRangeQuery(poojaDate)
          },
          {
            field: 'poojaDetails/selectedPooja',
            operator: FilterOperator.equalTo,
            value: selectedPooja
          }
        ],
        subCollectionQueryOption: SubCollectionQueryOption.withSubCollection,
        transformer: {
          accessor: [
            transaction => transaction.poojaDetails,
            poojaDetail => poojaDetail.selectedDates
          ],
          transformer: selectedDates => {
            if (isArray(selectedDates)) {
              return selectedDates.map(date => date.toDate());
            }
            return {
              startDate: selectedDates.startDate.toDate(),
              endDate: selectedDates.endDate.toDate()
            };
          }
        },
        groupBy: {
          accessor: [transaction => transaction.poojaDetails],
          groupBy: [
            poojaDetail => poojaDetail.selectedPooja,
            transaction => transaction.transactionId
          ]
        }
      });
    })
  );
  const formattedResults = results.reduce((acc, result) => {
    if (result && !isEmpty(result)) {
      const formattedResult = Object.keys(result).reduce(
        (formResults, poojaId) => {
          const transactions = result[poojaId];
          return {
            ...formResults,
            [poojaId]: Object.keys(transactions).reduce(
              (trs, transactionId) => {
                return {
                  ...trs,
                  [transactionId]: flatten(
                    transactions[transactionId].map(
                      currentTransaction => sortBy(currentTransaction.personDetails, 'seq')
                    )
                  )
                };
              },
              {}
            )
          };
        },
        {}
      );
      return { ...acc, ...formattedResult };
    }
    return acc;
  }, {});
  if (!isEmpty(formattedResults)) {
    const doc = new jsPDF();

    Object.keys(formattedResults).forEach((item, index) => {
      if (index > 0) {
        doc.addPage();
      }
      let noOfTransactions = 0;
      const rows = flatten(
        Object.values(formattedResults[item]).map(transaction => {
          ++noOfTransactions;
          return transaction
            .map((item, index) => {
              return { ...item, no: ++index };
            })
            .concat({ no: '', name: '', gothram: '', nakshatram: '' });
        })
      );
      doc.text(`${item} - ${moment(poojaDate).format('DD-MM-YYYY')}`, 12, 20);
      doc.text(`No of Tickets: ${noOfTransactions}`, 130, 20);

      doc.autoTable({
        columns: [
          { dataKey: 'no', header: 'No' },
          { dataKey: 'name', header: 'Name' },
          { dataKey: 'gothram', header: 'Gothram' },
          { dataKey: 'nakshatram', header: 'Nakshatram' }
        ],
        body: rows,
        startY: 30
      });
    });
    doc.save(`Pooja Report for ${moment(poojaDate).format('DD-MM-YYYY')}`);
    return false;
  }
  return true;
};
const availabePoojas = sortBy(poojaInfo, 'name').map(item => ({
  label: item.name,
  value: item.name
}));
const momentToday = startOfToday();
const today = {
  day: momentToday.getDate(),
  month: momentToday.getMonth() + 1,
  year: momentToday.getFullYear()
};
function Report() {
  const { control, handleSubmit, errors } = useForm({
    mode: 'onTouched'
  });
  const [selectedPoojas, setSelectedPoojas] = useState([]);
  const [error, setError] = useState(false);
  const [isResultsEmpty, setIsEmpty] = useState(false);
  const onSubmit = async ({ selectedDate }) => {
    const poojaDate = `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`;
    if (poojaDate && selectedPoojas.length > 0) {
      const isEmpty = await getPoojaReportDetails(poojaDate, selectedPoojas);
      return setIsEmpty(isEmpty);
    }
    setError(true);
  };

  return (
    <Card className="bg-transparent">
      <CardTitle className="p-2 bg-default text-white">
        Pooja Report of Devotees
      </CardTitle>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="">Select the Poojas you want the report for</label>
          <MultiSelect
            className="w-100"
            overrideStrings={{
              selectSomeItems: 'Select poojas ...',
              allItemsAreSelected: 'All Poojas are Selected',
              selectAll: 'Select All',
              search: 'Search for a pooja..'
            }}
            options={availabePoojas}
            selected={selectedPoojas}
            onSelectedChanged={selected => {
              setError(false);
              setSelectedPoojas(selected);
            }}
          />
          {error && <p className="text-danger">Please select a pooja</p>}
          <DatePicker
            className="form-control-sm bg-transparent"
            name={'selectedDate'}
            id={'date'}
            label={'Select a Date'}
            control={control}
            icon={faCalendarAlt}
            addonType={'append'}
            // minimumDate={today}
            defaultValue={today}
            error={errors?.selectedDate}
            calendarPopperPosition="bottom"
          />
          {isResultsEmpty && (
            <p className="text-danger">
              No transactions found on the selected poojas and date
            </p>
          )}
          <Button className="btn-block btn-default my-2">
            {' '}
            Download Pooja Report{' '}
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
}

export default Report;
